import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import { getStoreSlug } from '../util/StoreUtil';

export default (reducers) => {
  // const url = window.location.href.split('/');
  // const storeSlug = url[3];

  const persistedReducer = persistReducer(
    {
      key: `i9p:${getStoreSlug()}`,
      storage,
      whitelist: [
        'auth',
        'user',
        'cart',
        'store',
        'delivery',
        'alerts',
        'temporaryCart',
      ],
      transforms: [
        expireReducer('cart', {
          expireSeconds: 604800,
          expiredState: { products: [] },
          autoExpire: true,
        }),
        expireReducer('auth', {
          expireSeconds: 432000,
          expiredState: {
            token: null,
            signed: false,
            loading: false,
            showLoginModal: false,
          },
          autoExpire: true,
        }),
        expireReducer('user', {
          expireSeconds: 432000,
          expiredState: { profile: { id: '' } },
          autoExpire: true,
        }),
        expireReducer('alerts', {
          expireSeconds: 86400,
          expiredState: { alerts: [] },
          autoExpire: true,
        }),
        expireReducer('temporaryCart', {
          expireSeconds: 604800,
          expiredState: { temporaryProducts: [] },
          autoExpire: true,
        }),
      ],
    },

    reducers
  );

  return persistedReducer;
};
