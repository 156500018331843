import React, { useState } from 'react';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import api from '../../../../../../../services/api';

import * as DeliveryActions from '../../../../../../../store/modules/delivery/actions';

import Input from '../../../../../../components/Input';

import { CityArea, City, State } from './styles';

import {
  Header,
  IconArea,
  Title,
  Form,
  Card,
  InputArea,
  ButtonArea,
  ButtonConfirm,
} from '../SubmitAddress/styles';

import { ButtonBackArea } from '../../SubmitAddress/styles';

function EditAddress({
  handleEditAddress,
  currentAddress,
  handleUpdateAddress,
  color,
}) {
  const [zipCode, setZipCode] = useState(currentAddress.zip_code);
  const [name, setName] = useState(currentAddress.name);
  const [street, setStreet] = useState(currentAddress.street);
  const [neighbourhood, setNeighbourhood] = useState(
    currentAddress.neighborhood
  );
  const [city, setCity] = useState(currentAddress.city);
  const [state, setState] = useState(currentAddress.state);
  const [number, setNumber] = useState(currentAddress.number);
  const [complement, setComplement] = useState(currentAddress.complement);

  async function handleZipCode(text) {
    text = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
    await setZipCode(text);

    if (text && text.length + 1 > 8) {
      const response = await api.getZipCode(text);

      setStreet(response.data.street);
      setNeighbourhood(response.data.neighborhood);
      setCity(response.data.city);
      setState(response.data.state);
      setZipCode(
        response.data.cep
          .replace(/\D/g, '')
          .replace(/^(\d{5})(\d{3})/g, '$1-$2')
      );
    }
  }

  function goBack() {
    handleEditAddress(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    handleUpdateAddress(
      zipCode,
      name,
      street,
      neighbourhood,
      city,
      state,
      number,
      complement
    );
  }

  return (
    <>
      <Header color={color}>
        <ButtonBackArea onClick={() => goBack()}>
          <IoIosArrowBack />
        </ButtonBackArea>
        <Title>Editar endereço</Title>
        <IconArea />
      </Header>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Card>
          <InputArea>
            <span>
              <strong>CEP: </strong>
            </span>{' '}
            <Input
              setState={handleZipCode}
              maxLength={9}
              value={zipCode}
              placeholder="Informe seu CEP"
            />
          </InputArea>

          <InputArea>
            <strong>Nome: </strong>
            <Input
              value={name}
              placeholder="Ex.(Casa, trabalho, empresa)"
              setState={setName}
            />
          </InputArea>

          <InputArea>
            <strong>Rua: </strong>
            <Input
              value={street}
              required
              placeholder="Ex.(Avenida Brasil)"
              setState={setStreet}
            />
          </InputArea>

          <InputArea>
            <strong>Bairro: </strong>
            <Input
              value={neighbourhood}
              required
              placeholder="Ex.(Jardim Brasil)"
              setState={setNeighbourhood}
            />
          </InputArea>

          <CityArea>
            <City>
              <strong>Cidade: </strong>
              <Input
                value={city}
                required
                placeholder="Ex.(Sorocaba)"
                setState={setCity}
              />
            </City>
            <State>
              <strong>Estado: </strong>
              <Input
                maxLength={2}
                value={state}
                required
                placeholder="Ex.(São Paulo)"
                setState={setState}
              />
            </State>
          </CityArea>
          <InputArea>
            <strong>Número: </strong>
            <Input
              value={number}
              type="number"
              required
              placeholder="Ex.(2000)"
              setState={setNumber}
            />
          </InputArea>
          <InputArea>
            <strong>Complemento: </strong>
            <Input
              type="text"
              value={complement}
              placeholder="Ex.(Apto 10 bloco C)"
              setState={setComplement}
            />
          </InputArea>
        </Card>
        <ButtonArea>
          <ButtonConfirm color={color}>
            <span>Editar endereço</span>
            <IoIosArrowForward size={25} />
          </ButtonConfirm>
        </ButtonArea>
      </Form>
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...DeliveryActions }, dispatch);

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
  zipCode: state.delivery.zipCode,
  address: state.delivery.address,
  currentAddress: state.delivery.currentAddress,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
