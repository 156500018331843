import React, { useEffect } from 'react';

import Layout from '../components/Layout';

import image from '../../../assets/icons/error/500.svg';

export default function Error500() {
  useEffect(() => {
    document.title = 'I9P - Erro 500';
  }, []);

  return (
    <Layout
      title="Erro interno no servidor"
      text="Desculpe. A página não pode ser exibida, pois há algum erro de execução no servidor."
      image={image}
      reload
    />
  );
}
