import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  align-content: center;
  height: 90px;
  width: 100%;
  position: fixed;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 5;

  @media print {
    display: none;
  }
`;
export const ImageHeader = styled.div`
  background-image: ${(props) => ` url(${props.image})`};
  width: 30%;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;

  @media (max-width: 350px) {
    width: 40%;
  }
`;

export const ProfileButton = styled.button`
  align-self: center;
  background: transparent;
  border: 0;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
    color: #727c8e;
  }

  @media (max-width: 1150px) {
    margin-right: 10px;
  }
`;

export const BackButtom = styled.button`
  align-self: center;
  background: transparent;
  border: 0;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 1150px) {
    margin-left: 10px;
  }
`;
