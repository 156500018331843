import styled, { keyframes } from 'styled-components';

const animation = keyframes`
 from {
    margin-top: 50%;
		margin-bottom: -300px;
		opacity: 0
	}
	to {
    margin-top: 0px;
		margin-bottom: 0px;
		opacity: 1
	}

`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  background: transparent;
  z-index: 5;
  justify-content: center;
  padding-top: 200px;
`;

export const MenuProfileContainer = styled.div`
  display: flex;
  height: auto;
  width: 200px;
  background: #fff;
  position: fixed;
  top: 80px;
  right: 250px;
  box-shadow: 0px 2px 4px #00000050;
  z-index: 5;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 1150px) {
    height: 100%;
    width: 100%;
    padding: 0;
    top: 0;
    right: 0;
    align-items: center;

    animation-name: ${animation};
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-play-state: running;
  }
`;

export const HeaderProfile = styled.div`
  display: none;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #eee;
  color: #fff;
  padding: 10px;
  background: ${(props) => props.color};
  max-height: 50px;

  @media (max-width: 1150px) {
    display: flex;
  }
`;

export const IconArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;

  svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    color: #fff;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
  font-size: 30px;
  font-weight: bold;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  text-align: center;

  @media (max-width: 1150px) {
    font-size: 26px;
  }
`;

export const Body = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  @media (max-width: 1150px) {
    font-size: 20px;
  }
`;

export const Menu = styled.div`
  display: flex;
  cursor: pointer;
  padding: 10px 5px;
  transition: all 0.2s;

  svg {
    margin-right: 5px;
  }

  &:hover {
    color: ${(props) => props.color};
  }
`;
