import React, { useState } from 'react';
import { connect } from 'react-redux';

import { IoIosArrowDown } from 'react-icons/io';

import { bindActionCreators } from 'redux';

import Login from './components/Login';
import Register from './components/Register';

import RecoveryPassword from './components/RecoveryPassword';

import * as UserActions from '../../../../store/modules/user/actions';
import * as AuthActions from '../../../../store/modules/auth/actions';

import {
  ModalStyled,
  Container,
  Headers,
  Icon,
  Title,
  Space,
  Tabs,
  Tab,
  Header,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function LoginRegister({
  color,
  signed,
  showLoginModal,
  recoveryPassword,
  handleLoginModal,
}) {
  const [registerVisible, setRegisterVisible] = useState(false);

  // const isInitialMount = useRef(true);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     window.location.reload();
  //   }
  // }, [signed]);

  function handleRegister(bool) {
    setRegisterVisible(bool);
  }

  function handleCloseModalButton() {
    handleLoginModal();
  }

  return !signed && showLoginModal ? (
    <Container>
      {recoveryPassword ? (
        <RecoveryPassword />
      ) : (
        <ModalStyled
          isOpen={showLoginModal}
          onRequestClose={handleCloseModalButton}
          style={customStyles}
          registerVisible={registerVisible}
        >
          <Headers>
            <Header color={color}>
              <Icon>
                <IoIosArrowDown onClick={() => handleCloseModalButton()} />
              </Icon>
              <Title>{registerVisible ? 'Cadastro' : 'Login'}</Title>
              <Space />
            </Header>
            <Tabs>
              <Tab
                registerVisible={registerVisible}
                onClick={() => handleRegister(false)}
                color={color}
              >
                <h2>Entrar</h2>
              </Tab>
              <Tab
                registerVisible={!registerVisible}
                onClick={() => handleRegister(true)}
                color={color}
              >
                <h2>Criar conta</h2>
              </Tab>
            </Tabs>
          </Headers>
          {!registerVisible ? (
            <Login
              handleLoginModal={handleLoginModal}
              handleRegister={handleRegister}
              color={color}
            />
          ) : (
            <Register
              handleLoginModal={handleLoginModal}
              handleRegister={handleRegister}
              color={color}
              registerVisible={registerVisible}
            />
          )}
        </ModalStyled>
      )}
    </Container>
  ) : null;
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...UserActions, ...AuthActions }, dispatch);

const mapStateToProps = (state) => ({
  signed: state.auth.signed,
  showLoginModal: state.auth.showLoginModal,
  color: state.store.storeInfo.settings.color_primary,
  recoveryPassword: state.user.recoveryPassword,
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginRegister);
