import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 5%;
`;

export const ButtonCart = styled.button`
  display: none;
  bottom: 8%;
  position: fixed;
  width: 96%;
  border: 0;
  z-index: 4;
  border-radius: 100px;
  background: ${(props) => props.color};
  transition: background 0.6s;
  cursor: pointer;
  align-items: center;
  padding: 8px 60px 8px 20px;
  align-items: center;

  svg {
    height: 30px;
    width: 30px;
    color: #fff;
  }

  label {
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background: ${(props) => darken(0.17, props.color)};
  }

  @media (max-width: 1150px) {
    display: ${(props) => (props.cartVisible ? 'none' : 'flex')};
  }

  @media (max-width: 750px) {
    svg {
      height: 20px;
      width: 20px;
    }
    label {
      font-size: 20px;
    }
  }
  @media (max-width: 440px) {
    label {
      font-size: 15px;
    }
  }
`;
