import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CloseArea = styled.div`
  width: 100%;
  display: none;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px;
  background: ${(props) => props.color};

  @media (max-width: 576px) {
    display: flex;
  }
`;

export const Space = styled.div`
  display: flex;
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  svg {
    height: 30px;
    width: 30px;
  }
`;

export const ImageArea = styled.div`
  display: flex;
  img {
    vertical-align: middle;
    width: 100%;
    border-radius: 10px 10px 0 0;

    @media (max-width: 576px) {
      border-radius: 0;
    }
  }
`;

export const TextInfo = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: bold;

  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;

export const ZipCodeArea = styled.div`
  width: 100%;
  height: 80px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    margin-top: 20px;
    text-align: center;

    &:placeholder-shown {
      text-align: center;
    }

    @media (max-width: 576px) {
      margin-top: 0px;
    }
  }
`;

export const ErrorArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f00;
  font-size: 14px;
  margin-top: 10px;
`;

export const WithouCep = styled.span`
  width: 100%;
  color: ${(props) => props.theme.colors.grayDark};
  font-style: italic;
  font-size: 12px;
  text-decoration-line: underline;
  display: flex;
  cursor: pointer;
  margin-top: 10px;
  justify-content: flex-end;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.color};
  border-radius: 10px;
  width: 50%;
  padding: 10px;
  cursor: pointer;
  transition: 0.6s;
  box-shadow: 0px 2px 4px #00000050;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }
`;
