export function addToCart(product) {
  return {
    type: 'ADD_TO_CART',
    product,
  };
}

export function removeFromCart(id) {
  return { type: 'REMOVE_FROM_CART', id };
}

export function updateAmount(id, amount) {
  return {
    type: 'UPDATE_AMOUNT_CART',
    id,
    amount,
  };
}

export function cleanCart() {
  return {
    type: 'CLEAN_CART',
  };
}

export function handleModalDivergents(state) {
  return {
    type: 'HANDLE_MODAL_DIVERGENTS',
    payload: { state },
  };
}

export function setDivergentProducts(products) {
  return {
    type: 'SET_DIVERGENT_PRODUCTS',
    payload: { products },
  };
}

export function removeDivergent(productId) {
  return {
    type: 'REMOVE_DIVERGENT',
    productId,
  };
}

export function updateCartProductPrices(id, prices) {
  return {
    type: 'UPDATE_CART_PRODUCT_PRICES',
    id,
    prices,
  };
}
