import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import * as StoreActions from '../../../../store/modules/store/actions';
import { getStoreSlug } from '../../../../util/StoreUtil';

import { Container, Modal, Title, Message, ButtonArea, Button } from './styles';

function Alert({ alert, color }) {
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    const localAlert = localStorage.getItem(
      `i9p:${getStoreSlug()}:alert:${alert.id}`
    );
    if (!localAlert) {
      return setShowAlert(true);
    }
    const localToday = moment(JSON.parse(localAlert).date).format('L');
    const today = moment(new Date()).format('L');

    if (localToday !== today) {
      return setShowAlert(true);
    }
    return setShowAlert(false);
  }, []);

  function handleAlertShowed() {
    const jsonAlert = JSON.stringify({
      id: alert.id,
      date: new Date(),
      showed: true,
    });
    localStorage.setItem(`i9p:${getStoreSlug()}:alert:${alert.id}`, jsonAlert);
    setShowAlert(false);
  }
  return (
    showAlert && (
      <Container>
        <Modal>
          <Title>{alert?.title}</Title>
          <Message>{alert?.message}</Message>
          <ButtonArea>
            <Button color={color} onClick={() => handleAlertShowed()}>
              Entendi
            </Button>
          </ButtonArea>
        </Modal>
      </Container>
    )
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...StoreActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  alerts: state.store.alerts,
  color: state.store.storeInfo.settings.color_primary,
});
export default connect(mapStateToProps, mapDispatchToProps)(Alert);
