import styled from 'styled-components';

export const InputStyled = styled.input`
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 5px 10px;
  transition: border 0.6s;
  font-size: 16px;
  color: #000;
  height: 40px;
  margin-top: 5px;
  width: 100%;

  &:focus {
    border: 1px solid #727c8e;
  }
`;
