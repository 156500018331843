import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px 00px 20px 50px;
  color: ${(props) => props.theme.colors.grayDark};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.grayDark};
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;

export const Company = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const By = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 1150px) {
    flex-direction: column;
    font-size: 18px;
    align-items: center;
  }
`;

export const I9p = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const Logo = styled.img`
  height: 50px;
  margin: 5px 20px 0px 0px;

  @media (max-width: 576px) {
    height: 40px;
  }
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    @media (max-width: 1150px) {
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
    }
  }
`;

export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;

  div {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.6s;
    font-weight: bold;

    &:hover {
      color: #37474f;
    }
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
`;
export const Term = styled.div``;
export const Privacity = styled.div``;
