import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AiOutlineLoading } from 'react-icons/ai';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

import history from '../../services/history';

import * as CartActions from '../../store/modules/cart/actions';
import * as MainActions from '../../store/modules/main/actions';
import * as DeliveryActions from '../../store/modules/delivery/actions';
import * as CustomerActions from '../../store/modules/user/actions';
import * as OrderActions from '../../store/modules/order/actions';
import * as StoreActions from '../../store/modules/store/actions';

import { getStoreSlug } from '../../util/StoreUtil';
import { formatPrice } from '../../util/format';

import Header from '../components/Header';
import MenuProfile from '../Main/components/MenuProfile';
import AlterPassword from '../Main/components/AlterPassword';
import MenuAddress from '../Main/components/MenuAddress';
import Divergents from './components/Divergents';

import Metas from '../components/Metas';

import api from '../../services/api';

import { validationResponse, validationDocument } from '../../util/validation';

import ModalAddress from './components/ModalAddress';
import Customer from './components/Customer';
import Products from './components/Products';
import Delivery from './components/Delivery';
import Payment from './components/Payment';
import Note from './components/Note';
import Total from './components/Total';

import {
  Title,
  Loading,
  Container,
  Body,
  Confirmation,
  ConfirmationBottom,
} from './styles';

function CheckOut({
  cart,
  user,
  color,
  total,
  totalFloat,
  orderMinimunValue,
  currentAddress,
  handleMenuAddress,
  restrictDeliveryArea,
  modalDivergents,
  priceDecimals,
  signed,
  deliveryTax,
  totalWithTax,
  loading,
  getCustomer,
  setOrder,
  deliveryPaymentsMethods,
  modalAddress,
  getAddresses,
  storePickUp,
  storeCorreios,
  storeOnwDelivery,
  setFreights,
  storeCreditCard,
  storeBankBill,
  storeMethodsOnlineId,
}) {
  const [note, setNote] = useState('');
  const [money, setMoney] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [change, setChange] = useState(0);
  const [pickUp, setPickUp] = useState(false);
  const [onwDelivery, setOnwDelivery] = useState(false);
  const [addressSelected, setAddressSelected] = useState({});
  const [currentFreight, setCurrentFreight] = useState(null);
  const [totalOrder, setTotalOrder] = useState(totalWithTax);
  const [cardNumber, setCardNumber] = useState('');
  const [validity, setValidity] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [cardholderDocument, setCardholderDocument] = useState('');
  const [bankBill, setBankBill] = useState(false);
  const [creditCard, setCreditCard] = useState(true);
  const [deliveryPayment, setDeliveryPayment] = useState(
    !storeCreditCard && !storeBankBill
  );
  const [deliveryTaxValue, setDeliveryTaxValue] = useState(deliveryTax || 0);
  const [incorrectCardNumber, setIncorrectCardNumber] = useState(false);
  const [correios, setCorreios] = useState(!storeOnwDelivery && !storePickUp);

  if (!signed) history.push(`/${getStoreSlug()}`);

  useEffect(() => {
    document.title = `I9P - ${getStoreSlug()} - Confirmação de pedido`;

    getCustomer(user && user?.id);
    getAddresses();
    setFreights([]);
  }, []);

  useEffect(() => {
    if (currentFreight) {
      setTotalOrder(totalWithTax + parseFloat(currentFreight.price));
    }
  }, [currentFreight]);

  useEffect(() => {
    const root = document.getElementsByTagName('body')[0];
    if (modalAddress) {
      root.classList.add('no-scroll');
    } else {
      root.classList.remove('no-scroll');
    }
  }, [modalAddress]);

  function verifyType(type) {
    if (type === 'Credit Card') {
      return 'Cartão de Crédito';
    }
    if (type === 'Debit Card') {
      return 'Cartão de Débito';
    }
    if (type === 'Voucher Card') {
      return 'Voucher';
    }
    return 'Outro';
  }

  function verifyDeliveryType() {
    if (storeCorreios && correios) {
      return 'correios';
    }
    if (storePickUp && pickUp) {
      return 'pick_up';
    }
    return 'onw_delivery';
  }

  function verifyService(code) {
    if (code === '04510') return 'PAC';
    if (code === '04782') return 'SEDEX 12';
    if (code === '04790') return 'SEDEX 10';
    if (code === '04804') return 'SEDEX Hoje';

    return 'SEDEX';
  }

  async function handleFinishOrder() {
    const products = cart.reduce((res, product) => {
      if (product?.options?.length > 0)
        res.push({
          id: product?.id,
          name: product.name,
          amount: product.amount,
          value_unitary: parseFloat(product.value_unitary),
          value_total: parseFloat(product.total),
          options: product.options,
        });
      else {
        res.push({
          id: product?.id,
          name: product.name,
          amount: product.amount,
          value_unitary: parseFloat(product.value_unitary),
          value_total: parseFloat(product.value_total),
        });
      }
      return res;
    }, []);
    let addressId = null;
    const deliveryType = verifyDeliveryType();
    const deliveryMethod = currentFreight
      ? {
          service_code: currentFreight.code,
          service_name: verifyService(currentFreight.code),
          deadline: parseInt(currentFreight.deadline, 10),
        }
      : null;

    if (currentAddress?.id) {
      addressId = currentAddress?.id;
    }
    if (addressSelected?.id) {
      addressId = addressSelected?.id;
    }

    if ((storeCreditCard && creditCard) || (storeBankBill && bankBill)) {
      let onlinePaymentMethod = {};
      const deliveryPaymentMethod = null;

      if (storeBankBill && bankBill) {
        onlinePaymentMethod = {
          id: storeMethodsOnlineId,
          type: 'bank-bill',
        };
      }
      if (storeCreditCard && creditCard) {
        let fingerprint = '';
        const fingerPrintElement = document.getElementsByName('finger_print');

        if (fingerPrintElement.length > 0) {
          fingerprint = fingerPrintElement[0].value;
        }

        const cardMonth = validity.split('/');
        onlinePaymentMethod = {
          id: storeMethodsOnlineId,
          type: 'credit',
          fingerprint,
          card_holder_document: cardholderDocument,
          card_holder_name: cardholderName,
          card_number: cardNumber,
          card_expire_month: cardMonth[0],
          card_expire_year: cardMonth[1],
          card_cvv: cvv,
        };
      }

      const paymentType = 'online';

      // online payments
      return setOrder(
        user,
        products,
        deliveryTaxValue || 0,
        totalOrder,
        paymentType,
        deliveryType,
        deliveryMethod,
        note,
        addressId || 0,
        deliveryPaymentMethod,
        onlinePaymentMethod
      );
    }

    if (
      (deliveryPaymentsMethods?.credit ||
        deliveryPaymentsMethods?.debit ||
        deliveryPaymentsMethods?.money ||
        deliveryPaymentsMethods?.voucher) &&
      deliveryPayment
    ) {
      const deliveryPaymentMethod = currentCard
        ? {
            id: currentCard?.id,
            type: verifyType(currentCard.type),
            name: currentCard.name,
          }
        : {
            type: 'Dinheiro',
            change: change ? parseFloat(change) : 0,
          };

      const paymentType = 'delivery';

      // delivery payment
      return setOrder(
        user,
        products,
        deliveryTax || 0,
        totalOrder,
        paymentType,
        deliveryType,
        deliveryMethod,
        note,
        addressId || 0,
        deliveryPaymentMethod,
        null
      );
    }

    // without payment by platform
    return setOrder(
      user,
      products,
      deliveryTax || 0,
      totalOrder,
      null,
      null,
      null,
      note,
      addressId || 0,
      null,
      null
    );
  }

  function verifyEmptyData() {
    if (!cardNumber) return 'número do cartão';
    if (!validity) return 'validade do cartão';
    if (!cvv) return 'cvv do cartão';
    if (!cardholderDocument) return 'documento do titular';
    if (!cardholderName) return 'nome do titular';

    return false;
  }
  async function handleFinishOrderConfirmation() {
    if (
      storePickUp &&
      !pickUp &&
      storeCorreios &&
      !currentFreight &&
      storeOnwDelivery &&
      !onwDelivery
    ) {
      toast.info('Selecione o tipo de entrega.');
      return;
    }

    if (storeCorreios && correios && !currentFreight) {
      toast.info('Selecione o tipo de entrega correios que você deseja.');
      return;
    }

    if (totalFloat < parseFloat(orderMinimunValue)) {
      toast.error(
        `Seu pedido deve ter o valor mínimo de ${formatPrice(
          orderMinimunValue,
          2
        )}`
      );
      return;
    }
    if (!cart || cart.length <= 0) {
      toast.error('Seu carrinho está vazio.');
      history.push(`/${getStoreSlug()}`);
      return;
    }

    if (restrictDeliveryArea && !currentAddress?.id && !addressSelected?.id) {
      toast.info('Por favor, informe o seu endereço.');
      handleMenuAddress(true);
      return;
    }

    if (
      deliveryPaymentsMethods?.credit &&
      deliveryPayment &&
      !currentCard &&
      !money
    ) {
      toast.info('Informe a forma de pagamento na entrega.');
      return;
    }
    if (
      deliveryPaymentsMethods?.debit &&
      deliveryPayment &&
      !currentCard &&
      !money
    ) {
      toast.info('Informe a forma de pagamento na entrega.');
      return;
    }
    if (
      deliveryPaymentsMethods?.money &&
      deliveryPayment &&
      !currentCard &&
      !money
    ) {
      toast.info('Informe a forma de pagamento na entrega.');
      return;
    }
    if (
      deliveryPaymentsMethods?.voucher &&
      deliveryPayment &&
      !currentCard &&
      !money
    ) {
      toast.info('Informe a forma de pagamento na entrega.');
      return;
    }
    if (
      storeCreditCard &&
      creditCard &&
      (!cardNumber ||
        !validity ||
        !cvv ||
        !cardholderName ||
        !cardholderDocument)
    ) {
      toast.info(`O campo ${verifyEmptyData()} é obrigatório.`);
      return;
    }
    if (creditCard && incorrectCardNumber) {
      toast.error(
        `Por favor verifique se o número do seu cartão está correto.`
      );
      return;
    }

    const cardMonth = validity.split('/');
    if (
      storeCreditCard &&
      creditCard &&
      parseInt(`20${cardMonth[1]}`, 10) < new Date().getFullYear()
    ) {
      toast.error(`Por favor verifique a validade do seu cartão.`);
      return;
    }

    if (
      storeCreditCard &&
      creditCard &&
      !validationDocument(cardholderDocument)
    ) {
      toast.error(`CPF/CNPJ inválido.`);
      return;
    }

    if (restrictDeliveryArea && currentAddress) {
      const response = await api.checkLocation(
        currentAddress.latitude,
        currentAddress.longitude
      );

      const validation = validationResponse(response);

      if (!validation) {
        toast.error('Local informado está fora da área de entrega.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      swal({
        title: 'Confirmar o pedido',
        text:
          'Após confirmar, seu pedido será finalizado e não poderá ser alterado.',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Voltar',
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: 'Confirmar pedido',
            value: true,
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: true,
      }).then((confirm) => {
        if (confirm) {
          swal.getState().actions.cancel.closeModal = false;
          return handleFinishOrder();
        }
        swal.stopLoading();
        return swal.close();
      });
    }

    swal({
      title: 'Confirmar o pedido',
      text:
        'Após confirmar, seu pedido será finalizado e não poderá ser alterado.',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Voltar',
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Confirmar pedido',
          value: true,
          visible: true,
          closeModal: false,
        },
      },
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        swal.getState().actions.cancel.closeModal = false;
        return handleFinishOrder();
      }
      swal.stopLoading();
      return swal.close();
    });
  }
  return (
    <>
      <Metas />
      <Header handleBackArrow />
      {modalAddress && <ModalAddress />}
      <MenuAddress />
      <MenuProfile />
      <AlterPassword />
      {modalDivergents ? <Divergents /> : <></>}
      {loading ? (
        <Loading>
          <AiOutlineLoading size={50} />
        </Loading>
      ) : (
        <Container>
          <Body>
            <Title>Confirmação de pedido</Title>
            <Customer handleMenuAddress={handleMenuAddress} />
            <Products cart={cart} priceDecimals={priceDecimals} />
            {(storePickUp || storeCorreios || storeOnwDelivery) && (
              <Delivery
                setPickUp={setPickUp}
                setOnwDelivery={setOnwDelivery}
                addressSelected={addressSelected}
                setAddressSelected={setAddressSelected}
                pickUp={pickUp}
                onwDelivery={onwDelivery}
                setCurrentFreight={setCurrentFreight}
                verifyService={verifyService}
                currentFreight={currentFreight}
                deliveryTaxValue={deliveryTaxValue}
                setDeliveryTaxValue={setDeliveryTaxValue}
                correios={correios}
                setCorreios={setCorreios}
              />
            )}
            {(deliveryPaymentsMethods?.money ||
              deliveryPaymentsMethods?.credit ||
              deliveryPaymentsMethods?.debit ||
              deliveryPaymentsMethods?.voucher ||
              storeCreditCard ||
              storeBankBill) && (
              <Payment
                setMoney={setMoney}
                money={money}
                setCurrentCard={setCurrentCard}
                setChange={setChange}
                change={change}
                setCardNumber={setCardNumber}
                setValidity={setValidity}
                validity={validity}
                setCvv={setCvv}
                cvv={cvv}
                setCardholderName={setCardholderName}
                cardholderDocument={cardholderDocument}
                cardholderName={cardholderName}
                setCardholderDocument={setCardholderDocument}
                cardNumber={cardNumber}
                currentCard={currentCard}
                bankBill={bankBill}
                setBankBill={setBankBill}
                creditCard={creditCard}
                setCreditCard={setCreditCard}
                deliveryPayment={deliveryPayment}
                setDeliveryPayment={setDeliveryPayment}
                incorrectCardNumber={incorrectCardNumber}
                setIncorrectCardNumber={setIncorrectCardNumber}
              />
            )}
            <Note setNote={setNote} />
            <Confirmation
              onClick={() => handleFinishOrderConfirmation()}
              color={color}
            >
              <span>Confirmar Pedido</span>
            </Confirmation>
          </Body>
          <Total
            total={total}
            currentFreight={currentFreight}
            verifyService={verifyService}
            deliveryTax={deliveryTax}
            totalOrder={totalOrder}
          />
          <ConfirmationBottom
            onClick={() => handleFinishOrderConfirmation()}
            color={color}
            disab
          >
            <span>Confirmar Pedido</span>
          </ConfirmationBottom>
        </Container>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...CartActions,
      ...MainActions,
      ...DeliveryActions,
      ...CustomerActions,
      ...OrderActions,
      ...StoreActions,
    },
    dispatch
  );

function getValueUnitary(product) {
  if (product?.prices?.length > 0) {
    return product.prices[0].promotional_price > 0
      ? product.prices[0].promotional_price
      : product.prices[0].regular_price;
  }

  return 0;
}

function getValueTotal(product) {
  if (product?.prices?.length > 0) {
    return product.prices[0].promotional_price > 0
      ? product.prices[0].promotional_price * product.amount
      : product.prices[0].regular_price * product.amount;
  }
  return 0;
}

const mapStateToProps = (state) => ({
  signed: state?.auth?.signed,
  color:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.color_primary,
  user: state?.user?.profile,
  cart: state?.cart?.products.map((product) => ({
    ...product,
    value_unitary: getValueUnitary(product),
    value_total: getValueTotal(product),
  })),
  total: formatPrice(
    state.cart.products.reduce((total, product) => {
      if (product.total > 0) {
        return total + product.total;
      }
      if (product.prices[0].promotional_price > 0) {
        return total + product.prices[0].promotional_price * product.amount;
      }
      return total + product.prices[0].regular_price * product.amount;
    }, 0),
    2
  ),
  totalFloat: state.cart.products.reduce((total, product) => {
    if (product.total > 0) {
      return total + product.total;
    }
    if (product.prices[0].promotional_price > 0) {
      return total + product.prices[0].promotional_price * product.amount;
    }
    return total + product.prices[0].regular_price * product.amount;
  }, 0),
  totalWithTax:
    parseFloat(state.store.delivery_tax || 0) +
    state.cart.products.reduce((total, product) => {
      if (product.total > 0) {
        return total + product.total;
      }
      if (product.prices[0].promotional_price > 0) {
        return total + product.prices[0].promotional_price * product.amount;
      }
      return total + product.prices[0].regular_price * product.amount;
    }, 0),
  currentAddress: state?.delivery?.currentAddress,
  restrictDeliveryArea:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.restrict_delivery_area,
  modalDivergents: state?.cart?.modalDivergents,
  priceDecimals:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.price_decimals,
  deliveryTax: state?.store && state.store.delivery_tax,
  loading: state?.user?.loadingCustomer,
  // eslint-disable-next-line
  deliveryPaymentsMethods: state.store?.storeInfo?.payment_methods_on_delivery,
  deliveryPaymentsCards:
    // eslint-disable-next-line
    state.store?.storeInfo?.payment_methods_on_delivery?.cards,
  orderMinimunValue: state.store.storeInfo.settings.order_minimun_value,
  modalAddress: state.delivery.modalAddress,
  // eslint-disable-next-line
  storePickUp: state.store.storeInfo?.settings?.delivery_ways?.pick_up,
  // eslint-disable-next-line
  storeCorreios: state.store.storeInfo?.settings?.delivery_ways?.correios,
  // eslint-disable-next-line
  storeOnwDelivery: state.store.storeInfo?.settings?.delivery_ways?.onw_delivery,
  // eslint-disable-next-line
  storeMethodsOnlineId: state.store.storeInfo?.payment_methods_online?.id,
  // eslint-disable-next-line
  storeCreditCard: state.store.storeInfo?.payment_methods_online ? state.store.storeInfo?.payment_methods_online.credit : false,
  // eslint-disable-next-line
  storeBankBill: state.store.storeInfo?.payment_methods_online ? state.store.storeInfo?.payment_methods_online.bank_bill : false,
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckOut);
