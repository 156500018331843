import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';

const animate = keyframes`
  from {
		bottom: -10%;
	}
	to {
		bottom: -50%;
  }
`;

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  min-height: 60%;
  max-height: 80%;
  background: #fff;
  border-radius: 10px;

  @media (max-width: 1150px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    animation: ${animate} 0.6s;
  }
`;
export const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: ${(props) => props.color};
  color: #fff;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;

  @media (max-width: 1150px) {
    border-radius: 0;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

export const Box = styled.div`
  flex-direction: column;
  display: flex;
  height: auto;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 10px 10px 0 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  flex: 3;
  padding: 10px 0;
  font-size: 30px;

  @media (max-width: 420px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  font-size: 24px;
  padding: 0 0 10px 0;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const IconArea = styled.div`
  flex: 1;

  svg {
    display: none;
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  @media (max-width: 1150px) {
    svg {
      display: flex;
    }
  }
`;

export const BoxWorkPeriod = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px 0 0 0;
  flex-wrap: wrap;
`;

export const WorkPeriodArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 110px;
  flex: 1;

  & + div {
    border-left: 1px solid #eee;
  }
`;

export const WeekTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: bold;
`;

export const Row = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0px 5px;

  span {
    padding: 5px;
    font-weight: bold;
  }
`;

export const DeliveryPaymentMethods = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardsArea = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 50px;
  }

  & + div {
    border-top: 1px solid #eee;
  }
`;

export const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
`;

export const Cards = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  img {
    margin-bottom: 10px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px 10px 0;

  & + div {
    padding: 0 10px 10px 10px;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  a {
    color: ${(props) => props.theme.colors.grayDark};
    text-decoration: none;

    & + a {
      margin-left: 20px;
    }
  }

  svg {
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
`;
