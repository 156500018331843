import { takeLatest, all, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

import api from '../../../services/api';
import history from '../../../services/history';

import { getStoreSlug } from '../../../util/StoreUtil';

import {
  cleanCart,
  setDivergentProducts,
  handleModalDivergents,
} from '../cart/actions';

import { handleLoadingTracks, setTrackingOrder } from './actions';

import { validationResponse } from '../../../util/validation';
import { setFreights } from '../delivery/actions';

export function* setOrder({ payload }) {
  yield put(setFreights([]));
  const data = {
    customer_id: payload.customer.id,
    products: payload.products,
    value_delivery_tax: parseFloat(payload.deliveryTax),
    customer_observation: payload.note,
    address_id: payload.addressId,
    total: payload.totalOrder,
  };

  // online payments
  if (payload.onlinePaymentMethod?.id) {
    data.payment_type = payload.paymentType;
    data.online_payment_method = payload.onlinePaymentMethod;
  }

  // delivery payments
  if (payload.deliveryPaymentMethod?.type) {
    data.payment_type = payload.paymentType;
    data.delivery_payment_method = payload.deliveryPaymentMethod;
  }

  // delivery type
  if (payload.deliveryType) {
    data.delivery_type = payload.deliveryType;
  }

  // delivery methods
  if (payload.deliveryMethod) {
    data.delivery_method = payload.deliveryMethod;
  }

  const response = yield api.finishOrder(data);

  if (response?.response?.status === 404) {
    swal.getState().actions.cancel.closeModal = true;
    swal.close();
    yield put(setDivergentProducts(response?.response?.data));
    return yield put(handleModalDivergents(true));
  }

  // eslint-disable-next-line
  if (response?.response?.data?.payment_error) {
    return swal({
      title: 'Falha no envio do pedido :(',
      text: response.response.data.error,
      icon: 'error',
    });
  }

  const validation = validationResponse(response);

  if (!validation) {
    return swal({
      title: response?.data?.error || 'Falha no envio do pedido :(',
      text: 'Tente novamente mais tarde...',
      icon: 'error',
    });
  }

  yield put(cleanCart());
  swal.getState().actions.cancel.closeModal = true;
  swal.close();
  return history.push(`/${getStoreSlug()}/pedidos`);
}

export function* checkLocation({ payload }) {
  const response = yield api.checkLocation(
    payload.currentAddress.latitude,
    payload.currentAddress.longitude
  );

  const validation = validationResponse(response);

  if (!validation) {
    return false;
  }
  if (response && response.data && !response.data.response) {
    return toast.error('Local informado está fora da área de entrega.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return true;
}

export function* getTrackingOrder({ payload }) {
  yield put(handleLoadingTracks(true));
  const data = {
    tracking_code: payload.trackingCode,
  };
  const response = yield api.getTrackingOrder(data);

  yield put(setTrackingOrder(response.data));

  yield put(handleLoadingTracks(false));
}

export default all([
  takeLatest('SET_ORDER', setOrder),
  takeLatest('CHECK_LOCATION', checkLocation),
  takeLatest('GET_TRACKING_ORDER', getTrackingOrder),
]);
