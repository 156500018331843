import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import image from '../../../assets/icons/error/401.svg';

import Layout from '../components/Layout';

import { signOut } from '../../../store/modules/auth/actions';

export default function Error401() {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'I9P - Erro 401';
    dispatch(signOut());
  }, []);

  return (
    <Layout
      title="Autorização requerida"
      text=" Verifique se os dados de autenticação foram informados corretamente ou se possuem autorização de acesso."
      image={image}
      reload
    />
  );
}
