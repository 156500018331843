import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Title = styled.div`
  margin-top: 100px;
  font-weight: bold;
  font-size: 30px;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 20px;

  @media (max-width: 576px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
  @media print {
    display: none;
  }
`;
