import React from 'react';

import { Container, Line } from './styles';

export default function OutLine({ color }) {
  return (
    <Container color={color || '#727c8e'}>
      <Line>
        <div />
      </Line>
      <span>ou</span>
      <Line>
        <div />
      </Line>
    </Container>
  );
}
