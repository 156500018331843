import { takeLatest, all, put } from 'redux-saga/effects';

import moment from 'moment';

import { toast } from 'react-toastify';
import api from '../../../services/api';

import { validationResponse } from '../../../util/validation';

import { setAlerts } from './actions';
import { getStoreSlug } from '../../../util/StoreUtil';

export function* getAlerts() {
  const response = yield api.getAlerts();

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar alertas, tente novamente mais tarde.');
  }
  const alerts = [];

  response.data.map((alert) => {
    const localAlert = localStorage.getItem(
      `i9p:${getStoreSlug()}:alert:${alert.id}`
    );

    if (!localAlert) {
      return alerts.push({ ...alert, showed: false });
    }

    const alertDate = moment(JSON.parse(localAlert).date).format('L');
    const today = moment(new Date()).format('L');

    if (alertDate !== today) {
      return alerts.push({ ...alert, showed: false });
    }

    return alerts.push({ ...alert, showed: true });
  });

  return yield put(setAlerts(alerts));
}

export default all([takeLatest('GET_ALERTS', getAlerts)]);
