export function setOrder(
  customer,
  products,
  deliveryTax,
  totalOrder,
  paymentType,
  deliveryType,
  deliveryMethod,
  note,
  addressId,
  deliveryPaymentMethod,
  onlinePaymentMethod
) {
  return {
    type: 'SET_ORDER',
    payload: {
      customer,
      products,
      deliveryTax,
      totalOrder,
      paymentType,
      deliveryType,
      deliveryMethod,
      note,
      addressId,
      deliveryPaymentMethod,
      onlinePaymentMethod,
    },
  };
}

export function checkLocation(currentAddress) {
  return {
    type: 'CHECK_LOCATION',
    payload: { currentAddress },
  };
}

export function getTrackingOrder(trackingCode) {
  return {
    type: 'GET_TRACKING_ORDER',
    payload: { trackingCode },
  };
}

export function handleLoadingTracks(state) {
  return {
    type: 'HANDLE_LOADING_TRACKS',
    state,
  };
}

export function setTrackingOrder(tracks) {
  return {
    type: 'SET_TRACKING_ORDER',
    tracks,
  };
}
