export function getGroups(
  currentParentId,
  currentSearch,
  currentCategoryId,
  nextPage
) {
  return {
    type: 'GET_GROUPS',
    currentParentId,
    currentSearch,
    currentCategoryId,
    nextPage,
  };
}

export function setGroups(groups) {
  return {
    type: 'SET_GROUPS',
    payload: groups,
  };
}

export function handleLoadingProducts(state) {
  return { type: 'HANDLE_LOADING_PRODUCTS', state };
}

export function handleLoadingSeeMore(state) {
  return { type: 'HANDLE_LOADING_SEE_MORE', state };
}

export function handlePagination(
  currentParentId,
  currentCategoryId,
  nextPage,
  child
) {
  return {
    type: 'HANDLE_PAGINATION',
    currentParentId,
    currentCategoryId,
    nextPage,
    child,
  };
}

export function setStatePagination(
  currentParentId,
  currentCategoryId,
  nextPage
) {
  return {
    type: 'SET_STATE_PAGINATION',
    currentParentId,
    currentCategoryId,
    nextPage,
  };
}

export function handleNavigation(category) {
  return {
    type: 'HANDLE_NAVIGATION',
    category,
  };
}

export function setNavigation(category) {
  return {
    type: 'SET_NAVIGATION',
    category,
  };
}

export function cleanNavigation() {
  return { type: 'CLEAN_NAVIGATION' };
}

export function handleSearch(currentSearch) {
  return {
    type: 'HANDLE_SEARCH',
    currentSearch,
  };
}

export function setCurrentSearch(currentSearch) {
  return {
    type: 'SET_CURRENT_SEARCH',
    currentSearch,
  };
}

export function handleProductDetail(state, product) {
  return {
    type: 'HANDLE_PRODUCT_DETAIL',
    state,
    product,
  };
}

export function setCurrentProduct(product) {
  return {
    type: 'SET_CURRENT_PRODUCT',
    product,
  };
}

export function setProductToContact(product) {
  return {
    type: 'SET_PRODUCT_TO_CONTACT',
    payload: { product },
  };
}

export function setContactRequest(contact) {
  return {
    type: 'SET_CONTACT_REQUEST',
    payload: { contact },
  };
}
