import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';
import { darken } from 'polished';

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  min-height: 60%;
  max-height: 80%;
  background: #fff;
  border-radius: 10px;

  @media (max-width: 1150px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0px;
    margin-top: 0px;
    padding: 0 0 50px 0;
  }
`;

export const Header = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  background: ${(props) => props.color};
  color: #fff;
  max-height: 50px;

  @media (max-width: 1150px) {
    display: flex;
  }
`;

export const Title = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`;

export const IconArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
  }
`;

export const Body = styled.div`
  display: flex;
  height: auto;
  min-height: 350px;
  width: 100%;

  @media (max-width: 1050px) {
    flex-direction: column;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const SlideArea = styled.div`
  border-right: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1.5;
  flex-direction: column;

  .slide {
    width: 600px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1300px) {
      width: 500px;
      height: 500px;
    }
    @media (max-width: 576px) {
      width: 450px;
      height: 450px;
    }
    @media (max-width: 520px) {
      width: 400px;
      height: 400px;
    }
    @media (max-width: 476px) {
      width: 350px;
      height: 350px;
    }
    @media (max-width: 420px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 376px) {
      width: 250px;
      height: 250px;
    }
    @media (max-width: 320px) {
      width: 200px;
      height: 200px;
    }
  }
`;

export const LeftButton = styled.div``;
export const RightButton = styled.div``;

export const ImageArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fff;
  width: auto;
  height: auto;
`;

export const Image = styled.div`
  margin-top: 5px;
  background-image: ${(props) => {
    return `url(${props.image})`;
  }};
  height: 500px;
  width: ${(props) => (props.caroucel ? 'auto' : '500px')};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  @media (max-width: 768px) {
    height: 300px;
  }
  @media (max-width: 576px) {
    height: 250px;
  }
  @media (max-width: 520px) {
    height: 250px;
  }
  @media (max-width: 420px) {
    width: 150px;
    height: 150px;
  }
`;

export const ProductArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  border-radius: 0 10px 0 0;
  padding: 10px;

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
  @media (max-width: 420px) {
    width: 100%;
    padding: 0px;
  }
`;

export const Description = styled.div`
  padding: 10px;
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 30px;
  padding: 5px;
  border-bottom: 2px solid ${(props) => props.color};

  @media (max-width: 768) {
    padding: 10px;
  }

  @media (max-width: 420px) {
    font-size: 25px;
  }
`;

export const Code = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px;
`;

export const PriceArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px;

  @media (max-width: 768) {
    padding: 10px;
  }
`;

export const Price = styled.label`
  text-decoration: line-through;
  color: red;
  font-weight: bold;
`;

export const ProductPrices = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
`;

export const Prices = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Old = styled.div`
  display: flex;
  margin-right: 20px;
  font-size: 20px;

  span {
    margin-right: 5px;
  }
`;

export const NewPrice = styled.div`
  display: flex;
  font-size: 40px;

  strong {
    margin-right: 5px;
  }

  @media (max-width: 420px) {
    font-size: 30px;
  }
`;
export const Consult = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const UnitDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px;
`;

export const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${(props) => (!props.price ? '1px solid #eee' : 0)};
  width: 100%;
  padding: 20px 0px 20px 5px;
  overflow-y: auto;

  span {
    margin-bottom: 10px;
  }

  div {
    width: 100%;
    color: ${(props) => props.theme.colors.grayLight};
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 1150px) {
    margin-left: 0px;
  }
`;

export const ButtonAddArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 1150px) {
    padding: 0 0 40px 0;
  }
`;

export const ButtonAdd = styled.button`
  background: ${(props) => props.color};
  padding: 10px;
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  /* display: ${(props) => (props.visible ? 'flex' : 'none')}; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 50%;
  box-shadow: 0px 2px 4px #00000050;
  transition: all 0.6s;

  &:hover {
    background: ${(props) => darken(0.1, props.color)};
    box-shadow: 2px 4px 6px #00000050;
  }

  @media(max-width: 1150px){
    margin-bottom: 50px;
    width: 90%;
  }
`;

export const SignUpArea = styled.div`
  width: 100%;
  strong {
    font-size: 30px;
  }
`;

export const SignUp = styled.button`
  background: ${(props) => props.color};
  box-shadow: 0px 2px 4px #00000050;
  padding: 2px 10px;
  margin-left: 10px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  transition: all 0.6s;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Option = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  color: ${(props) => !props.available && '#ddd'};

  @media (max-width: 1050px) {
    padding: 10px;
    & + div {
      border-top: 1px solid #eee;
    }
  }
`;

export const ItemNamePrice = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const OptionPriceMobila = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const OptionPrice = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ItemData = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ItemName = styled.div`
  display: flex;
  text-align: center;
`;

export const ButtonAddItem = styled.div`
  display: flex;
  padding: 5px;
  color: ${(props) => props.color};
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.6s;

  &:hover {
    color: ${(props) => darken(0.2, props.color)};
  }
`;

export const CompleteButtonItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #eee;

  .minus {
    color: ${(props) => props.theme.colors.redDark};
  }

  .plus {
    color: ${(props) => props.color};
  }
`;

export const Box = styled.div`
  display: flex;
  cursor: pointer;
  font-weight: bold;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px;
  & + div {
    border-left: 1px solid #eee;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 22px;
  background: #eee;
  color: #000;
  justify-content: space-between;

  label {
    color: #bbb;
    font-size: 18px;
  }

  @media (max-width: 1380px) {
    font-size: 18px;
    flex-direction: column;
    align-items: flex-start;

    span {
      padding: 2px 0;
    }

    label {
      padding: 2px 0;
      font-size: 16px;
    }
  }

  @media (max-width: 1050px) {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Obligatory = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Choices = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #bbb;
`;

export const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-size: 20px;
  background: #fff;
  z-index: 2;
`;
export const StickySection = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 74px;
  font-size: 20px;
  background: #fff;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

export const Space = styled.div`
  flex: 1;
  @media (max-width: 1150px) {
    flex: 0;
  }
`;

export const ButtonQtdArea = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1150px) {
    flex: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonQtd = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 5px;
  width: 50%;

  @media (max-width: 1150px) {
    width: 50%;
  }

  .minus {
    color: ${(props) => props.theme.colors.redDark};
  }

  .plus {
    color: ${(props) => props.color};
  }
`;

export const Total = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 1150px) {
    padding: 10px 0;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 1150px) {
    padding: 0 0 20px 0;
  }
`;
export const Contact = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

export const Call = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: ${(props) => props.color};
  transition: background 0.6s, box-shadow 0.6s;
  color: #fff;
  box-shadow: 0px 2px 3px #00000050;

  &:hover {
    background: ${(props) => darken(0.1, props.color)};
    box-shadow: 2px 5px 6px #00000050;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
const expand = (color) => keyframes`
0% {
  height: 50px;
  width: 50px;
  background: ${color}
}
100% {
  height: 50px;
  width: 50%;
  background: ${color}
}`;

export const CompleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.color};
  box-shadow: 0px 3px 6px ${(props) => props.color};
  border-radius: 10px;
  height: 50px;
  width: 50%;
  padding: 10px;
  animation: ${(props) => expand(props.color)} 1s;
  align-self: center;
  margin-top: 20px;

  @media (max-width: 1150px) {
    width: 90%;
  }
`;

export const Minus = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Quantity = styled.span`
  font-weight: bold;
  color: #fff;
`;

export const Add = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
