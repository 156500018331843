import produce from 'immer';

const INITIAL_STATE = {
  loadingTracks: false,
  tracks: [],
};

export default function main(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'HANDLE_LOADING_TRACKS': {
        draft.loadingTracks = action.state;
        break;
      }
      case 'SET_TRACKING_ORDER': {
        draft.tracks = action.tracks;
        break;
      }
      default:
        break;
    }
  });
}
