import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AiOutlineMail } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { MdKeyboardArrowRight } from 'react-icons/md';

import Input from '../../../../../components/Input';

import * as UserActions from '../../../../../../store/modules/user/actions';

import { signInRequest } from '../../../../../../store/modules/auth/actions';

import {
  LoginContainer,
  Form,
  Email,
  SubTitle,
  Password,
  ForgetPassword,
  ButtonsArea,
  IconArea,
  Dot,
  Text,
  ButtonEnter,
  RegisterFooter,
} from './styles';

function Login({ color, handleRegister, handleRecoveryPassword }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(signInRequest(email, password));
  }
  return (
    <LoginContainer>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Email>
          <SubTitle>
            <AiOutlineMail size={20} />
            <span>E-mail</span>
          </SubTitle>
          <div>
            <Input
              ref={inputRef}
              type="email"
              placeholder="Digite seu e-mail"
              required
              setState={setEmail}
            />
          </div>
        </Email>

        <Password>
          <SubTitle>
            <RiLockPasswordLine size={20} />
            <span>Senha</span>
          </SubTitle>
          <div>
            <Input
              type="password"
              placeholder="Digite sua senha"
              required
              setState={setPassword}
            />
          </div>
          <ForgetPassword onClick={() => handleRecoveryPassword(true)}>
            Esqueceu sua senha?
          </ForgetPassword>
        </Password>

        <ButtonsArea>
          <ButtonEnter color={color}>
            <Text>Entrar</Text>
            <IconArea color={color}>
              <Dot>
                <MdKeyboardArrowRight />
              </Dot>
            </IconArea>
          </ButtonEnter>
        </ButtonsArea>
      </Form>
      <RegisterFooter>
        <span>Ainda não possui cadastro? </span>
        <span onClick={() => handleRegister(true)} role="button" tabIndex={0}>
          Cadastre-se
        </span>
      </RegisterFooter>
    </LoginContainer>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...UserActions }, dispatch);
const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
