import produce from 'immer';

const INITIAL_STATE = {
  contactVisible: false,
  cartVisible: true,
  menuProfileVisible: false,
  alterPasswordVisible: false,
  headerVisible: true,
  loading: true,
  menuAddress: false,
  focusSearch: false,
  modalAbout: false,
};

export default function main(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'HANDLE_CART': {
        draft.cartVisible = action.state;
        break;
      }
      case 'SET_CART_VISIBLE': {
        draft.cartVisible = action.payload.state;
        break;
      }
      case 'HANDLE_LOADING': {
        draft.loading = action.state;
        break;
      }

      case 'HANDLE_PROFILE': {
        draft.menuProfileVisible = !state.menuProfileVisible;
        break;
      }

      case 'HANDLE_ALTER_PASSWORD': {
        draft.alterPasswordVisible = action.state;
        break;
      }

      case 'HANDLE_HEADER': {
        draft.headerVisible = action.state;
        break;
      }
      case 'HANDLE_MENU_ADDRESS': {
        draft.menuAddress = action.state;
        break;
      }

      case 'HANDLE_FOCUS_SEARCH': {
        draft.focusSearch = action.state;
        break;
      }
      case 'HANDLE_CONTACT': {
        draft.contactVisible = action.state;
        break;
      }

      case 'SIGN_OUT': {
        draft.menuProfileVisible = false;
        break;
      }

      case 'HANDLE_MODAL_ABOUT': {
        draft.modalAbout = action.payload.state;
        break;
      }

      default:
        break;
    }
  });
}
