import styled from 'styled-components';

export const TabContainer = styled.div`
  display: none;
  bottom: 0;
  position: fixed;
  width: 100%;
  background: ${(props) => props.color};
  color: #fff;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 2px 0;
  font-size: 14px;
  box-shadow: 0 -5px 5px -5px #00000050;

  @media (max-width: 1150px) {
    display: flex;
    div {
      svg {
        cursor: pointer;
        height: 40px;
        width: 40px;
      }
      img {
        cursor: pointer;
        height: 40px;
        width: 40px;
      }
    }
  }
  @media (max-width: 420px) {
    div {
      svg {
        height: 20px;
        width: 20px;
      }

      img {
        height: 20px;
        width: 20px;
      }
    }
  }
`;

export const TabNav = styled.div`
  cursor: pointer;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.6s;
  flex-direction: column;
`;
