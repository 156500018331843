import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 10px;
  color: ${(props) => props.theme.colors.grayDark};
  border-bottom: 1px solid #eee;
  position: relative;

  @media (max-width: 576px) {
    background: ${(props) => props.color};
    color: #fff;
  }
`;

export const Space = styled.div`
  flex: 1;
  display: flex;
`;

export const CurrentAddressArea = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;
export const Title = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`;
export const IconArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  svg {
    display: none;
    width: 30px;
    height: 30px;

    @media (max-width: 576px) {
      display: flex;
    }
  }

  @media (max-width: 576px) {
    cursor: pointer;
  }
`;

export const CurrentAddress = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 20px;
  width: 100%;
  height: auto;
  box-shadow: 0px 2px 4px #00000050;
  border-radius: 10px;
  display: flex;
`;

export const WithouAddress = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const Addresses = styled.div`
  overflow-y: auto;
`;

export const CardAddress = styled.div`
  width: ${(props) => (props.edit ? '80%' : '100%')};
  min-height: 50px;
  height: auto;
  box-shadow: ${(props) =>
    props.selected ? ' 2px 4px 6px #00000050' : ' 0px 2px 4px #00000050'};
  transition: box-shadow 0.6s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.edit ? '0 0 0 50px' : '0 0 0 80px')};
  cursor: pointer;
  background: ${(props) => (props.selected ? '#eee' : '#fff')};
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  transition: background 0.6s, box-shadow 0.1s, width 1s, padding 1s;
  z-index: 2;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }
  @media (max-height: 768px) {
    padding: 0 10px;
  }

  @media (max-width: 420px) {
    padding: 10px;
  }
`;

export const CardEdit = styled.div`
  width: ${(props) => (props.edit ? '20%' : '0%')};
  opacity: ${(props) => (props.edit ? '1' : '0')};
  min-height: 50px;
  height: auto;
  box-shadow: ${(props) =>
    props.selected ? ' 2px 4px 6px #00000050' : ' 0px 2px 4px #00000050'};
  transition: box-shadow 0.6s;
  border-radius: 0 10px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#eee' : '#fff')};
  color: ${(props) => props.theme.colors.grayDark};
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  transition: background 0.6s, box-shadow 0.1s, width 1s, opacity 0.6s;
  z-index: 1;

  @media (max-width: 420px) {
    padding: 2px;
  }
`;

export const Edit = styled.div`
  width: 10%;
  height: 50px;
  background: ${(props) => (props.selected ? '#eee' : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.6s, color 0.2s;
  border-radius: ${(props) => (props.edit ? '0' : '0 10px 10px 0')};

  &:hover {
    color: ${(props) => (props.selected ? '#fff' : '#eee')};
  }
`;

export const DeleteArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: color 0.2s;
    &:hover {
      color: #ddd;
    }
  }
`;

export const EditArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #727c8e;

  svg {
    transition: color 0.2s;
    &:hover {
      color: #ddd;
    }
  }
`;

export const ButtonsArea = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Button = styled.div`
  width: 48%;
  height: 50px;
  box-shadow: 0px 2px 4px #00000050;
  transition: box-shadow 0.6s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  transition: all 0.6s;
  background: ${(props) => props.color};
  font-weight: bold;
  padding: 10px;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin-top: 10px;
  }

  @media (max-height: 768px) {
    height: 40px;

    svg {
      margin-left: 0px;
    }
  }
  @media (max-height: 660px) {
    height: 40px;
    font-size: 14px;
  }
`;
