import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FiShoppingCart } from 'react-icons/fi';
import * as MainActions from '../../../../store/modules/main/actions';

import { formatPrice } from '../../../../util/format';

import { Container, ButtonCart } from './styles';

function TabCart({ cart, color, handleCart, cartVisible, showcase, total }) {
  return showcase ? (
    <></>
  ) : (
    <>
      {' '}
      {cart.length > 0 ? (
        <Container>
          <ButtonCart
            cartVisible={cartVisible}
            onClick={() => handleCart(true)}
            color={color}
          >
            <FiShoppingCart />
            <label>{total}</label>
          </ButtonCart>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MainActions, dispatch);

const mapStateToProps = (state) => ({
  showcase: state.store.storeInfo.settings.showcase,
  color: state.store.storeInfo.settings.color_primary,
  cartVisible: state.main.cartVisible,
  cart: state.cart.products.map((product) => ({
    ...product,
  })),

  total: formatPrice(
    state.cart.products.reduce((total, product) => {
      if (product.total > 0) {
        return total + product.total;
      }
      // eslint-disable-next-line
      if (product?.prices[0]?.promotional_price > 0) {
        return total + product.prices[0].promotional_price * product.amount;
      }
      // eslint-disable-next-line
      return total + product?.prices[0]?.regular_price * product.amount;
    }, 0),
    2
  ),
  totalFloat: state.cart.products.reduce((total, product) => {
    if (product.total > 0) {
      return total + product.total;
    }
    // eslint-disable-next-line
    if (product.prices[0]?.promotional_price > 0) {
      return total + product.prices[0].promotional_price * product.amount;
    }
    // eslint-disable-next-line
    return total + product.prices[0]?.regular_price * product.amount;
  }, 0),
  totalWithTax:
    parseFloat(state.store.delivery_tax || 0) +
    state.cart.products.reduce((total, product) => {
      if (product.total > 0) {
        return total + product.total;
      }
      // eslint-disable-next-line
      if (product.prices[0]?.promotional_price > 0) {
        return total + product.prices[0].promotional_price * product.amount;
      }
      // eslint-disable-next-line
      return total + product.prices[0]?.regular_price * product.amount;
    }, 0),

  deliveryTax: state.store.delivery_tax,
});

export default connect(mapStateToProps, mapDispatchToProps)(TabCart);
