import { takeLatest, all, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';

import { validationResponse } from '../../../util/validation';

import {
  handleLoadingSendEmail,
  handleRecoveryPassword,
  setCustomer,
  setLoadingCustomer,
} from './actions';
import { handleLoginModal } from '../auth/actions';

export function* requestRecoveryPassword(payload) {
  const { email } = payload;
  yield put(handleLoadingSendEmail(true));

  const response = yield api.requestRecoveryPassword(email);

  if (response.response && response.response.status === 404) {
    yield put(handleLoadingSendEmail(false));
    return toast.error(response.response.data.error);
  }

  if (response && !response.data && !response.response) {
    yield put(handleLoadingSendEmail(false));
    return toast.error('Erro ao recuperar senha, tente novamente mais tarde.');
  }

  yield put(handleLoadingSendEmail(false));
  yield put(handleRecoveryPassword(false));
  yield put(handleLoginModal(false));

  return toast.success('E-mail enviado com sucesso!');
}

export function* getCustomer({ payload }) {
  const response = yield api.getCustomer(payload.id);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar usuário, tente novamente mais tarde');
  }

  yield put(setCustomer(response.data));
  return yield put(setLoadingCustomer(false));
}

export default all([
  takeLatest('REQUEST_RECOVERY_PASSWORD', requestRecoveryPassword),
  takeLatest('GET_CUSTOMER', getCustomer),
]);
