import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FaWhatsapp } from 'react-icons/fa';

import { toast } from 'react-toastify';

import { RiCloseCircleLine } from 'react-icons/ri';
import sendWhatsMessage from '../../../../services/whatsApp';

import OutLine from '../../../components/OutLine';

import * as MainActions from '../../../../store/modules/main/actions';
import * as GroupsActions from '../../../../store/modules/groups/actions';

import {
  Container,
  Modal,
  Title,
  CloseArea,
  Body,
  Form,
  Text,
  Input,
  Name,
  Phone,
  ButtonArea,
  Button,
  WhatsAppArea,
  WhatsApp,
} from './styles';

function Contact({
  contactVisible,
  handleContact,
  color,
  productToContact,
  setContactRequest,
  showcasePhone,
  setProductToContact,
}) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  function handleCloseModal(event) {
    if (event.currentTarget === event.target) {
      handleContact(false);
    }
  }

  function handleName(text) {
    setName(text);
  }

  const phoneMask = (text) => {
    return text
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d)(\d{4})$/, '$1$2-$3');
  };

  function handlePhone(text) {
    setPhone(phoneMask(text));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let price = 0;

    if (
      productToContact &&
      productToContact.price &&
      productToContact.price.length > 0 &&
      productToContact.price.promotional_price.length > 0
    ) {
      price = productToContact.price.promotional_price;
    } else if (
      productToContact &&
      productToContact.price &&
      productToContact.price.length > 0 &&
      productToContact.price.regular_price.length > 0
    ) {
      price = productToContact.price.regular_price;
    }

    if (phone.length < 15) {
      return toast.info('O telefone deve conter pelo menos 9 números.');
    }

    setContactRequest({
      name,
      phone,
      productId: productToContact.id,
      productName: productToContact.name,
      productPrice: price,
    });
    setProductToContact(null);
    setName('');
    setPhone('');
    return handleContact(false);
  }

  function handleWhatsApp() {
    // open new tab in navigator
    const mode = 'link';

    // open modal with conversation
    // const mode = 'popup';

    const phoneToContact = `55${showcasePhone}`;
    const message = `Olá,\n\nTenho interesse em *${productToContact.name}*, e gostaria de mais informações, obrigado.\n\n_Solicitação gerada via I9P_`;

    sendWhatsMessage(mode, phoneToContact, message);
    setProductToContact(null);
    setName('');
    setPhone('');
    handleContact(false);
  }
  return contactVisible ? (
    <Container onClick={(e) => handleCloseModal(e)}>
      <Modal>
        <Title>
          <div className="space" />
          <div className="title">Contato</div>
          <CloseArea>
            <RiCloseCircleLine />
          </CloseArea>
        </Title>
        <Body>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Text>
              <span>
                Informe seus dados e nós entraremos em contato com você!
              </span>
            </Text>
            <Name>
              <span>Nome</span>
              <Input
                maxLength={50}
                value={name}
                onChange={(text) => handleName(text.target.value)}
                required
                placeholder="Informe seu nome"
              />
            </Name>
            <Phone>
              <span>Telefone</span>
              <Input
                maxLength={15}
                value={phone}
                onChange={(text) => handlePhone(text.target.value)}
                required
                placeholder="Informe um telefone para contato"
              />
            </Phone>
            <ButtonArea>
              <Button color={color}>Enviar</Button>
            </ButtonArea>
          </Form>
          <OutLine />
          <WhatsAppArea>
            <WhatsApp onClick={() => handleWhatsApp()}>
              <FaWhatsapp />
              <span>Entre em contato</span>
            </WhatsApp>
          </WhatsAppArea>
        </Body>
      </Modal>
    </Container>
  ) : (
    <></>
  );
}

const mapStateToProps = (state) => ({
  contactVisible: state.main.contactVisible,
  color: state.store.storeInfo.settings.color_primary,
  showcasePhone: state.store.storeInfo.settings.showcase_phone,
  productToContact: state.groups.productToContact,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...MainActions, ...GroupsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
