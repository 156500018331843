import produce from 'immer';

const INITIAL_STATE = {
  products: [],
  dateNow: '',
  modalDivergents: false,
  divergentProducts: [],
};

export default function cart(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'ADD_TO_CART': {
        draft.dateNow = new Date().toJSON();
        const productIndex = draft.products.findIndex(
          (p) => p.id === action.product.id
        );

        if (productIndex >= 0) {
          draft.products[productIndex].amount += parseInt(
            action.product.multiple,
            10
          );
        } else {
          draft.products.push({
            ...action.product,
            amount: parseInt(action.product.multiple, 10),
            options: [],
          });
        }
        break;
      }

      case 'REMOVE_FROM_CART': {
        const productIndex = draft.products.findIndex(
          (p) => p.id === action.id
        );

        if (productIndex >= 0) {
          draft.products.splice(productIndex, 1);
        }
        break;
      }

      case 'UPDATE_AMOUNT_CART': {
        const productIndex = draft.products.findIndex(
          (p) => p.id === action.id
        );

        if (productIndex >= 0) {
          draft.products[productIndex].amount = Number(action.amount);
        }
        if (draft.products[productIndex].amount <= 0) {
          draft.products.splice(productIndex, 1);
        }
        break;
      }

      case 'CLEAN_CART': {
        draft.products = [];
        break;
      }

      case 'HANDLE_MODAL_DIVERGENTS': {
        draft.modalDivergents = action.payload.state;
        break;
      }

      case 'SET_DIVERGENT_PRODUCTS': {
        draft.divergentProducts = action.payload.products;
        break;
      }
      case 'REMOVE_DIVERGENT': {
        const divergentsIndex = draft.divergentProducts.findIndex(
          (p) => p.old.id === action.productId
        );

        if (divergentsIndex >= 0) {
          draft.divergentProducts.splice(divergentsIndex, 1);
        }
        break;
      }
      case 'UPDATE_CART_PRODUCT_PRICES': {
        const productIndex = draft.products.findIndex(
          (p) => p.id === action.id
        );

        if (productIndex >= 0) {
          draft.products[productIndex].prices = action.prices;
        }
        break;
      }

      case 'SIGN_OUT': {
        draft.products = [];
        break;
      }

      case 'SEND_TO_CART': {
        if (action.product.uuid) {
          draft.products.push({ ...action.product });
          break;
        }

        const productIndex = draft.products.findIndex(
          (p) => p.id === action.product.id
        );

        if (productIndex >= 0) {
          draft.products[productIndex].amount += parseInt(
            action.product.multiple,
            10
          );
          break;
        }

        draft.products.push({
          ...action.product,
          options: [],
        });
        break;
      }

      case 'SEND_PRODUCT_EDITED_TO_CART': {
        const productIndex = draft.products.findIndex(
          (p) => p.uuid === action.product.uuid
        );

        if (productIndex >= 0) {
          draft.products[productIndex] = action.product;
        }
        break;
      }
      default:
        break;
    }
  });
}
