import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  AiOutlineFieldTime,
  AiOutlineDollarCircle,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import ClosedIcon from '../../../../assets/icons/closed.svg';
import OpenedIcon from '../../../../assets/icons/opened.svg';

import { formatPrice } from '../../../../util/format';

import * as GroupActions from '../../../../store/modules/groups/actions';
import * as MainActions from '../../../../store/modules/main/actions';
import * as CartActions from '../../../../store/modules/cart/actions';

import {
  Container,
  StoreInfo,
  DeliveryTime,
  DeliveryTax,
  Status,
  Dot,
  IconArea,
  Online,
  Offline,
  About,
} from './styles';

function DeliveryInfo({
  deliveryWays,
  showStoreInfo,
  deliveryTime,
  deliveryMinTime,
  online,
  cartVisible,
  color,
  deliveryMinPrice,
  priceDecimals,
  minTime,
  maxTime,
  deliveryTax,
  handleModalAbout,
}) {
  const [taxVisible, setTaxVisible] = useState(false);

  useEffect(() => {
    const ownDelivery = deliveryWays?.filter((deliveryWay) => {
      return deliveryWay.name === 'Entrega própria';
    });

    if (ownDelivery?.length > 0 && ownDelivery[0]?.status) {
      setTaxVisible(true);
    } else {
      setTaxVisible(false);
    }
  }, []);

  function timeConvert(number) {
    const days = Math.floor(number / 1440);
    const hours = Math.floor((number - days * 1440) / 60);
    const minutes = Math.round(number % 60);

    return (
      <span>
        {days > 0 ? `${days}d` : <></>} {hours > 0 ? `${hours}h` : <></>}{' '}
        {minutes > 0 ? `${minutes}m` : <></>}
      </span>
    );
  }
  return showStoreInfo ? (
    <Container cartVisible={cartVisible}>
      <StoreInfo>
        {deliveryMinTime > 0 ? (
          <DeliveryTime>
            <AiOutlineFieldTime />
            {minTime && maxTime ? (
              <span>
                Tempo de entrega de {timeConvert(minTime)} -{' '}
                {timeConvert(maxTime)}
              </span>
            ) : (
              <span>
                Tempo de entrega a partir de {timeConvert(deliveryMinTime)}
              </span>
            )}
          </DeliveryTime>
        ) : (
          <DeliveryTime>
            <AiOutlineFieldTime />
            <span>Tempo de entrega a partir de {deliveryTime}</span>
          </DeliveryTime>
        )}
        {taxVisible && deliveryMinPrice && (
          <DeliveryTax>
            <AiOutlineDollarCircle />
            {deliveryTax ? (
              <span>
                Taxa de entrega de {formatPrice(deliveryTax, priceDecimals)}
              </span>
            ) : (
              <span>
                Taxa de entrega a partir de{' '}
                {formatPrice(deliveryMinPrice, priceDecimals)}
              </span>
            )}
          </DeliveryTax>
        )}
        <Status>
          {!online ? (
            <Online>
              <IconArea>
                <img className="closed" alt="Fechado" src={ClosedIcon} />
              </IconArea>
              <Dot online={online} />
              <label>Fechado</label>
              <div />
            </Online>
          ) : (
            <Offline>
              <IconArea>
                <img className="opened" alt="Aberto" src={OpenedIcon} />
              </IconArea>
              <Dot online={online} />
              <label>Aberto</label>
              <div />
            </Offline>
          )}
        </Status>
        <About onClick={() => handleModalAbout(true)} color={color}>
          <AiOutlineInfoCircle />
          <span>Sobre a loja</span>
        </About>
      </StoreInfo>
    </Container>
  ) : (
    <></>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...GroupActions, ...MainActions, ...CartActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  deliveryWays: state.store.storeInfo.delivery_ways,
  info: state.store.storeInfo,
  minTime: state.store.minTime,
  maxTime: state.store.maxTime,
  deliveryTax: state.store.delivery_tax,
  online: state.store.storeInfo.settings.online,
  currentAddress: state.delivery.currentAddress,
  deliveryMinPrice: state.store.storeInfo.settings.delivery_min_price,
  deliveryMinTime: state.store.storeInfo.settings.delivery_min_time,
  deliveryTime: state.store.storeInfo.settings.delivery_time,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
  showStoreInfo: state.store.storeInfo.settings.show_store_info_header,
  color: state.store.storeInfo.settings.color_primary,
  cartVisible: state.main.cartVisible,
  priceDecimals: state.store.storeInfo.settings.price_decimals,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInfo);
