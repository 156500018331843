import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as MainActions from '../../../../store/modules/main/actions';
import * as DeliveryActions from '../../../../store/modules/delivery/actions';
import * as AuthActions from '../../../../store/modules/auth/actions';
// import Location from './components/Location';

import { Container, ModalStyled } from './styles';
import SubmitAddress from './components/SubmitAddress';
import UserAddresses from './components/UserAddresses';
import ZipCodeArea from './components/ZipCode';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function MenuAddress({
  handleMenuAddress,
  menuAddress,
  // getAddressByGeolocation,
  // handleLocationSelected,
  // handleSubmitAddressSelected,
  // locationSelected,
  address,
  signed,
}) {
  function handleCloseModal(event) {
    if (event.currentTarget === event.target) {
      handleMenuAddress(false);
    }
  }

  // async function handleLocation() {
  //   handleLocationSelected(true);
  //   navigator.geolocation.getCurrentPosition(async (positions) => {
  //     getAddressByGeolocation(
  //       positions.coords.latitude,
  //       positions.coords.longitude
  //     );
  //   });
  // }

  return (
    menuAddress && (
      <Container>
        {signed ? (
          <ModalStyled
            isOpen={menuAddress && signed}
            onRequestClose={handleCloseModal}
            style={customStyles}
          >
            <UserAddresses />{' '}
          </ModalStyled>
        ) : (
          <ModalStyled
            isOpen={menuAddress && !signed}
            onRequestClose={handleCloseModal}
            style={customStyles}
          >
            {/* {!locationSelected && ( */}
            {!address ? <ZipCodeArea /> : <SubmitAddress />}
            {/* {locationSelected && <Location />} */}
          </ModalStyled>
        )}
      </Container>
    )
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...MainActions, ...DeliveryActions, ...AuthActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  menuAddress: state.main.menuAddress,
  address: state.delivery.address,
  signed: state.auth.signed,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuAddress);
