import React from 'react';

import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import {
  RiFileList3Line,
  RiMoneyDollarCircleLine,
  RiSearchEyeLine,
} from 'react-icons/ri';
import { BsCalendar } from 'react-icons/bs';
import { AiOutlineClockCircle } from 'react-icons/ai';

import Loading from './components/Loading';
import { formatPrice } from '../../../../util/format';

import {
  Header,
  Number,
  Date,
  Status,
  TotalPrice,
  HeaderDetail,
  Container,
  ListITems,
  ItemArea,
  Item,
  AreaValues,
  AreaDetail,
  AreaMiddle,
  OrderNumber,
  OrderDate,
  OrderDetails,
  OrderStatus,
  AreaStatus,
  Total,
  StatusPayment,
  WithOutOrders,
} from './styles';

function List({
  orders,
  loadingVisible,
  setitemsVisible,
  setTotal,
  setCurrentOrder,
  handleDetails,
}) {
  function handleItemDetails(order, total) {
    handleDetails(order.id);
    setTotal(total);
    setitemsVisible(true);
    setCurrentOrder(order);
  }
  return (
    <Container>
      <ListITems>
        <Header>
          <Number>
            <RiFileList3Line size={20} />
            Nº do pedido
          </Number>
          <Date>
            <BsCalendar size={20} />
            Data
          </Date>
          <Status>
            <AiOutlineClockCircle size={20} />
            Status
          </Status>
          <TotalPrice>
            <RiMoneyDollarCircleLine size={20} />
            Total
          </TotalPrice>
          <HeaderDetail />
        </Header>
        {loadingVisible ? (
          <Loading />
        ) : (
          <>
            {orders.length > 0 ? (
              orders.map((item) => (
                <ItemArea key={item.id}>
                  <Item>
                    <AreaValues>
                      <OrderNumber>#{item.id}</OrderNumber>
                      <OrderDate>
                        {moment(item.created_at).format('L')}
                      </OrderDate>
                      <AreaStatus>
                        <OrderStatus status={item.status.toLowerCase()}>
                          {item.status.toUpperCase()}
                        </OrderStatus>
                      </AreaStatus>
                      <Total>{formatPrice(item.value_total, 2)}</Total>
                    </AreaValues>
                    <AreaMiddle>
                      <OrderStatus status={item.status.toLowerCase()}>
                        {item.status}
                      </OrderStatus>
                    </AreaMiddle>
                    <AreaDetail>
                      <OrderDetails
                        onClick={() =>
                          handleItemDetails(item, item.value_total)
                        }
                      >
                        <RiSearchEyeLine size={20} />
                        Detalhes
                      </OrderDetails>
                    </AreaDetail>
                  </Item>
                  {item.onlie_payment_status_id && (
                    <StatusPayment
                      status={
                        // eslint-disable-next-line
                        item?.online_payment_status_name
                      }
                    >
                      {
                        // eslint-disable-next-line
                      item?.online_payment_method_name} -{' '}
                      {
                        // eslint-disable-next-line
                      item?.online_payment_status_name}
                    </StatusPayment>
                  )}
                </ItemArea>
              ))
            ) : (
              <WithOutOrders>Nenhum pedido disponível no momento</WithOutOrders>
            )}
          </>
        )}
      </ListITems>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  priceDecimals: state.store.storeInfo.settings.price_decimals,
});

export default connect(mapStateToProps)(List);
