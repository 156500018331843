import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as OrderActions from '../../../../store/modules/order/actions';

import Loading from './components/Loading';

import {
  Container,
  ModalStyled,
  LoadingArea,
  TracksList,
  TrackArea,
  Title,
  Track,
  Steps,
  Dot,
  Line,
  TrackInfo,
  ButtonArea,
  Button,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function ModalTracks({
  setModalTrackVisible,
  modalTrackVisible,
  loadingTracks,
  tracks,
  color,
}) {
  return (
    <Container>
      <ModalStyled
        isOpen={modalTrackVisible}
        onRequestClose={() => setModalTrackVisible(false)}
        style={customStyles}
      >
        {loadingTracks ? (
          <LoadingArea>
            <Loading />
          </LoadingArea>
        ) : (
          <TrackArea>
            <Title>Acompanhe seu pedido</Title>
            <TracksList>
              {tracks?.length > 0 &&
                tracks.map((track) => (
                  <Track id={track.hour}>
                    <Steps>
                      <Dot color={color} />
                      <Line color={color} />
                    </Steps>
                    <TrackInfo>
                      <strong>{track.date}</strong>
                      <span>
                        {track.hour} - {track.status}
                      </span>
                      <span>{track.place}</span>
                      {track.origin && track.destiny && (
                        <>
                          <span>De {track.origin}</span>
                          <span>Para {track.destiny}</span>
                        </>
                      )}
                    </TrackInfo>
                  </Track>
                ))}
            </TracksList>
            <ButtonArea>
              <Button onClick={() => setModalTrackVisible(false)} color={color}>
                Fechar
              </Button>
            </ButtonArea>
          </TrackArea>
        )}
      </ModalStyled>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...OrderActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  loadingTracks: state.order.loadingTracks,
  tracks: state.order.tracks,
  color: state.store.storeInfo.settings.color_primary,
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalTracks);
