import React from 'react';

import { RiInboxArchiveLine } from 'react-icons/ri';

import { formatPrice } from '../../../../util/format';

import history from '../../../../services/history';
import { getStoreSlug } from '../../../../util/StoreUtil';

import imageDefault from '../../../../assets/images/default.png';

import {
  Container,
  Continue,
  Product,
  Image,
  Info,
  ProductName,
  Options,
  Option,
  Price,
} from './styles';
import { SubTitle, Sub } from '../../styles';

function Products({ cart, priceDecimals }) {
  return (
    <Container>
      <SubTitle>
        <Sub>
          <RiInboxArchiveLine size={16} />
          <span>Produtos</span>
        </Sub>
        <Continue
          onClick={() => {
            history.push(`/${getStoreSlug()}`);
          }}
        >
          <span>Continuar comprando</span>
        </Continue>
      </SubTitle>

      {cart.length > 0 &&
        cart.map((product) => {
          return (
            <Product key={product?.id}>
              <Image
                src={
                  product.photos && product.photos.length > 0
                    ? product.photos[0].thumb_url
                    : imageDefault
                }
              />

              <Info>
                <ProductName>
                  {product.unit_description ? (
                    <span>
                      {product.amount} {product.unit_description}x{' '}
                      {product.name}
                    </span>
                  ) : (
                    <span>
                      {product.amount}x {product.name}
                    </span>
                  )}
                  {product.value_unitary > 0 && (
                    <span>
                      {formatPrice(product.value_unitary, priceDecimals)}
                    </span>
                  )}
                </ProductName>
                <span>{product.code}</span>
                {product?.options?.length > 0 && (
                  <Options>
                    {product.options.map((option) => (
                      <Option key={option?.id}>
                        <span>
                          {option.amount}x {option.name}
                        </span>
                        <span>
                          {option.option_items_price[0].promotional_price > 0
                            ? formatPrice(
                                option.option_items_price[0].promotional_price,
                                priceDecimals
                              )
                            : formatPrice(
                                option.option_items_price[0].regular_price,
                                priceDecimals
                              )}
                        </span>
                      </Option>
                    ))}

                    <Price>
                      <span>
                        {formatPrice(
                          product.total > 0
                            ? product.total
                            : product.value_total,
                          2
                        )}
                      </span>
                    </Price>
                  </Options>
                )}
              </Info>
            </Product>
          );
        })}
    </Container>
  );
}

export default Products;
