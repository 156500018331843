import React, { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FiSearch, FiUser, FiShoppingCart, FiLogIn } from 'react-icons/fi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { TiLocationOutline } from 'react-icons/ti';
import { IoIosArrowDown } from 'react-icons/io';

import { getStoreSlug } from '../../../../util/StoreUtil';
import history from '../../../../services/history';

import {
  HeaderContainer,
  ImageHeader,
  InputHeader,
  Span,
  ArrowDown,
  Address,
  Title,
  AddressInfo,
  AddressComplet,
  ProfileButton,
  Login,
  CartButton,
  CartSize,
  FocusSearch,
} from './styles';

import * as GroupsActions from '../../../../store/modules/groups/actions';
import * as MainActions from '../../../../store/modules/main/actions';
import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import { handleLoginModal } from '../../../../store/modules/auth/actions';

function Header({
  handleCart,
  handleSearch,
  headerVisible,
  handleMenuProfile,
  image,
  color,
  handleMenuAddress,
  address,
  currentAddress,
  getAddresses,
  restrictDeliveryArea,
  handleFocusSearch,
  focusSearch,
  cartVisible,
  showcase,
  discountClub,
}) {
  const [firstRender, setFirstRender] = useState(0);
  const [timeOut, setTimeOut] = useState(0);
  const [textInput, setTextInput] = useState('');
  const signed = useSelector((state) => state.auth.signed);
  const cartSize = useSelector((state) => state.cart.products.length);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current && window.innerWidth < 1050) {
      inputRef.current.focus();
    }
  }, []);

  async function handleGetAddresses() {
    await getAddresses();
  }

  useEffect(() => {
    if (signed && restrictDeliveryArea) {
      handleGetAddresses();
    }
  }, [signed]);

  useEffect(() => {
    if (firstRender > 1) {
      if (headerVisible) document.getElementById('inputHeader').focus();
    }
    setFirstRender(+1);
  }, [headerVisible]);

  function handleTimeSearch(text) {
    const { value } = text.target;
    setTextInput(value);
    clearTimeout(timeOut);

    setTimeOut(
      setTimeout(() => {
        handleSearch(value);
        handleFocusSearch(false);
      }, 900)
    );
  }

  function cleanInputText() {
    setTimeOut(
      setTimeout(() => {
        handleFocusSearch(false);
        setTextInput('');
        handleSearch(null);
      }, 100)
    );
  }

  const dispatch = useDispatch();
  function handleShowModal() {
    dispatch(handleLoginModal());
  }
  function handleReaload() {
    history.push({ pathname: `/${getStoreSlug()}`, search: '' });
    if (window.location.pathname === `/${getStoreSlug()}`) {
      setTimeout(() => {
        window.location.reload();
      });
    }
  }

  return headerVisible ? (
    <HeaderContainer HeaderVisible={headerVisible}>
      {focusSearch && <FocusSearch onClick={() => handleFocusSearch(false)} />}
      {image ? (
        <ImageHeader
          onClick={() => handleReaload()}
          // src={image}
          image={image}
        />
      ) : (
        <div style={{ display: 'flex', width: '90px', height: '90px ' }} />
      )}

      <InputHeader color={color} focusSearch={focusSearch}>
        <FiSearch size={30} />
        <input
          ref={inputRef}
          onFocus={() => handleFocusSearch(true)}
          id="inputHeader"
          value={textInput}
          onChange={(text) => handleTimeSearch(text)}
          placeholder="Buscar produto"
        />
        {textInput && (
          <AiOutlineCloseCircle onClick={() => cleanInputText()} size={30} />
        )}
      </InputHeader>
      {restrictDeliveryArea && (
        <Address
          address={address && address.name}
          onClick={() => handleMenuAddress(true)}
        >
          <Title>
            <span>Endereço</span>
          </Title>
          <AddressInfo>
            {
              // eslint-disable-next-line
            signed && currentAddress?.zip_code ? (
                <AddressComplet>
                  <span>
                    {currentAddress.name ? `${currentAddress.name} -` : ''}
                    {currentAddress.neighborhood},{currentAddress.number}
                  </span>
                </AddressComplet>
              ) : (
                <>
                  {address && address.number ? (
                    <AddressComplet>
                      <span>
                        {address.name ? <span> -</span> : ''}{' '}
                        {address.neighbourhood}, {address.number}
                      </span>
                    </AddressComplet>
                  ) : (
                    <>
                      <TiLocationOutline />
                      <Span>Insira a sua localização.</Span>
                      <ArrowDown>
                        <IoIosArrowDown size={25} />
                      </ArrowDown>
                    </>
                  )}
                </>
              )
            }
          </AddressInfo>
        </Address>
      )}

      {/* <FilterButton style={{ visibility: 'hidden' }}>
          <FiFilter size={30} color="#727C8E" />
          <label>Filtros</label>
        </FilterButton> */}

      <ProfileButton
        showcase={showcase}
        discountClub={discountClub}
        restrictDeliveryArea={restrictDeliveryArea}
        onClick={() => (signed ? handleMenuProfile() : handleShowModal())}
      >
        {signed ? (
          <>
            <FiUser size={30} />
            <label>Perfil</label>
          </>
        ) : (
          <Login>
            <FiLogIn size={30} />
            <label>Entre ou cadastre-se</label>
          </Login>
        )}
      </ProfileButton>
      {showcase || discountClub ? (
        <></>
      ) : (
        <CartButton onClick={() => handleCart(!cartVisible)}>
          <FiShoppingCart size={30} />
          <label>Carrinho</label>
          {cartSize > 0 && <CartSize color={color}>{cartSize}</CartSize>}
        </CartButton>
      )}
    </HeaderContainer>
  ) : (
    <></>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...MainActions, ...GroupsActions, ...DeliveryActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  cartVisible: state.main.cartVisible,
  signed: state.auth.signed,
  headerVisible: state.main.headerVisible,
  image: state.store.storeInfo.settings.logo_url,
  color: state.store.storeInfo.settings.color_primary,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
  showcase: state.store.storeInfo.settings.showcase,
  discountClub: state.store.storeInfo.settings.discount_club,
  address: state.delivery.address,
  currentAddress: state.delivery.currentAddress,
  focusSearch: state.main.focusSearch,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
