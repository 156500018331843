import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import { getStoreSlug } from './util/StoreUtil';

// if (process.env.NODE_ENV === 'production') {
Sentry.init({
  dsn:
    'https://947a2f3ba95745279de20dfa065b79bd@o314391.ingest.sentry.io/5444069',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeSend: (event) => {
    const local = JSON.parse(
      localStorage.getItem(`persist:i9p:${getStoreSlug()}`)
    );

    const auth = JSON.parse(local?.auth);
    const cart = JSON.parse(local?.cart);
    const delivery = JSON.parse(local?.delivery);
    const store = JSON.parse(local?.store);
    const { profile } = JSON.parse(local?.user);

    event = {
      ...event,
      contexts: {
        LocalStorage: {
          auth,
          cart: { ...cart, products: JSON.stringify(cart?.products) },
          delivery: {
            ...delivery,
            addresses: JSON.stringify(delivery?.addresses),
          },
          store: { ...store, storeInfo: JSON.stringify(store.storeInfo) },
          user: profile,
        },
      },
      tags: {
        store: getStoreSlug(),
      },
      user: { username: profile.name, id: profile.id, email: profile.email },
    };

    return event;
  },
});
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
