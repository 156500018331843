import styled from 'styled-components';
import { darken } from 'polished';

export const ResgisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  background: #fff;
  align-items: center;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 10px 10px #00000090;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 20px 30px;

  @media (max-width: 1150px) {
    display: flex;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 2;
    overflow-y: auto;
    padding: 10px;
  }
`;

export const Form = styled.form`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: visible;
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 0;

  @media (max-width: 576px) {
    padding: 10px 0;
  }
`;

export const InputLine = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    width: 48%;
  }
`;

export const NewsLetter = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;

  input {
    margin-right: 10px;
  }

  @media (max-width: 576px) {
    margin-top: 20px;
    input {
      margin-right: 20px;
    }
  }
`;

export const Contract = styled(NewsLetter)``;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  @media (max-width: 420px) {
    margin-bottom: 20px;
  }
`;

export const IconArea = styled.div`
  display: flex;
  flex: 0.8;
  justify-content: flex-start;
`;

export const Dot = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  background: #fff;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;
export const Text = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: 20px;
`;

export const RegisterButton = styled.button.attrs({ type: 'submit' })`
  width: 100%;
  height: 40px;
  border-radius: 15px;
  color: #fff;
  background: ${(props) => props.color};
  box-shadow: 0px 2px 3px #00000050;
  transition: background 0.6s, box-shadow 0.6s;
  border: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.color};
  }

  &:hover {
    background: ${(props) => props.color && darken(0.07, props.color)};
    box-shadow: 1px 4px 5px #00000050;
  }
`;
