import styled from 'styled-components';

// #region CONTAINER
export const Container = styled.div`
  flex: 1;
`;
// #endregion

// #region BODY
export const Body = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  @media (max-width: 1150px) {
    padding-top: 0;
  }
`;

export const Apresentation = styled.div`
  margin-top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;

  img:nth-child(1) {
    display: flex;
    width: 100%;
    height: 350px;
    z-index: -1;
  }
  @media (max-width: 1700px) {
    width: 100%;
    img:nth-child(1) {
      width: 100%;
    }
  }

  @media (max-width: 1150px) {
    width: 100%;
    height: 100%;
    right: 0;
    margin-top: 0;

    img:nth-child(1) {
      height: 200px;
    }
  }
`;

// #endregion

// #region TAB BOTTOM
export const Tab = styled.div``;

// #endregion
