import React from 'react';

import { connect } from 'react-redux';

import { AiOutlineLoading } from 'react-icons/ai';
import { FaTruckLoading } from 'react-icons/fa';

import { Container } from './styles';

function LoadingFreight({ color }) {
  return (
    <Container color={color}>
      <AiOutlineLoading className="loading" />
      <FaTruckLoading />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  color:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.color_primary,
});

export default connect(mapStateToProps)(LoadingFreight);
