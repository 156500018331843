import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import { AiOutlineCloseCircle, AiOutlineLoading } from 'react-icons/ai';

import * as OrderActions from '../../../../store/modules/order/actions';

import { ReactComponent as Printer } from '../../../../assets/icons/printer.svg';
import { ReactComponent as BankBill } from '../../../../assets/icons/bankbill.svg';
import { formatPrice } from '../../../../util/format';

import {
  Container,
  Loading,
  ModalStyled,
  IconClose,
  IconsMobile,
  PrintResponsive,
  HeaderComplete,
  HeaderPrint,
  Logo,
  Customer,
  IconsArea,
  ButtonArea,
  OrderHeader,
  OnlinePaymentInfo,
  TrackingCodeArea,
  TrackingCode,
  Info,
  Print,
  BankBillArea,
  Body,
  Item,
  Values,
  Options,
  Option,
  SubtotalItem,
  Tax,
  TaxText,
  TaxValue,
  Footer,
  Total,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function OrderItems({
  loadingItemsVisible,
  orderItems,
  setitemsVisible,
  itemsVisible,
  setLoadingItemsVisible,
  total,
  logo,
  priceDecimals,
  currentOrder,
  setModalTrackVisible,
  getTrackingOrder,
}) {
  useEffect(() => {
    setLoadingItemsVisible(false);
  }, []);
  function handleCloseModal(event) {
    if (event.currentTarget === event.target) {
      setitemsVisible(false);
    }
  }

  function handlePrint() {
    window.print();
  }

  function handleValue(item) {
    const totalItem = item.order_item_options.reduce((totalOptions, option) => {
      totalOptions +=
        parseFloat(option.quantity) * parseFloat(option.value_unitary);

      return totalOptions;
    }, 0);

    const subtotalItem = totalItem + parseFloat(item.value_unitary);

    return subtotalItem;
  }

  function handleTrackinCode() {
    // eslint-disable-next-line
    getTrackingOrder(currentOrder?.tracking_code);
    setModalTrackVisible(true);
  }

  return (
    <Container onClick={(event) => handleCloseModal(event)}>
      <ModalStyled
        isOpen={itemsVisible}
        onRequestClose={() => handleCloseModal(false)}
        style={customStyles}
      >
        {loadingItemsVisible ? (
          <Loading>
            <AiOutlineLoading size={100} />
          </Loading>
        ) : (
          <>
            <IconClose>
              <AiOutlineCloseCircle
                size={20}
                onClick={() => setitemsVisible(false)}
              />
              <IconsMobile>
                <PrintResponsive>
                  <Printer onClick={() => handlePrint()} />
                </PrintResponsive>

                {currentOrder.online_payment_url && (
                  <PrintResponsive>
                    <BankBill href="www.google.com" />
                  </PrintResponsive>
                )}
              </IconsMobile>
            </IconClose>
            <HeaderComplete key={currentOrder.id}>
              <HeaderPrint>
                <Logo image={logo} />
                <Info>
                  <OrderHeader>
                    <span>
                      <strong>Pedido: </strong> {currentOrder.id}
                    </span>
                    <span>
                      <strong>Data: </strong>{' '}
                      {moment(currentOrder.createdAt).format('L')}
                    </span>
                    <span>
                      <strong>Status: </strong>{' '}
                      {currentOrder.status.toUpperCase()}
                    </span>
                    {
                      // eslint-disable-next-line
                    currentOrder?.onlie_payment_status_id && (
                        <OnlinePaymentInfo>
                          <div>
                            <strong>Forma de pagamento: </strong>{' '}
                            <span>
                              {
                                // eslint-disable-next-line
                          currentOrder?.online_payment_method_name?.toUpperCase()}</span>
                          </div>
                          <div>
                            <strong>Status do pagamento: </strong>{' '}
                            <span>
                              {
                                // eslint-disable-next-line
                          currentOrder?.online_payment_status_name?.toUpperCase()}</span>
                          </div>
                        </OnlinePaymentInfo>
                      )
                    }
                    {
                      // eslint-disable-next-line
                    currentOrder?.tracking_code && (
                        <TrackingCodeArea>
                          <strong>Código de rastreio: </strong>

                          <TrackingCode
                            // eslint-disable-next-line
                            onClick={() => handleTrackinCode(currentOrder?.tracking_code)
                            }
                          >
                            {
                              // eslint-disable-next-line
                              currentOrder?.tracking_code}
                          </TrackingCode>
                        </TrackingCodeArea>
                      )
                    }
                    <span>
                      <strong>Total: </strong>
                      {formatPrice(currentOrder.value_total, 2)}
                    </span>
                    <strong>
                      {currentOrder.delivery_payment_method}{' '}
                      {currentOrder.delivery_payment_card &&
                        `- ${currentOrder.delivery_payment_card}`}
                    </strong>
                  </OrderHeader>

                  <Customer>
                    <span>
                      <strong>Nome: </strong> {currentOrder.customer_name}
                    </span>
                    {currentOrder.customer_phone && (
                      <span>
                        <strong>Telefone: </strong>{' '}
                        {currentOrder.customer_phone}
                      </span>
                    )}
                    <span>
                      <strong>CPF/CNPJ: </strong>{' '}
                      {currentOrder.customer_document}
                    </span>
                    <span>
                      <strong>E-mail: </strong> {currentOrder.customer_email}
                    </span>
                  </Customer>
                </Info>
                <IconsArea>
                  <ButtonArea onClick={() => handlePrint()}>
                    <Print>
                      <Printer />
                    </Print>
                    <span>Imprimir pedido</span>
                  </ButtonArea>
                  {currentOrder.online_payment_url && (
                    <ButtonArea
                      target="_blank"
                      rel="noopener noreferrer"
                      href={currentOrder.online_payment_url}
                    >
                      <BankBillArea>
                        <BankBill />
                      </BankBillArea>
                      <span>Imprimir boleto</span>
                    </ButtonArea>
                  )}
                </IconsArea>
              </HeaderPrint>
            </HeaderComplete>

            <Body>
              {orderItems.map((order) =>
                order.products.map((item) => (
                  <Item>
                    <Values key={item.id}>
                      {item.product_unit_description ? (
                        <span>
                          {item.amount}x {item.product_unit_description}x{' '}
                          {item.product_name}
                        </span>
                      ) : (
                        <span>
                          {item.amount}x {item.product_name}
                        </span>
                      )}

                      {parseFloat(item.value_unitary) !== 0 && (
                        <span>
                          {formatPrice(
                            parseFloat(item.value_unitary) *
                              parseFloat(item.amount),
                            2
                          )}
                        </span>
                      )}
                    </Values>
                    {
                      // eslint-disable-next-line
                    item?.order_item_options?.length > 0 && (
                        <>
                          <Options>
                            {item.order_item_options.map((option) => (
                              <Option>
                                <span>
                                  {option.quantity}x {option.name}
                                </span>
                                <span>
                                  {formatPrice(option.value_unitary, 2)}
                                </span>
                              </Option>
                            ))}
                          </Options>
                          <SubtotalItem>
                            {formatPrice(handleValue(item), priceDecimals)}
                          </SubtotalItem>
                        </>
                      )
                    }
                  </Item>
                ))
              )}
              {/* eslint-disable-next-line */}
              {parseFloat(currentOrder?.value_delivery_tax) > 0 && (
                <Tax>
                  <TaxText>
                    <span>taxa de entrega</span>
                  </TaxText>
                  <TaxValue>
                    <span>
                      {formatPrice(currentOrder.value_delivery_tax, 2)}
                    </span>
                  </TaxValue>
                </Tax>
              )}
            </Body>
            <Footer>
              <Total>Total</Total>
              <Total>{formatPrice(total, 2)}</Total>
            </Footer>
          </>
        )}
      </ModalStyled>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...OrderActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  logo: state.store.storeInfo.settings.logo_url,
  priceDecimals: state.store.storeInfo.settings.price_decimals,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderItems);
