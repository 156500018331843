import { takeLatest, all, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import api from '../../../services/api';

import {
  setGroups,
  setStatePagination,
  setNavigation,
  cleanNavigation,
  setCurrentSearch,
  handleLoadingProducts,
  handleLoadingSeeMore,
} from './actions';

import { handleLoading } from '../main/actions';

export function* getGroups(payload) {
  const {
    currentParentId,
    currentSearch,
    currentCategoryId,
    nextPage,
  } = payload;

  const response = yield api.getProducts(
    currentParentId,
    currentSearch,
    currentCategoryId,
    nextPage
  );

  yield response && put(setGroups(response.data));
  return yield put(handleLoading(false));
}

export function* handlePagination(payload) {
  const { currentParentId, currentCategoryId, nextPage, child } = payload;

  const oldCurrentParentId = yield select(
    (state) => state.groups.oldCurrentParentId
  );

  const oldCurrentCategoryId = yield select(
    (state) => state.groups.oldCurrentCategoryId
  );
  const oldNextPage = yield select((state) => state.groups.oldNextPage);
  const currentSearch = yield select((state) => state.groups.oldCurrentSearch);

  if (
    oldCurrentParentId !== currentParentId ||
    oldCurrentCategoryId !== currentCategoryId ||
    oldNextPage !== nextPage
  ) {
    if (child) {
      yield put(handleLoadingProducts(true));
    } else {
      yield put(handleLoadingSeeMore(true));

      yield put({
        type: 'GET_GROUPS',
        currentParentId,
        currentSearch,
        currentCategoryId,
        nextPage,
      });
    }
    yield put(setStatePagination(currentParentId, currentCategoryId, nextPage));
    // yield put({
    //   type: 'GET_GROUPS',
    //   currentParentId,
    //   currentSearch: oldCurrentSearch,
    //   currentCategoryId,
    //   nextPage,
    // });
  }
}

export function* handleNavigation(payload) {
  const { category } = payload;

  yield !category && put(cleanNavigation());

  if (category && category.childrenCount <= 0) {
    yield put({
      type: 'HANDLE_PAGINATION',
      currentParentId: category.parent_id,
      currentCategoryId: category.id,
      nextPage: 2,
      child: false,
    });
    yield put(setNavigation(category));
  } else {
    yield put({
      type: 'HANDLE_PAGINATION',
      currentParentId: category ? category.id : null,
      currentCategoryId: null,
      nextPage: 1,
      child: true,
    });
    yield put(setNavigation(category));
  }
}

export function* handleSearch(payload) {
  const { currentSearch } = payload;
  yield put(cleanNavigation());
  yield put(setStatePagination(null, null, 1));
  yield put(setCurrentSearch(currentSearch));
  yield put({
    type: 'GET_GROUPS',
    currentParentId: null,
    currentSearch,
    currentCategoryId: null,
    nextPage: 1,
  });
}

export function* setContactRequest({ payload }) {
  const response = yield api.setContactRequest(payload.contact);

  if (
    response &&
    response.response &&
    response.response.status &&
    response.response.status !== 200
  ) {
    return toast.error(
      'Erro ao enviar pedido de contato, tente novamente mais tarde.'
    );
  }

  return toast.success('Pedido de contato enviado com sucesso!');
}

export default all([
  takeLatest('GET_GROUPS', getGroups),
  takeLatest('HANDLE_PAGINATION', handlePagination),
  takeLatest('HANDLE_NAVIGATION', handleNavigation),
  takeLatest('HANDLE_SEARCH', handleSearch),
  takeLatest('SET_CONTACT_REQUEST', setContactRequest),
]);
