function unregister() {
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
}

function unregisterServicework() {
  return unregister();
}

export default unregisterServicework;
