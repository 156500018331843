import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from 'react-toastify';

import history from '../../services/history';

import * as GroupsActions from '../../store/modules/groups/actions';
import * as MainActions from '../../store/modules/main/actions';
import * as AuthActions from '../../store/modules/auth/actions';

import Metas from '../components/Metas';

import { getStoreSlug } from '../../util/StoreUtil';

import {
  ContainerRecovery,
  Header,
  Image,
  Body,
  Title,
  Password,
  NewPassword,
  Input,
  ButtonArea,
  Button,
} from './styles';

function RecoveryPassword({
  image,
  headerVisible,
  color,
  location,
  changePassword,
}) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  function handlePassword() {
    if (!password || !confirmPassword) {
      return toast.error('Campos obrigatórios.');
    }

    if (password !== confirmPassword) {
      setPassword('');
      setConfirmPassword('');
      return toast.error('A senha e a confirmação de senha devem ser iguais.');
    }
    if (password.length < 6) {
      return toast.error('A senha deve conter pelo menos 6 caracteres.');
    }

    const token = new URLSearchParams(location.search).get('token');
    return changePassword(password, token);
  }

  return (
    <ContainerRecovery>
      <Metas />
      <Header>
        {image ? (
          <Image
            style={{ marginLeft: '20px' }}
            onClick={() => {
              history.push(`/${getStoreSlug()}`);
            }}
            src={image}
          />
        ) : (
          <div style={{ display: 'flex', width: '10%' }} />
        )}
      </Header>
      <Body headerVisible={headerVisible}>
        <Title>
          <span>Recuperar senha</span>
        </Title>
        <Password>
          <span>Nova senha</span>
          <Input
            ref={inputRef}
            value={password}
            onChange={(text) => setPassword(text.target.value)}
          />
        </Password>
        <NewPassword>
          <span>Confirme sua nova senha</span>
          <Input
            value={confirmPassword}
            onChange={(text) => setConfirmPassword(text.target.value)}
          />
        </NewPassword>
        <ButtonArea>
          <Button onClick={() => handlePassword()} color={color}>
            Alterar
          </Button>
        </ButtonArea>
      </Body>
    </ContainerRecovery>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...MainActions, ...GroupsActions, ...AuthActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  image: state.store.storeInfo.settings.logo_url,
  headerVisible: state.main.headerVisible,
  color: state.store.storeInfo.settings.color_primary,
});
export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
