import isMobile from '../util/isMobile';

function sendWhatsMessage(mode, phone, message) {
  const whatsLink = function () {
    if (isMobile) {
      let target = `whatsapp://send?`;
      if (!!phone && phone !== '') {
        target += `phone=${encodeURIComponent(phone)}&`;
      }
      if (!!message && message !== '') {
        target += `text=${encodeURIComponent(message)}`;
      }
      return target;
    }
    let target = `https://api.whatsapp.com/send?`;
    if (!!phone && phone !== '') {
      target += `phone=${encodeURIComponent(phone)}&`;
    }
    if (!!message && message !== '') {
      target += `text=${encodeURIComponent(message)}`;
    }
    return target;
  };

  const openLink = function () {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = whatsLink(); // o link URL do api.whatsapp.com
    a.click();
  };

  const openPopUp = function () {
    const h = 650;
    const w = 800;
    const l = Math.floor(((window.innerHeight || 1024) - w) / 2);
    const t = Math.floor(((window.innerWidth || 700) - h) / 2);
    // open popup
    const options = `height=${h},width=${w},top=${t},left=${l},location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=0`;
    // abrir
    const popup = window.open(whatsLink(), 'self', options);
    // forçar o focus()
    if (popup) {
      popup.focus();
    }
  };

  switch (mode) {
    case 'link':
      openLink();
      break;
    case 'popup':
      openPopUp();
      break;

    default:
  }
}

export default sendWhatsMessage;
