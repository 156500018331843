import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

// #region PRODUCTS
export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  padding: 0 20px 20px 20px;
  transition: width 0.6s;

  @media (max-width: 1150px) {
    width: 100%;
  }
  @media (max-width: 555px) {
    padding: 10px;
  }

  .search {
    font-size: 18px;
    color: ${(props) => props.theme.colors.garyDark};

    strong {
      color: #000;
    }
  }
`;
export const Products = styled.div`
  margin-top: 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 15px 15px #80828b26;

  @media (max-width: 555px) {
    margin-top: 10px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow-y: hidden;

  @media (max-width: 555px) {
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    flex-flow: row;
  }

  @media (max-width: 420px) {
    font-size: 20px;
  }
`;

export const SectionName = styled.div`
  font-weight: bold;
  width: 98%;
  margin-left: 10px;
  padding: 10px 10px 0 10px;
  font-size: 24px;
  display: flex;
  align-items: center;
`;

export const ButtonSeeMore = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #eee;
  margin-left: 10px;
  transition: background 0.6s, color 0.6s, border 0.6s, box-shadow 0.6s;
  cursor: pointer;
  box-shadow: 0px 1px 3px #00000050;
  font-size: 16px;
  display: none;

  &:hover {
    background: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    color: #fff;
    box-shadow: 0px 3px 6px #00000050;
  }

  @media (max-width: 555px) {
    display: flex;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-basis: ${(props) => (props.cartVisible ? '25%' : '20%')};
  flex: auto;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  /* min-width: 300px; */
  width: 300px;

  @media (max-width: 1333px) {
    /* margin-right: ${(props) => (props.cartVisible ? '80px' : '50px')}; */
  flex-basis: ${(props) => (props.cartVisible ? '33.33%' : '25%')};
  }
  @media (max-width: 1150px){

    flex-basis: 25%;
  }
  @media (max-width: 1073px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 815px) {
    flex-basis: 50%;
  }
  @media (max-width: 555px) {
    flex-basis: 100%;
  }
  @media (max-width: 420px) {
    width: 200px;
  }
`;

export const Product = styled.div`
  background: #fff;
  padding: 10px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 10px;
  z-index: 1;
  transition: box-shadow 0.6s, margin-right 2s, border-color 0.5s;
  width: 100%;
  cursor: pointer;

  @media (min-width: 555px) {
    &:hover {
      box-shadow: 10px 10px 10px #80828b99;
      border-color: ${(props) => props.color};
    }
  }

  @media (max-width: 420px) {
    padding: 10px;
  }
`;

export const BadgeArea = styled.div`
  display: flex;
  position: absolute;
  width: 85%;
  justify-content: flex-end;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const Badge = styled.div`
  background: ${(props) => props.color};
  font-size: 12px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all 0.6s;
  transform: rotate(-45deg);
  box-shadow: 0px 2px 4px #00000050;
  font-weight: bold;

  &:hover {
    transform: rotate(-55deg);
    width: 50px;
    height: 50px;
    font-size: 16px;
    box-shadow: 2px 4px 6px #00000050;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: -1;
    transform: rotate(30deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    z-index: -1;
    transform: rotate(30deg);
  }

  &:after {
    transform: rotate(-30deg);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: inherit;
    transform: rotate(45deg);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      z-index: -1;
      transform: rotate(30deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      z-index: -1;
      transform: rotate(30deg);
    }
    &:after {
      transform: rotate(-30deg);
    }
  }
`;

export const ProductBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ProductImage = styled.div`
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto !important;
    height: auto !important;
    background-repeat: no-repeat !important;
    position: relative !important;
    align-items: center !important;
  }

  @media (max-width: 420px) {
    img {
      width: 150px !important;
    }
  }
`;

export const ProductName = styled.label`
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;

  color: ${(props) => props.theme.colors.garyDark};
  @media (max-width: 420px) {
    margin-top: 0;
    font-size: 14px;
  }
`;

export const ProductCode = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => props.theme.colors.garyDark};
  @media (max-width: 420px) {
    margin-top: 0;
    font-size: 12px;
  }
`;

export const ProductDesUnit = styled.label`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.garyDark};
  font-size: 16px;

  @media (max-width: 420px) {
    font-size: 12px;
  }
`;

export const ProductDescription = styled.label`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.garyDark};
  font-size: 16px;

  @media (max-width: 420px) {
    margin-top: 5px;
    font-size: 14px;
  }
`;

export const ProductFooter = styled.div`
  flex-direction: row;
  display: flex;
  height: 100%;
  align-items: flex-end;
  flex: 1;
`;

export const ProductPrices = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
`;

export const Prices = styled.div`
  flex-direction: column;
  div {
    display: flex;
  }
`;

export const Old = styled.label`
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const Price = styled.label`
  text-decoration: line-through;
  color: red;
  font-weight: bold;
  font-size: 20px;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const NewPrice = styled.label`
  display: flex;
  font-weight: bold;
  font-size: 30px;

  strong {
    display: ${(props) => (props.visible ? 'flex' : 'none')};
  }

  @media (max-width: 420px) {
    font-size: 20px;
  }
`;

export const Economy = styled.div`
  display: flex;
  font-size: 14px;
  padding: 10px 0;
  color: ${(props) => props.theme.colors.grayLight};
`;

export const PromotionExpiration = styled.div`
  display: flex;

  font-size: 10px;
  padding: 5px 0;
  color: ${(props) => props.theme.colors.redDark};
`;

export const ButtonAdd = styled.button`
  margin-top: 10px;
  background: ${(props) => props.color};
  height: 50px;
  width: 50px;
  border: 0;
  border-radius: 25px;
  font-size: 25px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  color: #fff;
  box-shadow: 0px 3px 6px ${(props) => props.color};
  transition: background 0.6s;
  transition: color 0.3s;

  &:hover {
    background: ${(props) => darken(0.1, props.color)};
  }

  @media (max-width: 420px) {
    height: 36px;
    width: 36px;
  }
`;

const expand = (color) => keyframes`
0% {
  width: 50px;
  background: ${color}
}
100% {
  width: 100%;
  background: ${color}
}`;

export const CompleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.color};
  box-shadow: 0px 3px 6px ${(props) => props.color};
  border-radius: 25px;
  height: 50px;
  /* width: 200px; */
  width: 100%;
  padding: 10px;
  animation: ${(props) => expand(props.color)} 1s;
  align-self: center;
  margin-top: 10px;

  @media (max-width: 420px) {
    height: 30px;
    padding: 5px;
  }
`;

export const Minus = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.color};
    width: 20px;
    height: 20px;
  }

  @media (max-width: 420px) {
    height: 20px;
    width: 20px;

    svg {
      height: 15px;
      width: 15px;
    }
  }
`;

export const Quantity = styled.div`
  font-weight: bold;
  color: #fff;

  @media (max-width: 420px) {
    font-size: 18px;
  }
`;

export const Add = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #fff;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.color};
    width: 20px;
    height: 20px;
  }

  @media (max-width: 420px) {
    height: 20px;
    width: 20px;

    svg {
      height: 15px;
      width: 15px;
    }
  }
`;

export const SeeMore = styled.div`
  display: none;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 10px;
  width: 600px !important;
  text-align: center;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.garyDark};

  strong {
    color: #000;
    font-size: 24px;
  }

  .buttonSeeMore {
    margin-top: 20px;
  }

  @media (max-width: 555px) {
    display: flex;
  }
`;

export const TextSeeMore = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Pagination = styled.div`
  width: 100%;
  transition: width 1.5s;
  text-align: end;
  color: ${(props) => props.theme.colors.garyDark};
  font-size: 24px;
  font-weight: bold;
  padding: 10px;

  span {
    cursor: pointer;
    margin-right: 40px;
  }

  @media (max-width: 1150px) {
    width: 100%;
  }
  @media (max-width: 555px) {
    display: none;
  }
  @media (max-width: 420px) {
    padding-right: 10px;
  }
`;

export const SignUpArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  strong {
    font-size: 30px;
  }

  @media (max-width: 420px) {
    strong {
      font-size: 24px;
    }
  }
`;

export const SignUp = styled.button`
  background: ${(props) => props.color};
  box-shadow: 0px 2px 4px #00000050;
  padding: 2px 10px;
  margin-left: 10px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 28px;
  transition: all 0.6s;
  text-align: center;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }

  @media (max-width: 420px) {
    font-size: 16px;
    width: 72%;
  }
`;

export const Unavailable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
  font-weight: bold;
  margin-top: 20px;
  padding: 5px 0;
`;

export const CustomizeArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Customize = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 10px;
  flex-direction: column;

  strong {
    color: #000;
    font-size: 30px;
  }
`;

export const Disable = styled.div`
  pointer-events: ${(props) => (!props.disable ? 'none' : 'all')};

  opacity: ${(props) => (!props.disable ? '0.5' : 1)};
  background: ${(props) => (!props.disable ? '#fff' : 'transparent')};
`;

export const Consult = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 10px;

  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSeeMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    animation: ${rotate} 2s infinite;
  }
`;

export const LoadingProducts = styled.div`
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  background: #f2f2f2;

  svg {
    animation: ${rotate} 2s infinite;
  }

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const WithOutProducts = styled.div`
  height: 100%;
  width: 100%;
  padding: 100px;
  display: ${(props) => (props.firstRender ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;
export const Cart = styled.div`
  height: 200px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.garyDark};
  font-size: 20px;
  padding: 50px;

  strong {
    color: #e73b17;
  }

  span {
    margin-bottom: 20px;
  }

  ul {
    margin-left: 20px;
  }

  .question {
    font-weight: bold;
  }

  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 500px) {
    div {
      display: none;
    }
  }
`;

export const Contact = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0 0;
`;

export const Call = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: ${(props) => props.color};
  transition: background 0.6s, box-shadow 0.6s;
  color: #fff;
  box-shadow: 0px 2px 3px #00000050;

  &:hover {
    background: ${(props) => darken(0.1, props.color)};
    box-shadow: 2px 5px 6px #00000050;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;
// #endregion
