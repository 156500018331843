import styled, { keyframes } from 'styled-components';

// #region HEADER
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #fff;
  align-content: center;
  height: 90px;
  width: 100%;
  position: fixed;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 5;

  label {
    cursor: pointer;
  }

  img {
    width: 150px;
    cursor: pointer;
  }

  @media (max-width: 1150px) {
    display: ${(props) => (props.HeaderVisible ? 'flex' : 'none')};
    align-items: center;
    z-index: 2;

    div {
      position: absolute;
      width: 100%;
    }

    input {
      width: 80%;
    }

    img {
      display: none;
    }

    svg {
      display: none;
    }

    label {
      display: none;
    }
  }

  @media (max-width: 555px) {
    height: 50px;
  }
`;

export const ImageHeader = styled.div`
  background-image: ${(props) => ` url(${props.image})`};
  width: 10%;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;

  @media (max-width: 1150px) {
    display: none;
  }
`;

export const InputHeader = styled.div`
  align-items: center;
  align-self: center;
  height: 50px;
  width: 50%;
  border-radius: 15px;
  background: #f8f8f8;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  z-index: 3;
  border: ${(props) => props.focusSearch && `1px solid ${props.color}`};
  color: ${(props) => props.theme.colors.grayLight};

  svg {
    margin-left: 2%;
    margin-right: 2%;
    cursor: pointer;
  }

  input {
    font-size: 20px;
    background: #f8f8f8;
    border: 0;
    height: 25px;
    width: 90%;
  }

  @media (max-width: 1150px) {
    width: 90% !important;
    svg {
      display: flex;
    }

    input {
      width: 100%;
    }
  }

  @media (max-width: 555px) {
    height: 40px;
  }
`;

const animate = keyframes`
  0% {
    background: #fff;
  }
  50% {
    background: #E8F5E9;
  }
  100% {
    background: #fff;
  }
`;

export const Address = styled.div`
  margin-left: 20px;
  height: 50px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 20px;
  transition: box-shadow 0.6s, height 0.6s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #6e7989;
  background: #fff;
  animation: ${(props) => props.address && animate} 1.2s;

  &:hover {
    box-shadow: 0px 6px 9px #00000029;
  }

  @media (max-width: 1150px) {
    display: none;
  }
`;

export const Submit = styled.div`
  height: 40%;
  font-size: 16px;
  padding: 5px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

export const Title = styled.div`
  font-size: 12px;
  padding: 5px;
  font-weight: bold;
  height: 20%;
`;
export const AddressInfo = styled.div`
  height: 40%;
  font-size: 16px;
  padding: 5px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-top: 5px;
  transition: color 0.6s;

  &:hover {
    color: #424242;
  }
`;

export const AddressComplet = styled.div``;

export const Span = styled.span`
  margin-left: 10px;
`;

export const ArrowDown = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ProfileButton = styled.button`
  align-self: center;
  background: transparent;
  border: 0;
  color: #6e7989;
  margin-left: ${(props) => {
    if (props.restrictDeliveryArea) {
      return '20px';
    }
    if (props.showcase) {
      return '30%';
    }
    if (props.discountClub) {
      return '30%';
    }
    return '20%';
  }};
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
  }
`;

export const Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80px;
`;

export const CartButton = styled.button`
  align-self: center;
  background: transparent;
  border: 0;
  margin-left: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6e7989;
  label {
    font-weight: bold;
  }
`;

export const CartSize = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  position: absolute;
  margin-left: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  bottom: 60px;
  color: #fff;
  background: ${(props) => props.color};

  @media (max-width: 1150px) {
    display: none;
  }
`;

export const FocusSearch = styled.div`
  width: 100%;
  height: 200vh;
  background: #33333350;
  z-index: 2;
  position: absolute;
`;
// #endregion
