import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from './pages/Main';
import WithOutStore from './pages/Error/WithOutStore';
import Error400 from './pages/Error/400';
import Error401 from './pages/Error/401';
import Error403 from './pages/Error/403';
import Error404 from './pages/Error/404';
import Error500 from './pages/Error/500';
import Orders from './pages/Orders';
import Confirmation from './pages/CheckOut';
import RecoverPassword from './pages/RecoveryPassword';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={WithOutStore} />
      <Route exact path="/:store" component={Main} />
      <Route exact path="/:store/checkout" component={Confirmation} />
      <Route exact path="/:store/pedidos" component={Orders} />
      <Route exact path="/:store/recuperar-senha" component={RecoverPassword} />
      <Route exact path="/:store/erro/400" component={Error400} />
      <Route exact path="/:store/erro/401" component={Error401} />
      <Route exact path="/:store/erro/403" component={Error403} />
      <Route exact path="/:store/erro/404" component={Error404} />
      <Route exact path="/:store/erro/500" component={Error500} />
      <Route component={Error404} />
    </Switch>
  );
}
