import produce from 'immer';

const INITIAL_STATE = {
  groups: [],
  oldCurrentParentId: null,
  oldCurrentSearch: null,
  oldCurrentCategoryId: null,
  oldNextPage: 1,
  navigation: [],
  loadingProducts: false,
  loadingSeeMore: false,
  categoryRef: null,
  productDetail: false,
  currentProduct: {},
  productToContact: {},
};

export default function groups(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_GROUPS': {
        if (state.oldNextPage > 1 && action.payload.length > 0) {
          const index = draft.groups.findIndex(
            (c) => c.id === action.payload[0].id
          );
          draft.groups[index].nextPage = action.payload[0].nextPage;
          draft.groups[index].products = [
            ...state.groups[index].products,
            ...action.payload[0].products,
          ];
        }
        if (state.oldNextPage === 1) {
          draft.groups = action.payload;
        }

        if (action.payload.length <= 0) {
          const index = draft.groups.findIndex(
            (c) => c.id === state.oldCurrentCategoryId
          );
          if (index < 0) break;
          draft.loadingSeeMore = false;
          draft.groups[index].nextPage = 0;
        }

        break;
      }

      case 'SET_STATE_PAGINATION': {
        draft.oldCurrentParentId = action.currentParentId;
        draft.oldCurrentCategoryId = action.currentCategoryId;
        draft.oldNextPage = action.nextPage;
        break;
      }

      case 'SET_CURRENT_SEARCH': {
        draft.oldCurrentSearch = action.currentSearch;
        break;
      }

      case 'SET_NAVIGATION': {
        if (action.category) {
          const navigationIndex = state.navigation.findIndex(
            (n) => n.id === action.category.id
          );

          if (navigationIndex >= 0) {
            draft.navigation.splice(
              navigationIndex + 1,
              state.navigation.length
            );
            break;
          }
          draft.navigation = action.category
            ? [...state.navigation, action.category]
            : [];
        }

        break;
      }

      case 'CLEAN_NAVIGATION': {
        draft.navigation = [];
        break;
      }

      case 'HANDLE_LOADING_PRODUCTS': {
        draft.loadingProducts = action.state;
        break;
      }

      case 'HANDLE_LOADING_SEE_MORE': {
        draft.loadingSeeMore = action.state;
        break;
      }
      case 'HANDLE_PRODUCT_DETAIL': {
        draft.productDetail = action.state;
        break;
      }
      case 'SET_CURRENT_PRODUCT': {
        draft.currentProduct = action.product;
        break;
      }
      case 'SET_PRODUCT_TO_CONTACT': {
        draft.productToContact = action.payload.product;
        break;
      }

      case 'SEND_TO_CART': {
        draft.productDetail = false;
        break;
      }
      case 'SEND_PRODUCT_EDITED_TO_CART': {
        draft.productDetail = false;
        break;
      }

      case 'CLEAN_CART': {
        draft.productDetail = false;
        break;
      }

      default:
    }
  });
}
