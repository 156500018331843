import React, { useState, useEffect, useRef } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';

import { AiOutlineArrowRight } from 'react-icons/ai';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import history from '../../../../services/history';

import * as GroupsActions from '../../../../store/modules/groups/actions';

import {
  CategoryContainer,
  CategoryArea,
  CurrentChip,
  Chip,
  Categories,
} from './styles';

function Category({
  color,
  cartVisible,
  handleNavigation,
  categories,
  navigation,
  headerVisible,
  showStoreInfo,
}) {
  const [visibleCategory, setVisibleCategory] = useState(true);
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);
  const categoryRef = useRef(null);

  useEffect(() => {
    const array = categories.filter((group) => {
      return group.products.length > 0;
    });
    setCategoriesWithProducts(array);

    if (array.length > 0) {
      array[0].ancestors.map((ancestor) => {
        return handleNavigation(ancestor);
      });
    }
  }, [categories]);

  async function handleNavi(product) {
    if (product && product.childrenCount && product.childrenCount <= 0) {
      const offset = 150;
      window.scrollTo({
        behavior: 'smooth',
        top: document.getElementById(product.id).offsetTop - offset,
      });
    } else {
      setVisibleCategory(true);
      await handleNavigation(product || null);
    }

    if (product && product.childrenCount > 0) {
      history.push({
        pathname: window.location.pathname,
        search:
          product && product.id
            ? `?${new URLSearchParams({ categoryId: product.id }).toString()}`
            : '',
      });
    }
  }

  function handleAllCategories() {
    handleNavi(null);
    history.push({
      pathname: window.location.pathname,
      search: '',
    });
  }

  return (
    <CategoryContainer
      ref={categoryRef}
      id="category"
      cartVisible={cartVisible}
      showStoreInfo={showStoreInfo}
    >
      {navigation.length > 0 && (
        <CategoryArea headerVisible={headerVisible} cartVisible={cartVisible}>
          <CurrentChip color={color} onClick={() => handleAllCategories()}>
            Todas
          </CurrentChip>
          {navigation.map((category) => {
            return (
              <>
                <div id={category.id}>
                  <AiOutlineArrowRight />
                </div>
                <CurrentChip
                  color={color}
                  onClick={() => handleNavi(category)}
                  key={category.id}
                >
                  {category.name.toLowerCase()}
                </CurrentChip>
              </>
            );
          })}
        </CategoryArea>
      )}
      {visibleCategory && categoriesWithProducts.length > 1 && (
        <>
          <Categories cartVisible={cartVisible}>
            <ScrollContainer
              className="scroll-container"
              vertical
              hideScrollbars={false}
            >
              {categoriesWithProducts.map((category) => {
                return (
                  <Chip
                    key={category.id}
                    onClick={() => handleNavi(category)}
                    color={color}
                  >
                    {category.name}
                  </Chip>
                );
              })}
            </ScrollContainer>
          </Categories>
        </>
      )}
    </CategoryContainer>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(GroupsActions, dispatch);

const mapStateToProps = (state) => ({
  cartVisible: state.main.cartVisible,
  color: state.store.storeInfo.settings.color_primary,
  showStoreInfo: state.store.storeInfo.settings.show_store_info_header,
  categories: state.groups.groups,
  navigation: state.groups.navigation,
  oldCurrentParentId: state.groups.oldCurrentParentId,
  oldCurrentCategoryId: state.groups.oldCurrentCategoryId,
  headerVisible: state.main.headerVisible,
  categoryRef: state.groups.categoryRef,
});
export default connect(mapStateToProps, mapDispatchToProps)(Category);
