import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import logoI9p from '../../../../../../assets/images/I9P-01.svg';

import {
  Container,
  Company,
  By,
  I9p,
  Logo,
  Copyright,
  Terms,
  Term,
  Privacity,
} from './styles';

function FooterCompany({ cartVisible }) {
  const [Year, setYear] = useState(null);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);
  return (
    <Container cartVisible={cartVisible}>
      <Company>
        <a href="https://i9p.com.br" target="_blank" rel="noopener noreferrer">
          <By>
            <span>Sistema Desenvolvido por</span>
          </By>
          <I9p>
            <Logo src={logoI9p} />
            <Copyright>
              <span>
                © Copyright {Year} - I9P Sistemas de pedidos e delivery
              </span>
              <span>
                CNPJ 36.040.124/0001-48 / Avenida Afonso Vergueiro, nº 2900,
                Vila Augusta, Sorocaba/SP - CEP 18040-000.
              </span>
            </Copyright>
          </I9p>
        </a>
      </Company>
      <Terms>
        <Term>
          <span>Termos e condição de uso</span>
        </Term>
        <Privacity>
          <span>Politicas de privacidade</span>
        </Privacity>
      </Terms>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  cartVisible: state.main.cartVisible,
});
export default connect(mapStateToProps)(FooterCompany);
