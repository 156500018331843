import React, { useEffect, useState, createRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AiOutlineCreditCard } from 'react-icons/ai';

import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import DefaultCard from '../../../../assets/icons/cards/DefaultCard.png';
import Visa from '../../../../assets/icons/cards/Visa.png';
import { ReactComponent as VisaSvg } from '../../../../assets/icons/cards/Visa.svg';
import Mastercard from '../../../../assets/icons/cards/Mastercard.png';
import { ReactComponent as MastercardSvg } from '../../../../assets/icons/cards/Mastercard.svg';
import Maestro from '../../../../assets/icons/cards/Maestro.png';
import Elo from '../../../../assets/icons/cards/Elo.png';
import { ReactComponent as EloSvg } from '../../../../assets/icons/cards/Elo.svg';
import Amex from '../../../../assets/icons/cards/Amex.png';
import { ReactComponent as AmexSvg } from '../../../../assets/icons/cards/Amex.svg';
import DinersClub from '../../../../assets/icons/cards/DinersClub.png';
import { ReactComponent as DinersClubSvg } from '../../../../assets/icons/cards/DinersClub.svg';
import Discover from '../../../../assets/icons/cards/Discover.png';
import { ReactComponent as DiscoverSvg } from '../../../../assets/icons/cards/Discover.svg';
import Hipercard from '../../../../assets/icons/cards/Hipercard.png';
import { ReactComponent as HipercardSvg } from '../../../../assets/icons/cards/Hipercard.svg';
import Alelo from '../../../../assets/icons/cards/Alelo.png';
import Sodexo from '../../../../assets/icons/cards/Sodexo.png';
import Sorocred from '../../../../assets/icons/cards/Sorocred.png';
import Ticket from '../../../../assets/icons/cards/Ticket.png';
import Vr from '../../../../assets/icons/cards/Vr.png';

import { validationCard } from '../../../../util/validation';
import { currencyMask } from '../../../../util/mask';

import Input from '../../../components/Input';
import CheckBox from '../../../components/CheckBox';

import {
  Container,
  TabsArea,
  TabPayments,
  TabPayment,
  CreditCardInfo,
  Cards,
  CreditCard,
  Border,
  OnlinePayment,
  DataArea,
  IncorrectNumber,
  Line,
  BankBillArea,
  PaymentBody,
  CardArea,
  Card,
  Method,
  Change,
  CheckBoxArea,
  ResponseArea,
  CardOptions,
  InputArea,
  OtherCard,
} from './styles';
import { SubTitle } from '../../styles';

function Payment({
  deliveryPaymentsCards,
  setMoney,
  money,
  setCurrentCard,
  setChange,
  change,
  setCardNumber,
  cardNumber,
  setValidity,
  validity,
  setCvv,
  cvv,
  bankBill,
  setBankBill,
  creditCard,
  setCreditCard,
  setCardholderName,
  cardholderName,
  setCardholderDocument,
  cardholderDocument,
  color,
  deliveryPaymentsMethods,
  currentCard,
  storeCreditCard,
  storeBankBill,
  deliveryPayment,
  setDeliveryPayment,
  incorrectCardNumber,
  setIncorrectCardNumber,
  storeEnvironment,
}) {
  const [credit, setCredit] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [debit, setDebit] = useState(false);
  const [debitCards, setDebitCards] = useState([]);
  const [voucher, setVoucher] = useState(false);
  const [voucherCards, setVoucherCards] = useState([]);
  const [onlinePayment, setOnlinePayment] = useState(
    storeCreditCard || storeBankBill
  );
  const [visa, setVisa] = useState(false);
  const [mastercard, setMastercard] = useState(false);
  const [amex, setAmex] = useState(false);
  const [dinersClub, setDinersClub] = useState(false);
  const [hipercard, setHipercard] = useState(false);
  const [elo, setElo] = useState(false);
  const [discover, setDiscover] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);

  const changeRef = createRef(null);

  useEffect(() => {
    if (changeStatus && changeRef) {
      if (changeRef?.current) {
        changeRef.current.focus();
      }
    }
  }, [changeStatus]);

  useEffect(() => {
    const scriptYapay = document.createElement('script');
    scriptYapay.src = 'https://static.traycheckout.com.br/js/finger_print.js';
    scriptYapay.async = false;
    document.head.appendChild(scriptYapay);

    if (deliveryPaymentsCards?.length > 0) {
      const credits = deliveryPaymentsCards.filter(
        (deliveryPaymentsCard) => deliveryPaymentsCard.type === 'Credit Card'
      );
      if (credits?.length > 0) {
        setCreditCards(credits);
      }
      const debits = deliveryPaymentsCards.filter(
        (deliveryPaymentsCard) => deliveryPaymentsCard.type === 'Debit Card'
      );
      if (debits?.length > 0) {
        setDebitCards(debits);
      }
      const vouchers = deliveryPaymentsCards.filter(
        (deliveryPaymentsCard) => deliveryPaymentsCard.type === 'Voucher Card'
      );
      if (vouchers?.length > 0) {
        setVoucherCards(vouchers);
      }
    }
  }, []);

  useEffect(() => {
    if (window.yapay) {
      window.yapay.FingerPrint({ env: storeEnvironment });
    }
  }, [cardNumber]);

  function handleIcon(icon) {
    if (icon === 'American Express') {
      return (
        <OtherCard>
          <img src={Amex} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Diners Club') {
      return (
        <OtherCard>
          <img src={DinersClub} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Visa') {
      return (
        <OtherCard>
          <img src={Visa} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Mastercard') {
      return (
        <OtherCard>
          <img src={Mastercard} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Elo') {
      return (
        <OtherCard>
          <img src={Elo} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Discover Network') {
      return (
        <OtherCard>
          <img src={Discover} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Maestro') {
      return (
        <OtherCard>
          <img src={Maestro} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Hipercard') {
      return (
        <OtherCard>
          <img src={Hipercard} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Alelo') {
      return (
        <OtherCard>
          <img src={Alelo} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Sodexo') {
      return (
        <OtherCard>
          <img src={Sodexo} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Sorocred') {
      return (
        <OtherCard>
          <img src={Sorocred} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Ticket') {
      return (
        <OtherCard>
          <img src={Ticket} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }
    if (icon === 'Vr') {
      return (
        <OtherCard>
          <img src={Vr} alt={icon} /> <span>{icon}</span>
        </OtherCard>
      );
    }

    return (
      <OtherCard>
        <img src={DefaultCard} alt={icon} /> <span>{icon}</span>
      </OtherCard>
    );
  }

  function handleMoney() {
    setMoney(true);
    setCurrentCard(null);
    setCredit(false);
    setDebit(false);
    setVoucher(false);
  }
  function handleCredit() {
    setCurrentCard(null);
    setChange('');
    setMoney(false);
    setCredit(!credit);
    setDebit(false);
    setVoucher(false);
  }
  function handleDebit() {
    setCurrentCard(null);
    setChange('');
    setMoney(false);
    setCredit(false);
    setDebit(!debit);
    setVoucher(false);
  }
  function handleVoucher() {
    setCurrentCard(null);
    setChange('');
    setMoney(false);
    setCredit(false);
    setDebit(false);
    setVoucher(!voucher);
  }

  function handleChange(text) {
    // const textAux = text.replace(/[\D]+/g, '').replace(/^0+/, '');

    // const { length } = textAux;

    // if (length === 0) {
    //   text = '';
    // }

    // if (length === 1) {
    //   text = `0,0${textAux}`;
    // }

    // if (length === 2) {
    //   text = `0,${textAux}`;
    // }

    // if (length > 2) {
    //   let textAux2 = '';
    //   for (let j = 0, i = length - 3; i >= 0; i -= 1) {
    //     if (j === 3) {
    //       textAux2 += '.';
    //       j = 0;
    //     }
    //     textAux2 += textAux.charAt(i);
    //     j += 1;
    //   }
    //   text = '';
    //   const lengthAux = textAux2.length;
    //   for (let i = lengthAux - 1; i >= 0; i -= 1) text += textAux2.charAt(i);
    //   text += `,${textAux.substr(length - 2, length)}`;
    // }

    setChange(currencyMask(text, '.', ','));
  }

  function handleOnlinePayment() {
    setOnlinePayment(true);
    setDeliveryPayment(false);
  }

  function handleDeliveryPayment() {
    setCredit(true);
    setOnlinePayment(false);
    setDeliveryPayment(true);
  }

  function verifyFlag(flag) {
    switch (flag) {
      case 'Visa':
        setVisa(true);
        setMastercard(false);
        setAmex(false);
        setDinersClub(false);
        setHipercard(false);
        setElo(false);
        setDiscover(false);
        setIncorrectCardNumber(false);
        break;

      case 'Mastercard':
        setVisa(false);
        setMastercard(true);
        setAmex(false);
        setDinersClub(false);
        setHipercard(false);
        setElo(false);
        setDiscover(false);
        setIncorrectCardNumber(false);
        break;

      case 'Amex':
        setVisa(false);
        setMastercard(false);
        setAmex(true);
        setDinersClub(false);
        setHipercard(false);
        setElo(false);
        setDiscover(false);
        setIncorrectCardNumber(false);
        break;

      case 'Diners':
        setVisa(false);
        setMastercard(false);
        setAmex(false);
        setDinersClub(true);
        setHipercard(false);
        setElo(false);
        setDiscover(false);
        setIncorrectCardNumber(false);
        break;

      case 'Hipercard':
        setVisa(false);
        setMastercard(false);
        setAmex(false);
        setDinersClub(false);
        setHipercard(true);
        setElo(false);
        setDiscover(false);
        setIncorrectCardNumber(false);
        break;

      case 'Elo':
        setVisa(false);
        setMastercard(false);
        setAmex(false);
        setDinersClub(false);
        setHipercard(false);
        setElo(true);
        setDiscover(false);
        setIncorrectCardNumber(false);
        break;

      case 'Discover':
        setVisa(false);
        setMastercard(false);
        setAmex(false);
        setDinersClub(false);
        setHipercard(false);
        setElo(false);
        setDiscover(true);
        setIncorrectCardNumber(false);
        break;

      default:
        break;
    }
  }

  function handleCardNumber(text) {
    text = text
      .replace(/[^0-9.]/g, '')
      .replace(/\W/gi, '')
      .replace(/(.{4})/g, ' $1');
    setCardNumber(text);

    const number = text.split(' ').join('');
    const response = validationCard(number);
    if (response?.operadora) {
      verifyFlag(response.operadora);
      return;
    }
    setIncorrectCardNumber(true);
    setVisa(false);
    setMastercard(false);
    setAmex(false);
    setDinersClub(false);
    setHipercard(false);
    setElo(false);
    setDiscover(false);
  }

  function handleValidity(text) {
    text = text
      .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
      .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
      .replace(/^([0-1])([3-9])$/g, '0$1/$2')
      .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
      .replace(/^([0]+)\/|[0]+$/g, '0')
      .replace(/[^\d/]|^[/]*$/g, '')
      .replace(/\/\//g, '/');
    setValidity(text);
  }

  const DocumentMask = (text) => {
    if (text.length <= 14) {
      text = text.replace(/\D/g, '');
      text = text.replace(/(\d{3})(\d)/, '$1.$2');
      text = text.replace(/(\d{3})(\d)/, '$1.$2');
      text = text.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      text = text.replace(/\D/g, '');
      text = text.replace(/^(\d{2})(\d{3})(\d)/g, '$1.$2.$3');
      text = text.replace(/(\d{3})(\d{4})(\d{2})$/, '$1/$2-$3');
    }
    return text;
  };

  function handleCardholderDocument(text) {
    setCardholderDocument(DocumentMask(text));
  }

  function handleCreditCard() {
    setCreditCard(true);
    setBankBill(false);
  }
  function handleBankBill() {
    setCreditCard(false);
    setBankBill(true);
  }

  function handleTitle() {
    if (
      !storeCreditCard &&
      !storeBankBill &&
      (deliveryPaymentsMethods?.credit ||
        deliveryPaymentsMethods?.money ||
        deliveryPaymentsMethods?.debit ||
        deliveryPaymentsMethods?.voucher)
    ) {
      return <span>Formas de pagamentos na entrega</span>;
    }
    if (
      storeCreditCard &&
      storeBankBill &&
      !(
        deliveryPaymentsMethods?.credit ||
        deliveryPaymentsMethods?.money ||
        deliveryPaymentsMethods?.debit ||
        deliveryPaymentsMethods?.voucher
      )
    ) {
      return <span>Formas de pagamentos online</span>;
    }

    return <span>Formas de pagamentos</span>;
  }

  function handleStatusChange() {
    if (!changeStatus) {
      setChange('');
      return setChangeStatus(true);
    }
    setChange(0);
    return setChangeStatus(false);
  }

  return (
    <Container>
      <SubTitle>
        <AiOutlineCreditCard />

        {handleTitle()}
      </SubTitle>
      <TabsArea>
        {storeCreditCard &&
          (deliveryPaymentsMethods?.credit ||
            deliveryPaymentsMethods?.money ||
            deliveryPaymentsMethods?.debit ||
            deliveryPaymentsMethods?.voucher) && (
            <TabPayment
              payment={onlinePayment}
              onClick={() => handleOnlinePayment()}
              color={color}
            >
              Pagar online
            </TabPayment>
          )}
        {storeCreditCard &&
          (deliveryPaymentsMethods?.credit ||
            deliveryPaymentsMethods?.money ||
            deliveryPaymentsMethods?.debit ||
            deliveryPaymentsMethods?.voucher) && (
            <TabPayment
              payment={deliveryPayment}
              onClick={() => handleDeliveryPayment()}
              color={color}
            >
              Pagar na entrega
            </TabPayment>
          )}
      </TabsArea>

      {onlinePayment ? (
        <OnlinePayment>
          <TabPayments>
            {storeCreditCard && (
              <Method
                current={creditCard}
                onClick={() => handleCreditCard()}
                color={color}
              >
                Cartão de crédito
              </Method>
            )}
            {storeBankBill && (
              <Method
                current={bankBill}
                onClick={() => handleBankBill()}
                color={color}
              >
                Boleto bancário
              </Method>
            )}
          </TabPayments>
          {(storeBankBill || storeCreditCard) && (
            <>
              {storeCreditCard && creditCard ? (
                <CreditCardInfo color={color} data-yapay="payment-form">
                  <Cards>
                    <CreditCard>
                      <VisaSvg />
                      <Border currentCreditCard={visa} color={color} />
                    </CreditCard>
                    <CreditCard>
                      <MastercardSvg />
                      <Border currentCreditCard={mastercard} color={color} />
                    </CreditCard>
                    <CreditCard>
                      <AmexSvg />
                      <Border currentCreditCard={amex} color={color} />
                    </CreditCard>
                    <CreditCard>
                      <DinersClubSvg />
                      <Border currentCreditCard={dinersClub} color={color} />
                    </CreditCard>
                    <CreditCard>
                      <HipercardSvg />
                      <Border currentCreditCard={hipercard} color={color} />
                    </CreditCard>
                    <CreditCard>
                      <EloSvg />
                      <Border currentCreditCard={elo} color={color} />
                    </CreditCard>
                    <CreditCard>
                      <DiscoverSvg />
                      <Border currentCreditCard={discover} color={color} />
                    </CreditCard>
                  </Cards>
                  <DataArea>
                    <span>Número do cartão</span>

                    <Input
                      required={onlinePayment}
                      maxLength={19}
                      value={cardNumber}
                      setState={(text) => handleCardNumber(text)}
                    />
                    {incorrectCardNumber && (
                      <IncorrectNumber>
                        Número de cartão incorreto.
                      </IncorrectNumber>
                    )}
                  </DataArea>
                  <Line>
                    <DataArea>
                      <span>Validade</span>
                      <Input
                        required={onlinePayment}
                        maxLength={5}
                        value={validity}
                        setState={(text) => handleValidity(text)}
                      />
                    </DataArea>
                    <DataArea>
                      <span>CVV</span>
                      <Input
                        required={onlinePayment}
                        maxLength={3}
                        value={cvv}
                        setState={(text) => setCvv(text)}
                      />
                    </DataArea>
                  </Line>
                  <DataArea>
                    <span>Nome do titular</span>
                    <Input
                      required={onlinePayment}
                      value={cardholderName}
                      setState={(text) => setCardholderName(text)}
                    />
                  </DataArea>
                  <DataArea>
                    <span>CPF/CNPJ do titular</span>
                    <Input
                      required={onlinePayment}
                      minLength={14}
                      maxLength={18}
                      value={cardholderDocument}
                      setState={(text) => handleCardholderDocument(text)}
                    />
                  </DataArea>
                </CreditCardInfo>
              ) : (
                <BankBillArea color={color}>
                  <ul>
                    <li>
                      Após a finalização do pedido poderá ser visualizado o
                      boleto bancário.
                    </li>
                    <br />
                    <li>
                      O pagamento poderá ser feito em qualquer agência bancária,
                      pelo celular ou internet banking até a data de vencimento.
                    </li>
                  </ul>
                </BankBillArea>
              )}
            </>
          )}
        </OnlinePayment>
      ) : (
        <PaymentBody>
          <CardArea>
            {' '}
            {deliveryPaymentsMethods?.credit && (
              <Method
                onClick={() => handleCredit()}
                current={credit}
                color={color}
              >
                <span>Cartão de crédito</span>
              </Method>
            )}
            {deliveryPaymentsMethods?.money && (
              <Method
                current={money}
                onClick={() => handleMoney()}
                color={color}
              >
                <span>Dinheiro</span>
              </Method>
            )}
            {deliveryPaymentsMethods?.debit && (
              <Method
                onClick={() => handleDebit()}
                current={debit}
                color={color}
              >
                <span>Cartão de débito</span>
              </Method>
            )}
            {deliveryPaymentsMethods?.voucher && (
              <Method
                onClick={() => handleVoucher()}
                current={voucher}
                color={color}
              >
                <span>Voucher</span>
              </Method>
            )}
          </CardArea>
          {money && (
            <Change color={color}>
              <ResponseArea>
                <span>Vai precisar de troco?</span>
                <CheckBoxArea>
                  <CheckBox
                    status={changeStatus}
                    id="change"
                    disable={false}
                    handleStatus={handleStatusChange}
                  />
                  {changeStatus ? <span>Sim</span> : <span>Não</span>}
                </CheckBoxArea>
              </ResponseArea>
              {changeStatus && (
                <ResponseArea>
                  <span>Para quanto?</span>
                  <InputArea>
                    <span>R$</span>
                    <Input
                      ref={changeRef}
                      maxLength={10}
                      value={change}
                      setState={handleChange}
                    />
                  </InputArea>
                </ResponseArea>
              )}
            </Change>
          )}
          {credit && (
            <CardOptions color={color}>
              {creditCards?.map((card) => (
                <Card
                  color={color}
                  key={card.name}
                  onClick={() => setCurrentCard(card)}
                  current={card.name === currentCard?.name}
                >
                  <input
                    checked={card.name === currentCard?.name}
                    type="checkbox"
                  />
                  {handleIcon(card.name)}
                </Card>
              ))}
            </CardOptions>
          )}
          {debit && (
            <CardOptions color={color}>
              {debitCards?.map((debitCard) => (
                <Card
                  onClick={() => setCurrentCard(debitCard)}
                  color={color}
                  key={debitCard.name}
                  current={debitCard.name === currentCard?.name}
                >
                  {' '}
                  <input
                    checked={debitCard.name === currentCard?.name}
                    type="checkbox"
                  />
                  {handleIcon(debitCard.name)}
                </Card>
              ))}
            </CardOptions>
          )}
          {voucher && (
            <CardOptions color={color}>
              {voucherCards?.map((voucherCard) => (
                <Card
                  onClick={() => setCurrentCard(voucherCard)}
                  color={color}
                  key={voucherCard.name}
                  current={voucherCard.name === currentCard?.name}
                >
                  {' '}
                  <input
                    checked={voucherCard.name === currentCard?.name}
                    type="checkbox"
                  />
                  {handleIcon(voucherCard.name)}
                </Card>
              ))}
            </CardOptions>
          )}
        </PaymentBody>
      )}
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...DeliveryActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  deliveryPaymentsCards:
    // eslint-disable-next-line
  state.store?.storeInfo?.payment_methods_on_delivery?.cards,
  // eslint-disable-next-line
  deliveryPaymentsMethods: state.store?.storeInfo?.payment_methods_on_delivery,
  color:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.color_primary,
  // eslint-disable-next-line
  storeCreditCard: state.store.storeInfo?.payment_methods_online ? state.store.storeInfo?.payment_methods_online.credit : false,
  // eslint-disable-next-line
  storeBankBill: state.store.storeInfo?.payment_methods_online ? state.store.storeInfo?.payment_methods_online.bank_bill : false,
  // eslint-disable-next-line
  storeEnvironment: state.store.storeInfo?.payment_methods_online?.environment,
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
