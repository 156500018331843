import produce from 'immer';

const INITIAL_STATE = {
  temporaryProducts: [],
  currentOption: {},
};

export default function cart(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_CURRENT_PRODUCT': {
        if (action.product.uuid) {
          const productIndex = draft.temporaryProducts.findIndex(
            (p) => p.uuid === action.product.uuid
          );

          if (productIndex >= 0) {
            draft.temporaryProducts.push({
              ...action.product,
            });
            break;
          }

          draft.temporaryProducts.push({
            ...action.product,
            amount: parseInt(action.product.multiple, 10),
            options: [],
          });
        }

        break;
      }
      case 'UPDATE_TEMPORARY_AMOUNT_CART': {
        const productIndex = draft.temporaryProducts.findIndex(
          (p) => p.uuid === action.uuid
        );

        if (productIndex >= 0) {
          draft.temporaryProducts[productIndex].amount = Number(action.amount);
        }
        if (draft.temporaryProducts[productIndex].amount <= 0) {
          draft.temporaryProducts.splice(productIndex, 1);
        }
        break;
      }

      case 'ADD_OPTION_TO_PRODUCT': {
        const productIndex = draft.temporaryProducts.findIndex(
          (p) => p.uuid === action.product.uuid
        );

        if (productIndex >= 0) {
          const temporaryProducts = draft.temporaryProducts[productIndex];
          const optionIndex = temporaryProducts?.options?.findIndex(
            (o) => o.id === action.option.id
          );
          if (optionIndex >= 0) {
            temporaryProducts.options[optionIndex].amount += 1;
            draft.currentOption = action.option;
            break;
          }

          if (temporaryProducts.options) {
            temporaryProducts.options.push({
              ...action.option,
              amount: 1,
            });
            draft.currentOption = action.option;
            break;
          }
          draft.temporaryProducts[productIndex] = {
            ...temporaryProducts,
            options: [
              {
                ...action.option,
                amount: 1,
              },
            ],
          };
          draft.currentOption = action.option;
          break;
        }

        break;
      }

      case 'REMOVE_OPTION': {
        const productIndex = draft.temporaryProducts.findIndex(
          (p) => p.uuid === action.product.uuid
        );

        const optionIndex = draft.temporaryProducts[
          productIndex
        ].options.findIndex((o) => o.id === action.option.id);

        draft.temporaryProducts[productIndex].options.splice(optionIndex, 1);

        break;
      }

      case 'CHANGE_OPTION': {
        const productIndex = draft.temporaryProducts.findIndex(
          (p) => p.uuid === action.product.uuid
        );

        const temporaryProducts = draft.temporaryProducts[productIndex];

        const optionIndex = temporaryProducts.options.findIndex(
          (option) => option.id === action.optionToRemove.id
        );

        if (optionIndex >= 0) {
          draft.temporaryProducts[productIndex].options.splice(optionIndex, 1, {
            ...action.item,
            amount: 1,
          });

          break;
        }

        if (state.currentOption) {
          const currentOptionIndex = temporaryProducts.options.findIndex(
            (option) => option.id === draft.currentOption.id
          );

          temporaryProducts.options.splice(currentOptionIndex, 1);
          draft.temporaryProducts[productIndex] = {
            ...temporaryProducts,
            options: [
              {
                ...action.item,
                amount: 1,
              },
            ],
          };
          break;
        }
        if (temporaryProducts.options) {
          temporaryProducts.options.push({
            ...action.item,
            amount: 1,
            options: [],
          });
          break;
        }
        break;
      }

      case 'UPDATE_OPTION_AMOUNT_PRODUCT': {
        const productIndex = draft.temporaryProducts.findIndex(
          (p) => p.uuid === action.product.uuid
        );

        if (productIndex >= 0) {
          const optionIndex = draft.temporaryProducts[
            productIndex
          ].options.findIndex((o) => o.id === action.itemId);

          if (optionIndex >= 0) {
            draft.temporaryProducts[productIndex].options[
              optionIndex
            ].amount = Number(action.amount);
          }
          if (
            draft.temporaryProducts[productIndex].options[optionIndex].amount <=
            0
          ) {
            draft.temporaryProducts[productIndex].options.splice(
              optionIndex,
              1
            );
          }
        }
        break;
      }

      case 'SEND_TO_CART': {
        // if (!action.product.uuid) {
        //   const productIndex = draft.temporaryProducts.findIndex(
        //     (p) => p.id === action.product.id
        //   );

        //   draft.temporaryProducts.splice(productIndex, 1);
        // }
        break;
      }

      case 'HANDLE_PRODUCT_DETAIL': {
        if (action.product && !action.product.uuid && !action.state) {
          const productIndex = draft.temporaryProducts.findIndex(
            (p) => p.id === action.product.id
          );

          draft.temporaryProducts.splice(productIndex, 1);
        }
        break;
      }

      case 'SET_TEMPORARY_PRODUCT': {
        const productIndex = draft.temporaryProducts.findIndex(
          (product) => product.id === action.product.id
        );

        if (productIndex >= 0) {
          draft.temporaryProducts[productIndex] = action.product;
        }
        break;
      }

      default:
    }
  });
}
