import styled, { keyframes } from 'styled-components';
import { transparentize } from 'polished';

const animation = keyframes`
 from {
    margin-right: 50%;
		margin-left: -100px;
		opacity: 0
	}
	to {
    margin-right:0%;
		margin-left: 0px;
		opacity: 1
	}

`;

// #region CATEGORIES
export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 40px;
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  transition: width 0.8s;

  @media (max-width: 1700px) {
    margin-right: ${(props) => (!props.cartVisible ? '2%' : '0%')};
  }
  @media (max-width: 555px) {
    font-size: 18px;
    padding: 10px 0 0 10px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.grayDark};
  height: 40px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
`;

export const CategoryArea = styled.div`
  font-size: 16px;
  color: #6e7989;
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  display: flex;
  align-items: center;
  top: 90px;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: auto;
  z-index: 3;
  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  position: ${(props) => (props.fixed ? 'fixed' : 'fixed')};
  background: ${(props) => (props.fixed ? '#fff' : '#fff')};
  margin-top: ${(props) => (props.fixed ? '0' : '0')};
  left: ${(props) => (props.fixed ? '0' : '0')};
  transition: background 0.8s, position 0.8s, width 0.8s;

  svg {
    margin: 0 8px 0 5px;
    height: 20px;
    width: 20px;
  }

  @media (max-width: 1150px) {
    top: ${(props) => (props.headerVisible ? '90px' : '0px')};
    width: 100%;
  }

  @media (max-width: 555px) {
    top: ${(props) => (props.headerVisible ? '50px' : '0px')};
  }
`;

export const CurrentChip = styled.div`
  margin-right: 5px;
  height: 35px;
  padding: 5px 10px;
  background: #ddd;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
  transition: box-shadow 0.6s;
  cursor: pointer;

  animation-name: ${animation};
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-play-state: running;

  &:hover {
    box-shadow: 2px 5px 5px #80828b99;
  }

  &:last-child {
    background: ${(props) => transparentize(0.8, props.color)};
    color: ${(props) => props.color};
  }
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.cartVisible ? '0' : '0')};
  transition: margin-right 0.8s;

  @media (max-width: 1150px) {
    width: 100%;
  }

  .scroll-container {
    padding: 5px;
    width: 100%;
    flex-direction: row;
    display: flex;
    overflow-y: hidden;
    white-space: nowrap;
    align-items: center;
    transition: width 2s;
    transition: margin-left 0.6s;

    ::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 1150px) {
    width: 100%;
    margin-right: 20px;

    .scroll-container {
      ::-webkit-scrollbar {
        width: 5px;
        height: 0px;
      }
    }
  }
`;

export const CategoriesList = styled.div`
  padding: 5px;
  width: 100%;
  flex-direction: row;
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  align-items: center;
  transition: width 2s;
  transition: margin-left 0.6s;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 1150px) {
    width: 100%;
    margin-right: 20px;
  }
`;

export const Chip = styled.button`
  height: 40px;
  border-radius: 25px;
  align-items: center;
  background: #fff;
  border: 2px solid ${(props) => props.color} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 18px;
  padding: 10px;
  margin-right: 5px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
  transition: box-shadow 0.6s;

  &:hover {
    box-shadow: 2px 5px 5px #80828b99;
  }

  div {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    border-radius: 17px;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 420px) {
    height: 40px;
    padding: 10px;
    border: 1px solid ${(props) => props.color};
    font-size: 16px;
    margin-top: 10px;

    div {
      height: 36px;
      width: 36px;
    }
  }
`;
// #endregion
