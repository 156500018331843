export function getStoreInfo() {
  return {
    type: 'GET_STORE_INFO',
  };
}
export function setStoreInfo(store) {
  return {
    type: 'SET_STORE_INFO',
    payload: store,
  };
}

export function setMinTime(time) {
  return {
    type: 'SET_MIN_TIME',
    payload: { time },
  };
}
export function setMaxTime(time) {
  return {
    type: 'SET_MAX_TIME',
    payload: { time },
  };
}

export function setDeliveryTax(deliveryTax) {
  return {
    type: 'SET_DELIVERY_TAX',
    payload: { deliveryTax },
  };
}
