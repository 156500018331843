import React, { useEffect, useState } from 'react';

import { useDispatch, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FiSearch, FiUser, FiLogIn, FiBox } from 'react-icons/fi';
import * as MainActions from '../../../../store/modules/main/actions';

import {
  handleCloseLoginModal,
  handleLoginModal,
} from '../../../../store/modules/auth/actions';

import { TabContainer, TabNav } from './styles';

function Tab({
  signed,
  handleCart,
  cartVisible,
  handleHeader,
  headerVisible,
  handleMenuProfile,
  menuProfileVisible,
  menuAddress,
  showLoginModal,
  color,
  handleMenuAddress,
}) {
  const [visibles, setVisibles] = useState(true);
  useEffect(() => {
    if (
      !cartVisible === true ||
      headerVisible === true ||
      menuProfileVisible === true ||
      showLoginModal === true
    ) {
      setVisibles(false);
    } else {
      setVisibles(true);
    }
  }, [cartVisible, headerVisible, menuProfileVisible, showLoginModal]);

  const dispatch = useDispatch();

  function handleMenuProfileVisible() {
    if (menuAddress) handleMenuAddress(false);
    if (headerVisible) handleHeader();
    handleMenuProfile();
  }

  function handleCloseModalButton() {
    if (menuAddress) handleMenuAddress(false);
    if (headerVisible) handleHeader(false);
    dispatch(handleLoginModal());
  }

  function handleCloseLoginModalButton() {
    dispatch(handleCloseLoginModal());
  }

  function handleVisibleHeader() {
    if (showLoginModal) handleCloseLoginModalButton();
    if (cartVisible) handleCart(false);
    if (menuProfileVisible) handleMenuProfile();
    handleHeader(!headerVisible);
  }

  function handleCloseAll() {
    if (cartVisible) handleCart(false);
    if (headerVisible) handleHeader(false);
    if (menuProfileVisible) handleMenuProfile();
    if (showLoginModal) handleCloseLoginModalButton();
    if (menuAddress) handleMenuAddress(false);
  }

  return (
    <TabContainer color={color}>
      <TabNav
        visibles={visibles}
        color={color}
        onClick={() => handleCloseAll()}
      >
        {/* <img alt="Products" src={BoxIcon} /> */}
        <FiBox />
        <span>Produtos</span>
      </TabNav>
      <TabNav
        color={color}
        headerVisible={headerVisible}
        onClick={() => handleVisibleHeader()}
      >
        <FiSearch />
        <span>Pesquisa</span>
      </TabNav>
      {/* <FiFilter /> */}
      {signed ? (
        <TabNav
          color={color}
          menuProfileVisible={menuProfileVisible}
          onClick={() => handleMenuProfileVisible()}
        >
          <FiUser />
          <span>Perfil</span>
        </TabNav>
      ) : (
        <TabNav
          color={color}
          showLoginModal={showLoginModal}
          onClick={() => handleCloseModalButton()}
        >
          <FiLogIn />
          <span>Entrar</span>
        </TabNav>
      )}
    </TabContainer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MainActions, dispatch);

const mapStateToProps = (state) => ({
  signed: state.auth.signed,
  menuAddress: state.main.menuAddress,
  menuProfileVisible: state.main.menuProfileVisible,
  cartVisible: state.main.cartVisible,
  headerVisible: state.main.headerVisible,
  showLoginModal: state.auth.showLoginModal,
  color: state.store.storeInfo.settings.color_primary,
});
export default connect(mapStateToProps, mapDispatchToProps)(Tab);
