import { takeLatest, all, put, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

import { validationResponse } from '../../../util/validation';

import { setMinTime, setMaxTime, setDeliveryTax } from '../store/actions';
import {
  setDeliveryAddress,
  handleLoadingAddress,
  handleLocationSelected,
  setErrorMessage,
  setAddresses,
  setCurrentAddress,
  setZipCode,
  setSubmitAddress,
  handleEditAddress,
  setFreights,
  handleModalFreight,
  handleModalAddress,
  handleLoadingFreight,
} from './actions';

import { handleMenuAddress, handleLoading } from '../main/actions';

import api from '../../../services/api';
import { updateCustomer } from '../user/actions';

// #region GET ADDRESS BY GEOLOCATION
export function* getAddressByGeolocation(payload) {
  const { latitude, longitude } = payload;

  const address = yield select((state) => state.delivery.address);

  if (address) {
    yield put(setDeliveryAddress(address));
    return yield put(handleLoadingAddress(false));
  }

  const response = yield api.getAddressByGeo(latitude, longitude);
  if (response.status && response.status === 200) {
    yield put(setDeliveryAddress(response.data.address));
    return yield put(handleLoadingAddress(false));
  }
  toast.error('Ocorreu um erro!', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  yield put(handleLoadingAddress(false));
  return yield put(handleLocationSelected(false));
}
// #endregion

// #region FIND ZIP CODE
export function* findZipCode(payload) {
  const { zipCode } = payload;
  yield put(handleLoadingAddress(true));
  yield put(setDeliveryAddress({}));

  const response = yield api.getZipCode(zipCode);

  if (!response.data) {
    yield put(handleLoadingAddress(false));
    yield put(setZipCode(''));
    yield put(setDeliveryAddress(null));
    return toast.error('CEP inválido!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  if (response.status && response.status === 200) {
    yield put(
      setDeliveryAddress({
        neighbourhood: response.data.neighborhood,
        zipCode: response.data.cep,
        city: response.data.city,
        street: response.data.street,
        state: response.data.state,
        complement: '',
      })
    );

    return yield put(handleLoadingAddress(false));
  }

  return yield put(handleLoadingAddress(false));
}

// #endregion

// #region GET GEOLOCATION
export function* getGeolocation(payload) {
  const {
    name,
    currentStreet,
    currentNeighbourhood,
    number,
    complement,
  } = payload;

  yield put(handleLoadingAddress(true));

  const address = yield select((state) => state.delivery.address);

  const { street, neighbourhood, city } = address;

  const addressToSearch = `${street || currentStreet} ${number} ${
    neighbourhood || currentNeighbourhood
  } ${city}`;

  const response = yield api.getGeolocationByAddress(addressToSearch);

  if (response.data.status === 'OK') {
    const verify = yield api.checkLocation(
      response.data.results[0].geometry.location.lat,
      response.data.results[0].geometry.location.lng
    );

    const validation = validationResponse(verify);

    if (!validation) {
      return toast.error(
        'Erro ao buscar endereço, tente novamente mais tarde.'
      );
    }

    if (verify.data.response) {
      yield put(
        setDeliveryAddress({
          name,
          neighbourhood: address.neighbourhood,
          number,
          zipCode: address.zipCode,
          complement: `${address.complement} ${complement}`,
          city: address.city,
          street: address.street,
          state: address.state,
          latitude: response.data.results[0].geometry.location.lat,
          longitude: response.data.results[0].geometry.location.lng,
        })
      );
      if (verify.data.response.min_waiting_time)
        yield put(setMinTime(verify.data.response.min_waiting_time));
      if (verify.data.response.max_waiting_time)
        yield put(setMaxTime(verify.data.response.max_waiting_time));
      if (verify.data.response.price)
        yield put(setDeliveryTax(verify.data.response.price));
      yield put(setErrorMessage(null));
      yield put(handleMenuAddress(false));
      return yield put(handleLoadingAddress(false));
    }
    yield put(setErrorMessage('Local informado está fora da área de entrega.'));
    yield put(setZipCode(''));
    yield put(handleLoadingAddress(false));
    return yield put(setDeliveryAddress(null));
  }
  yield put(setErrorMessage(''));
  yield put(handleLoadingAddress(false));
  return yield put(setDeliveryAddress(null));
}
// #endregion

// #region  GET ADDRESSES
export function* getAddresses() {
  const customerId = yield select((state) => state.user.profile.id);

  const response = yield api.getAddresses(customerId);

  const validation = validationResponse(response);

  if (!validation) {
    return toast.error('Erro ao buscar endereços, tente novamente mais tarde.');
  }

  yield put(setAddresses(response.data));

  const signed = yield select((state) => state.auth.signed);
  if (!signed) {
    const address = response.data[0];
    yield put({ type: 'HANDLE_CURRENT_ADDRESS', payload: { address } });
  }
  return yield put(handleLoading(false));
}
// #endregion

export function* handleCurrentAddress({ payload }) {
  const verify = yield api.checkLocation(
    payload.address.latitude,
    payload.address.longitude
  );

  const validationVerify = validationResponse(verify);

  if (!validationVerify) {
    return toast.error('Erro ao buscar endereço, tente novamente mais tarde.');
  }
  if (verify.data.response.min_waiting_time)
    yield put(setMinTime(verify.data.response.min_waiting_time));
  if (verify.data.response.max_waiting_time)
    yield put(setMaxTime(verify.data.response.max_waiting_time));
  if (verify.data.response.price)
    yield put(setDeliveryTax(verify.data.response.price));

  return yield put(setCurrentAddress(payload.address));
}

// #region HANDLE CUSTOMER ADDRESS
export function* handleCustomerAddress(payload) {
  const {
    name,
    zipCode,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
  } = payload;

  const addressToSearch = `${street} ${number} ${neighbourhood} ${city}`;

  const geolocation = yield api.getGeolocationByAddress(addressToSearch);

  const latitude = geolocation.data.results[0].geometry.location.lat;
  const longitude = geolocation.data.results[0].geometry.location.lng;

  if (geolocation && geolocation.data.status === 'OK') {
    const verify = yield api.checkLocation(
      geolocation.data.results[0].geometry.location.lat,
      geolocation.data.results[0].geometry.location.lng
    );

    if (!verify.data.response) {
      yield put(setZipCode(''));
      toast.error('Local informado está fora da área de entrega.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      yield put(setZipCode(null));
      yield put(handleLoadingAddress(false));
      return yield put(setDeliveryAddress(null));
    }

    const customerId = yield select((user) => user.user.profile.id);
    const neighborhood = neighbourhood;
    const addressCreated = yield api.createCustomerAddress(
      name,
      zipCode,
      street,
      complement,
      neighborhood,
      city,
      state,
      number,
      latitude,
      longitude,
      customerId
    );

    if (
      addressCreated && addressCreated.response
        ? addressCreated.response.status === 400
        : addressCreated.status === 400
    ) {
      yield put(setZipCode(''));
      yield put(handleLoadingAddress(false));
      return toast.warn('Endereço já cadastrado.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (
      addressCreated && addressCreated.response
        ? addressCreated.response.status !== 200
        : addressCreated.status !== 200
    ) {
      yield put(setZipCode(''));
      yield put(handleLoadingAddress(false));
      return toast.error('Ocorreu um erro, tente mais tarde.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    yield put(handleLoadingAddress(false));

    yield put(setSubmitAddress(false));
    yield put({
      type: 'GET_ADDRESSES',
    });
    return toast.success('Endereço cadastrado com sucesso!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  return true;
}
// #endregion

// #region DELETE ADDRESS
export function* deleteAddress(payload) {
  const { addressId } = payload;
  const customerId = yield select((state) => state.user.profile.id);
  yield put(handleLoadingAddress(true));

  const response = yield api.deleteCustomerAddress(customerId, addressId);

  if (response.status === 200) {
    yield put(handleLoadingAddress(false));
    yield put({
      type: 'GET_ADDRESSES',
    });
    swal('Endereço excluido com sucesso!', {
      icon: 'success',
    });
  }
  yield put(handleLoadingAddress(false));
}
// #endregion

// #region HANDLE UPDATE ADDRESS
export function* handleUpdateAddress(payload) {
  const customerId = yield select((state) => state.user.profile.id);
  const currentAddress = yield select((state) => state.delivery.currentAddress);
  const {
    zipCode,
    name,
    street,
    neighbourhood,
    city,
    state,
    number,
    complement,
  } = payload;

  yield put(handleLoadingAddress(true));

  const {
    zip_code: currentZipCode,
    name: currentName,
    street: currentStreet,
    neighborhood: currentNeighbourhood,
    city: currentCity,
    state: currentState,
    number: currentNumber,
    complement: currentComplement,
  } = currentAddress;

  if (
    zipCode === currentZipCode &&
    name === currentName &&
    street === currentStreet &&
    neighbourhood === currentNeighbourhood &&
    city === currentCity &&
    state === currentState &&
    number === currentNumber &&
    complement === currentComplement
  ) {
    yield put(handleLoadingAddress(false));
    toast.info('Sem alterações!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return yield put(handleEditAddress(false));
  }

  const addressToSearch = `${street} ${number} ${neighbourhood} ${city}`;

  const geolocation = yield api.getGeolocationByAddress(addressToSearch);
  if (geolocation && geolocation.data.status === 'OK') {
    const latitude = geolocation.data.results[0].geometry.location.lat;
    const longitude = geolocation.data.results[0].geometry.location.lng;

    const verify = yield api.checkLocation(latitude, longitude);

    if (!verify.data.response) {
      yield put(setZipCode(''));
      toast.error('Local informado está fora da área de entrega.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      yield put(setZipCode(null));
      yield put(handleLoadingAddress(false));
      return yield put(setDeliveryAddress(null));
    }
    const addressId = currentAddress.id;
    const response = yield api.updateCustomerAddress(
      addressId,
      customerId,
      zipCode,
      name,
      street,
      neighbourhood,
      city,
      state,
      number,
      complement,
      latitude,
      longitude
    );

    if (
      response && response.response
        ? response.response.status !== 200
        : response.status !== 200
    ) {
      yield put(handleLoadingAddress(false));
      toast.error('Falha ao atualizar endereço, tente mais tarde.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(handleLoadingAddress(false));
      return yield put(handleEditAddress(false));
    }
    yield put(handleLoadingAddress(false));
    swal('Endereço atualizado com sucesso!', {
      icon: 'success',
    });
    yield put({
      type: 'GET_ADDRESSES',
    });
    yield put(handleLoadingAddress(false));
    return yield put(handleEditAddress(false));
  }
  yield put(setZipCode(''));
  yield put(handleLoadingAddress(false));
  return yield put(setDeliveryAddress(null));
}

export function* getFreights({ payload }) {
  yield put(handleLoadingFreight(true));

  const origin = yield select((state) => state.store.storeInfo.zip_code);
  const { destiny, weight, height, width, length } = payload.data;
  const data = {
    origin: origin.split('-').join(''),
    destiny,
    weight,
    length,
    height,
    width,
  };

  const response = yield api.getFreights(data);

  const error = [];
  response.data.map((res) => {
    if (res.error !== '0') {
      error.push(res.error);
    }

    return false;
  });

  if (error.length >= 5) {
    yield put(handleLoadingFreight(false));
    return toast.error('Erro ao buscar frete, tente novamente mais tarde.');
  }

  const validation = validationResponse(response);
  if (!validation) {
    yield put(handleLoadingFreight(false));
    return toast.error('Erro ao buscar frete, tente novamente mais tarde.');
  }

  yield put(handleLoadingFreight(false));

  yield put(setFreights(response.data));
  return yield put(handleModalFreight(true));
}

export function* createAddress({ payload }) {
  const {
    name,
    zipCode,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
    customerId,
  } = payload.address;

  const addressToSearch = `${street} ${number} ${neighbourhood} ${city}`;

  const geolocation = yield api.getGeolocationByAddress(addressToSearch);

  const validation = validationResponse(geolocation);

  if (!validation) {
    return toast.error('Erro ao buscar endereço tente novamente mais tarde.');
  }

  const latitude = geolocation.data.results[0].geometry.location.lat;
  const longitude = geolocation.data.results[0].geometry.location.lng;

  const response = yield api.createCustomerAddress(
    name,
    zipCode,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
    latitude,
    longitude,
    customerId
  );

  const validationAddress = validationResponse(response);

  if (!validationAddress) {
    return toast.error('Erro ao criar endereço, tente novamente mais tarde.');
  }
  yield put(handleModalAddress(false));
  yield put({
    type: 'GET_ADDRESSES',
  });
  yield put(updateCustomer(response.data));
  yield put(setCurrentAddress(response.data));

  return toast.success('Endereço criado com sucesso!');
}

// #endregion

export default all([
  takeLatest('GET_ADDRESS_BY_GEOLOCATION', getAddressByGeolocation),
  takeLatest('FIND_ZIP_CODE', findZipCode),
  takeLatest('GET_GEOLOCATION', getGeolocation),
  takeLatest('GET_ADDRESSES', getAddresses),
  takeLatest('HANDLE_CURRENT_ADDRESS', handleCurrentAddress),
  takeLatest('HANDLE_CUSTOMER_ADDRESS', handleCustomerAddress),
  takeLatest('DELETE_CUSTOMER_ADDRESS', deleteAddress),
  takeLatest('HANDLE_UPDATE_ADDRESS', handleUpdateAddress),
  takeLatest('GET_FREIGHTS', getFreights),
  takeLatest('CREATE_ADDRESS', createAddress),
]);
