import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { FiPlus, FiMinus } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import { RiLoaderLine } from 'react-icons/ri';
import { FaSpinner } from 'react-icons/fa';
import { MdCall } from 'react-icons/md';

import * as GroupsActions from '../../../../store/modules/groups/actions';
import * as MainActions from '../../../../store/modules/main/actions';
import * as CartActions from '../../../../store/modules/cart/actions';

import { handleLoginModal } from '../../../../store/modules/auth/actions';

import history from '../../../../services/history';

import { formatPrice } from '../../../../util/format';

import defaultImage from '../../../../assets/images/default-thumb.jpeg';

import {
  GroupContainer,
  Products,
  Section,
  SectionName,
  ButtonSeeMore,
  Item,
  Product,
  BadgeArea,
  Badge,
  ProductBody,
  ProductImage,
  ProductName,
  ProductCode,
  ProductDesUnit,
  ProductFooter,
  ProductPrices,
  Prices,
  Old,
  Price,
  NewPrice,
  Economy,
  PromotionExpiration,
  ButtonAdd,
  CompleteButton,
  Minus,
  Quantity,
  Add,
  SeeMore,
  TextSeeMore,
  Pagination,
  Consult,
  SignUpArea,
  SignUp,
  Disable,
  Unavailable,
  CustomizeArea,
  Customize,
  LoadingSeeMore,
  LoadingProducts,
  WithOutProducts,
  Cart,
  Text,
  Contact,
  Call,
} from './styles';

function Group({
  groups,
  color,
  pricePublic,
  addToCart,
  cartVisible,
  signed,
  handlePagination,
  cartItems,
  updateAmount,
  loadingSeeMore,
  loadingProducts,
  handleLoadingSeeMore,
  handleLoadingProducts,
  oldNextPage,
  headerVisible,
  handleHeader,
  oldCurrentSearch,
  handleProductDetail,
  setCurrentProduct,
  navigation,
  priceDecimals,
  showcase,
  discountClub,
  handleContact,
  setProductToContact,
}) {
  const [firstRender, setFirstRender] = useState(true);
  const [groupsWithProducts, setGroupsWithProducts] = useState([]);

  useEffect(() => {
    const array = groups.filter((group) => {
      return group.products.length > 0;
    });
    setGroupsWithProducts(array);

    // setTimeout(() => {
    handleLoadingProducts(false);
    handleLoadingSeeMore(false);
    // }, 50);

    if (!firstRender && oldNextPage <= 1) {
      const categoryElement = document.getElementById('category');

      if (categoryElement) {
        setTimeout(() => {
          const offset = headerVisible ? 120 : 20;
          window.scrollTo({
            top: categoryElement.offsetTop - offset,
          });
        }, 100);
      }
    }
    if (groups && groups.length !== 0) {
      setFirstRender(false);
    }
  }, [groups]);

  useEffect(() => {
    handleLoadingProducts(false);
    handleLoadingSeeMore(false);
  }, [navigation]);

  useEffect(() => {
    const { innerWidth: width } = window;
    if (width <= 1150) handleHeader(false);
  }, []);

  async function handleNextPage(group) {
    if (group && group.childrenCount <= 0) {
      await handlePagination(group.parent_id, group.id, group.nextPage, false);
    } else {
      await handlePagination(
        group ? group.id : null,
        null,
        group.nextPage,
        null,
        true
      );
      history.push({
        pathname: window.location.pathname,
        search:
          group && group.id
            ? `?${new URLSearchParams({ categoryId: group.id }).toString()}`
            : '',
      });

      // await handleNavigation(group);
    }
  }

  function decrement(product) {
    updateAmount(
      product.id,
      cartItems[product.id] - parseInt(product.multiple, 10)
    );
  }

  const dispatch = useDispatch();
  function handleShowModal() {
    dispatch(handleLoginModal());
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
      c
    ) {
      const r = Math.random() * 16 || 0;
      const v = c === 'x' ? r : (r && 0x3) || 0x8;
      return v.toString(16);
    });
  }

  function handleDetail(product) {
    if (product.available) {
      if (product.option_group.length > 0) {
        setCurrentProduct({
          ...product,
          uuid: uuidv4(),
          amount: cartItems[product.id],
        });
        return handleProductDetail(true);
      }
      setCurrentProduct({ ...product, amount: cartItems[product.id] });
      return handleProductDetail(true);
    }

    return false;
  }

  function handleModalContact(product) {
    setProductToContact(product);
    handleContact(true);
  }

  function gerPriceAsOf(product) {
    const obligatories = product.option_group.filter(
      (og) => og.min_choices >= 1
    );

    let minValue = 0;

    obligatories.map((obligatory) => {
      const min = Math.min.apply(
        null,
        obligatory.option_items.map((item) => {
          if (item.option_items_price[0].promotional_price) {
            return item.option_items_price[0].promotional_price;
          }
          return item.option_items_price[0].regular_price;
        })
      );

      minValue = min;
      return false;
    });

    return (
      <Customize color={color}>
        <span>A partir de</span>
        <strong>{formatPrice(minValue, priceDecimals)}</strong>
      </Customize>
    );
  }

  function handleFooterProduct(product) {
    // Produto indisponível
    if (!product.available) {
      return (
        <Unavailable>
          <span>Indisponível</span>
        </Unavailable>
      );
    }

    // Produto com variação
    if (
      (pricePublic || (!pricePublic && signed)) && // eslint-disable-next-line
      product?.option_group?.length > 0 &&
      (product.prices.length === 0 ||
        (product.prices[0].regular_price <= 0 &&
          product.prices[0].promotional_price <= 0))
    ) {
      return (
        <CustomizeArea onClick={() => handleDetail(product)}>
          {gerPriceAsOf(product)}
        </CustomizeArea>
      );
    }

    // Produto com promoção
    if (
      (pricePublic || (!pricePublic && signed)) &&
      product.prices.length > 0 &&
      product.prices[0].promotional_price &&
      product.prices[0].promotional_price > 0
    ) {
      return (
        <ProductPrices>
          <Prices>
            <div>
              <Old>De: </Old>
              <Price>
                {formatPrice(product.prices[0].regular_price, priceDecimals)}
              </Price>
            </div>
            <NewPrice>
              <strong>Por:</strong>
              {formatPrice(product.prices[0].promotional_price, priceDecimals)}
            </NewPrice>
          </Prices>
          <Economy>
            Economize{' '}
            {formatPrice(
              product.prices[0].regular_price -
                product.prices[0].promotional_price,
              2
            )}
          </Economy>
          {product.prices[0].promotional_price_expiration && (
            <PromotionExpiration>
              * Promoção válida até{' '}
              {moment(
                product.prices[0].promotional_price_expiration,
                'YYYY-MM-DD'
              ).format('DD/MM/YYYY')}{' '}
              ou enquanto durarem os estoques.
            </PromotionExpiration>
          )}
        </ProductPrices>
      );
    }

    // Preço normal
    if (
      (pricePublic || (!pricePublic && signed)) &&
      product.prices.length > 0 &&
      (!product.prices[0].promotional_price ||
        product.prices[0].promotional_price <= 0) &&
      product.prices[0].regular_price > 0
    ) {
      return (
        <ProductPrices>
          <Prices>
            <NewPrice>
              {formatPrice(product.prices[0].regular_price, priceDecimals)}
            </NewPrice>
          </Prices>
        </ProductPrices>
      );
    }

    // preço sob consulta
    if (
      (pricePublic || (!pricePublic && signed)) &&
      (product.prices.length === 0 ||
        (product.prices[0].regular_price <= 0 &&
          product.prices[0].promotional_price <= 0))
    ) {
      return <Consult>Preço sob consulta</Consult>;
    }

    // Botão cadastre-se
    if (!pricePublic && !signed) {
      return (
        <SignUpArea>
          <strong>R$</strong>
          <SignUp onClick={() => handleShowModal()} color={color}>
            Cadastre-se
          </SignUp>
        </SignUpArea>
      );
    }

    return false;
  }

  function handleAdd(product) {
    // eslint-disable-next-line
    if (product?.option_group?.length > 0) return handleDetail(product);
    return addToCart(product);
  }

  function handleButtonProduct(product) {
    if (!product.available) {
      return <></>;
    }
    if (discountClub) {
      return <></>;
    }
    if (showcase) {
      return (
        <Contact>
          <Call onClick={() => handleModalContact(product)} color={color}>
            <MdCall />
          </Call>
        </Contact>
      );
    }
    if (!cartItems[product.id] || product.option_group.length > 0) {
      return (
        <ButtonAdd
          visible={
            (pricePublic || (!pricePublic && signed)) &&
            product.prices.length > 0 &&
            product.prices[0].regular_price > 0
          }
          onClick={() => handleAdd(product)}
          color={color}
        >
          <FiPlus />
        </ButtonAdd>
      );
    }

    return false;
  }

  return loadingProducts ? (
    <LoadingProducts cartVisible={cartVisible}>
      <FaSpinner size={50} />
    </LoadingProducts>
  ) : (
    <GroupContainer cartVisible={cartVisible}>
      {!loadingProducts && groupsWithProducts.length > 0 ? (
        <>
          {oldCurrentSearch && oldCurrentSearch.length > 0 && (
            <span className="search">
              Pesquisas relacionadas à &quot;
              <strong>{oldCurrentSearch}</strong>&quot;
            </span>
          )}
          {groupsWithProducts.map((group) => {
            return (
              group.products.length !== 0 && (
                <Products key={group.id} id={group.id}>
                  <SectionName>
                    {group.name}
                    {group.childrenCount > 0 && (
                      <ButtonSeeMore
                        onClick={() => handleNextPage(group)}
                        color={color}
                      >
                        <span>Ver mais</span>
                      </ButtonSeeMore>
                    )}
                  </SectionName>
                  <Section key={group.id}>
                    {group.products.map((product) => {
                      return (
                        <Disable key={product.id} disable={product.available}>
                          <Item cartVisible={cartVisible}>
                            <Product color={color} cartVisible={cartVisible}>
                              {
                                // eslint-disable-next-line
                              product?.prices[0]?.promotional_price && (
                                  <BadgeArea>
                                    <Badge
                                      onClick={() => handleDetail(product)}
                                      color={color}
                                    >
                                      <span>
                                        -
                                        {product.prices[0].promotional_percentage.toFixed(
                                          2
                                        )}
                                        %
                                      </span>
                                    </Badge>
                                  </BadgeArea>
                                )
                              }
                              <ProductImage
                                onClick={() => handleDetail(product)}
                              >
                                <img
                                  alt={product.name}
                                  src={
                                    product.photos.length > 0
                                      ? product.photos[0].thumb_url
                                      : defaultImage
                                  }
                                />
                              </ProductImage>
                              <ProductBody
                                onClick={() => handleDetail(product)}
                              >
                                <ProductName>{product.name}</ProductName>
                                <ProductCode>{product.code}</ProductCode>
                                <ProductDesUnit>
                                  {product.unit_description}
                                </ProductDesUnit>
                              </ProductBody>
                              <ProductFooter color={color}>
                                {handleFooterProduct(product)}
                                {handleButtonProduct(product)}
                              </ProductFooter>
                              {cartItems[product.id] &&
                                !showcase &&
                                product.option_group.length <= 0 && (
                                  <CompleteButton color={color}>
                                    {cartItems[product.id] > 1 ? (
                                      <Minus
                                        color={color}
                                        onClick={() => decrement(product)}
                                      >
                                        <FiMinus />
                                      </Minus>
                                    ) : (
                                      <Minus
                                        color={color}
                                        onClick={() => decrement(product)}
                                      >
                                        <BsTrash />
                                      </Minus>
                                    )}
                                    <Quantity>
                                      <span>{cartItems[product.id]}</span>
                                    </Quantity>
                                    <Add
                                      onClick={() => {
                                        handleAdd(product);
                                      }}
                                    >
                                      <FiPlus size={20} color={color} />
                                    </Add>
                                  </CompleteButton>
                                )}
                            </Product>
                          </Item>
                        </Disable>
                      );
                    })}

                    {group.nextPage > 0 && (
                      <SeeMore onClick={() => handleNextPage(group)}>
                        {!loadingSeeMore ? (
                          <>
                            <TextSeeMore>
                              <span>
                                Ver mais itens relacionados à
                                <p />
                                <strong>{group.name}</strong>
                              </span>
                            </TextSeeMore>
                            <ButtonSeeMore
                              className="buttonSeeMore"
                              onClick={() => handleNextPage(group)}
                              color={color}
                            >
                              <span>Ver mais</span>
                            </ButtonSeeMore>
                          </>
                        ) : (
                          <LoadingSeeMore>
                            <RiLoaderLine size={30} />
                          </LoadingSeeMore>
                        )}
                      </SeeMore>
                    )}

                    {group.nextPage > 0 && (
                      <Pagination
                        onClick={() => handleNextPage(group)}
                        cartVisible={cartVisible}
                      >
                        {!loadingSeeMore ? (
                          <span>Ver mais</span>
                        ) : (
                          <LoadingSeeMore>
                            <RiLoaderLine size={30} />
                          </LoadingSeeMore>
                        )}
                      </Pagination>
                    )}
                  </Section>
                </Products>
              )
            );
          })}
        </>
      ) : (
        <WithOutProducts firstRender={firstRender}>
          <Cart>
            <Text>
              <span>
                <strong>Ops!!</strong> Nenhum resultado encontrado para &quot;
                <strong>{oldCurrentSearch}</strong> &quot;.
              </span>
              <p />
              <div>
                <span className="question">O que eu faço?</span>
                <p />
                <ul>
                  <li>Verifique os termos digitados.</li>
                  <li>Verifique se não há erro de digitação.</li>
                  <li>Tente utilizar uma única palavra.</li>
                  <li>Procure utilizar sinônimos ao termo desejado.</li>
                </ul>
              </div>
            </Text>
          </Cart>
        </WithOutProducts>
      )}
    </GroupContainer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...CartActions, ...MainActions, ...GroupsActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  discountClub: state.store.storeInfo.settings.discount_club,
  showcase: state.store.storeInfo.settings.showcase,
  priceDecimals: state.store.storeInfo.settings.price_decimals,
  navigation: state.groups.navigation,
  oldCurrentSearch: state.groups.oldCurrentSearch,
  headerVisible: state.main.headerVisible,
  oldNextPage: state.groups.oldNextPage,
  categoryRef: state.groups.categoryRef,
  loadingProducts: state.groups.loadingProducts,
  loadingSeeMore: state.groups.loadingSeeMore,
  groups: state.groups.groups,
  color: state.store.storeInfo.settings.color_primary,
  pricePublic: state.store.storeInfo.settings.price_public,
  cartVisible: state.main.cartVisible,
  signed: state.auth.signed,
  cartItems: state.cart.products.reduce((sumAmount, product) => {
    sumAmount[product.id] = product.amount;
    return sumAmount;
  }, {}),
});
export default connect(mapStateToProps, mapDispatchToProps)(Group);
