import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import * as DeliveryActions from '../../../../../../../store/modules/delivery/actions';

import Input from '../../../../../../components/Input';

import {
  Header,
  Title,
  IconArea,
  Form,
  Card,
  InputArea,
  ButtonArea,
  ButtonConfirm,
} from './styles';
import { ButtonBackArea } from '../../SubmitAddress/styles';

function SubmitAddress({
  zipCode,
  address,
  setZipCode,
  findZipCode,
  setSubmitAddress,
  handleCustomerAddress,
  color,
}) {
  const [name, setName] = useState('');
  const [currentStreet, setCurrentStreet] = useState('');
  const [currentNeighbourhood, setCurrentNeighbourhood] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  function handleZipCode(text) {
    text = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
    setZipCode(text);

    if (text && text.length > 8) {
      findZipCode(text);
    }
  }

  function goBack() {
    setSubmitAddress(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    const { city } = address || '';
    const { state } = address || '';

    const street = currentStreet || address.street;
    const neighbourhood = currentNeighbourhood || address.neighbourhood;

    handleCustomerAddress(
      name,
      zipCode,
      street,
      complement,
      neighbourhood,
      city,
      state,
      number
    );
  }

  return (
    <>
      <Header color={color}>
        <ButtonBackArea onClick={() => goBack()}>
          <IoIosArrowBack />
        </ButtonBackArea>
        <Title>Cadastro de endereço</Title>
        <IconArea />
      </Header>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Card>
          <InputArea>
            <span>CEP</span>
            <Input
              ref={inputRef}
              setState={handleZipCode}
              maxLength={9}
              value={zipCode}
              placeholder="Informe seu CEP"
            />
          </InputArea>
          {zipCode && zipCode.length > 8 && address && (
            <>
              <InputArea>
                Nome:
                <Input
                  maxLength={20}
                  placeholder="Ex.(Casa, trabalho, empresa)"
                  setState={setName}
                />
              </InputArea>
              {address.street ? (
                <InputArea>
                  <span>Rua</span> <span>{address.street}</span>
                </InputArea>
              ) : (
                <InputArea>
                  <span>Rua</span>
                  <Input
                    required
                    placeholder="Ex.(Avenida Brasil)"
                    setState={setCurrentStreet}
                  />
                </InputArea>
              )}
              {address.neighbourhood ? (
                <InputArea>
                  <span>Bairro</span>
                  <span>{address.neighbourhood}</span>
                </InputArea>
              ) : (
                <InputArea>
                  <span>Bairro</span>
                  <Input
                    required
                    placeholder="Ex.(Centro)"
                    onChange={(text) =>
                      setCurrentNeighbourhood(text.target.value)
                    }
                  />
                </InputArea>
              )}
              <InputArea>
                <span>Cidade</span>
                <span>
                  {address.city}, {address.state}
                </span>
              </InputArea>
              <InputArea>
                <span>Número</span>
                <Input
                  type="number"
                  required
                  max={99999}
                  placeholder="Ex.(2000)"
                  setState={setNumber}
                />
              </InputArea>
              <InputArea>
                <span>Complemento</span>
                <Input
                  maxLength={20}
                  type="text"
                  value={complement}
                  placeholder="Ex.(Apto 10 bloco C)"
                  setState={setComplement}
                />
              </InputArea>
            </>
          )}
        </Card>
        {zipCode && zipCode.length > 8 && address && (
          <ButtonArea>
            <ButtonConfirm color={color}>
              <span>Cadastrar endereço</span>
              <IoIosArrowForward size={25} />
            </ButtonConfirm>
          </ButtonArea>
        )}
      </Form>
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...DeliveryActions }, dispatch);

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
  zipCode: state.delivery.zipCode,
  address: state.delivery.address,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitAddress);
