import React, { forwardRef } from 'react';

import { InputStyled } from './styles';

function Input(
  {
    type,
    placeholder,
    value,
    setState,
    required,
    disabled,
    minLength,
    maxLength,
    name,
    pattern,
    autoComplete,
  },
  ref
) {
  return (
    <InputStyled
      ref={ref}
      required={required}
      type={type}
      onChange={(text) => setState(text.target.value)}
      className="input"
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      minLength={minLength}
      maxLength={maxLength}
      name={name}
      pattern={pattern}
      autoComplete={autoComplete}
    />
  );
}

export default forwardRef((props, ref) => Input(props, ref));
