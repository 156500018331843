import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: #00000050;
  height: 100%;
  width: 100%;
  z-index: 3;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 500px;
  background: #fff;
  border-radius: 10px;
  color: #6e7989;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 20px 0 20px;

  .space {
    flex: 1;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
  }
`;

export const CloseArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const Form = styled.form``;

export const Text = styled.div`
  font-size: 24px;
  padding: 0 10px 10px 10px;
`;

export const Input = styled.input`
  font-size: 18px;
  color: #727c8e;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 5px;
  transition: border 0.6s;

  &:focus {
    border: 1px solid #727c8e;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
export const Phone = styled(Name)``;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Button = styled.button.attrs({ type: 'submit' })`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 20px;
  font-size: 20px;
  border-radius: 15px;
  padding: 10px;
  background: ${(props) => props.color};
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 2px 3px #00000060;
  transition: background 0.6s, box-shadow 0.6s;
  border: 0;

  &:hover {
    box-shadow: 2px 4px 5px #00000060;
    background: ${(props) => darken(0.1, props.color)};
  }
`;

export const WhatsAppArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

export const WhatsApp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #25d366;
  font-weight: bold;
  width: 100%;
  height: 40px;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.6s, box-shadow 0.6s;
  box-shadow: 0px 2px 3px #00000060;

  &:hover {
    box-shadow: 2px 4px 5px #00000060;
    background: ${darken(0.1, '#25d366')};
  }

  svg {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
`;
