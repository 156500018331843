import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IoIosArrowDown } from 'react-icons/io';
import api from '../../../../services/api';

import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import Input from '../../../components/Input';

import {
  Container,
  ModalStyled,
  Form,
  Header,
  IconArea,
  Title,
  Body,
  InputArea,
  Line,
  ButtonArea,
  Button,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function ModalAddress({
  color,
  customerId,
  createAddress,
  handleModalAddress,
  modalAddress,
}) {
  const [name, setName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [complement, setComplement] = useState('');

  async function getAddress(text) {
    const response = await api.getZipCode(text);
    setStreet(response?.data?.street);
    setNeighborhood(response?.data?.neighborhood);
    setCity(response?.data?.city);
    setState(response?.data?.state);
  }

  function handleZipCode(text) {
    setZipCode(
      (text = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2'))
    );

    if (text.length >= 9) {
      getAddress(text);
    }
  }

  function handleCreateAddress(e) {
    e.preventDefault();
    const neighbourhood = neighborhood;
    createAddress({
      name,
      zipCode,
      street,
      complement,
      neighbourhood,
      city,
      state,
      number,
      customerId,
    });
  }

  return (
    <Container>
      <ModalStyled
        isOpen={modalAddress}
        onRequestClose={() => handleModalAddress(false)}
        style={customStyles}
      >
        <Header color={color}>
          <IconArea onClick={() => handleModalAddress(false)}>
            <IoIosArrowDown />
          </IconArea>
          <Title>Cadastro de endereço</Title>
          <IconArea />
        </Header>
        <Form onSubmit={(e) => handleCreateAddress(e)}>
          <Body>
            <InputArea>
              <span>Nome</span>
              <Input
                placeholder="Ex. ( Casa, Trabalho, etc. )."
                value={name}
                setState={setName}
              />
            </InputArea>
            <InputArea>
              <span>CEP</span>
              <Input
                placeholder="Digite seu CEP."
                required
                maxLength={9}
                value={zipCode}
                setState={handleZipCode}
              />
            </InputArea>
            <Line>
              <InputArea className="street">
                <span>Rua</span>
                <Input
                  placeholder="Digite o nome da sua rua."
                  required
                  value={street}
                  setState={setStreet}
                />
              </InputArea>
              <InputArea className="number">
                <span>Número</span>
                <Input
                  required
                  value={number}
                  setState={setNumber}
                  type="number"
                />
              </InputArea>
            </Line>
            <InputArea>
              <span>Bairro</span>
              <Input
                placeholder="Digite o nome da set bairro."
                required
                value={neighborhood}
                setState={setNeighborhood}
              />
            </InputArea>
            <Line>
              <InputArea>
                <span>Cidade</span>
                <Input
                  placeholder="Digite o nome da sua cidade."
                  required
                  value={city}
                  setState={setCity}
                />
              </InputArea>
              <InputArea className="uf">
                <span>UF</span>
                <Input
                  required
                  value={state}
                  setState={setState}
                  maxLength={2}
                />
              </InputArea>
            </Line>
            <InputArea>
              <span>Complemento</span>
              <Input
                placeholder="Digite o complemento do seu endereço."
                value={complement}
                setState={setComplement}
              />
            </InputArea>
            <ButtonArea>
              <Button color={color}>Criar endereço</Button>
            </ButtonArea>
          </Body>
        </Form>
      </ModalStyled>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...DeliveryActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  color:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.color_primary,
  customerId: state.user?.profile?.id,
  modalAddress: state.delivery.modalAddress,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddress);
