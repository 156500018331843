import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const animation = keyframes`
 from {
    /* top: 50%; */
		right: -300px;
		opacity: 0
	}
	to {
    /* top: 0px; */
		right: 0px;
		opacity: 1;
	}

`;

const animationToTop = keyframes`
 from {
    top: 50%;
		bottom: -300px;
		opacity: 0
	}
	to {
    top: 0px;
		bottom: 0px;
		opacity: 1;
	}

`;

// #region CART
export const CartContainer = styled.div`
  display: ${(props) => (props.cartVisible ? 'flex' : 'none')};
  width: 20%;
  height: 100%;
  right: 0;
  position: fixed;
  background: #f6f6f6;
  box-shadow: 0px 3px 6px #00000029;
  padding: 90px 0 0 0;
  flex-direction: column;
  z-index: 2;

  animation-name: ${animation};
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-play-state: running;

  @media (max-width: 1150px) {
    display: ${(props) => (props.cartVisible ? 'flex' : 'none')};
    width: 100%;
    margin-top: 0;
    z-index: 5;
    padding: 0 0 80px 0;
    animation-name: ${animationToTop};
    animation-duration: 0.6s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-play-state: running;
  }
`;

export const Header = styled.div`
  display: none;
  width: 100%;
  padding: 10px;
  color: #fff;
  background: ${(props) => props.color};
  max-height: 50px;

  @media (max-width: 1150px) {
    display: flex;
  }
`;

export const Icon = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;

  svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
    color: ${(props) => props.color};
  }
`;
export const TitleMobile = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;

  @media (max-width: 420px) {
    font-size: 24px;
  }
`;
export const Space = styled.div`
  flex: 1;
`;

export const CartDescription = styled.div`
  font-size: 20px;
  color: #fff;
  background: ${(props) => props.color};
  padding: 5% 3% 5% 5%;
  justify-content: space-between;
  display: flex;
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 10px 10px;
  font-weight: bold;
  color: black;
  font-size: 24px;
  border-bottom: 1px solid #ddd;
  color: ${(props) => props.theme.colors.grayDark};
  align-items: center;

  h6 {
    cursor: pointer;
  }

  @media (max-width: 1350px) {
    font-size: 20px;
  }

  @media (max-width: 1150px) {
    font-size: 24px;
  }
`;

export const Items = styled.ul`
  padding: 10px;
  flex-direction: column;
  overflow: auto;
  scroll-behavior: auto;
  overflow-x: hidden;
  height: ${(props) => {
    if (props.minimumValue && props.deliveryTax) {
      return '60%';
    }
    if (props.minimumValue && !props.deliveryTax) {
      return '68%';
    }
    if (!props.minimumValue && props.deliveryTax) {
      return '70%';
    }
    if (!props.minimumValue && !props.deliveryTax) {
      return '80%';
    }

    return '70%';
  }};

  li {
    list-style-type: none;
  }
`;

export const EmptyCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
  font-size: 24px;
  text-align: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100px;
  align-items: center;
  font-size: 20px;
  padding: 20px 0;
  width: 100%;

  & + div {
    border-top: 1px solid #eee;
  }

  img {
    height: 70px;
    width: 80px;
  }

  @media (max-width: 1150px) {
    label {
      font-size: 20px;
    }
    img {
      height: 100px;
      width: 100px;
    }
  }
`;

export const ItemArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconsArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px !important;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;

    @media (max-width: 420px) {
      width: 18px;
      height: 18px;
    }
  }
`;

export const DeleteAra = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.redDark};
`;

export const EditArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.greenDark};
`;

export const ItemImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 420px) {
    img {
      height: 50px;
      width: 50px;
      margin-right: 0;
    }
  }
`;

export const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 0 10px;

  svg {
    cursor: pointer;
  }
  input {
    border: 0;
    width: 100px;
    color: ${(props) => props.theme.colors.grayDark};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    text-align: center;
  }
  @media (max-width: 1700px) {
    input {
      width: 10%;
    }
  }

  @media (max-width: 1150px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Name = styled.span`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  cursor: pointer;

  @media (max-width: 1400px) {
    font-size: 12px;
  }
`;

export const ItemQuantity = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  padding: 10px 10px 0 0;
  width: 100%;
  justify-content: space-between;

  span {
    margin-left: 10px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.grayDark};
    font-size: 18px;
  }

  @media (max-width: 1700px) {
    input {
      width: 45px;
    }
  }
  @media (max-width: 1400px) {
    font-size: 14px;
  }
`;

export const ItemAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 5px;
    transition: border 0.6s;
    width: 80%;
    color: #6e7989;
    background: #fff;

    &:focus {
      border: 1px solid #727c8e;
    }
  }
`;

export const Qtd = styled.div`
  display: flex;
  align-items: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    border: 1px solid ${(props) => (props.multiple ? '#dee2e6' : '#ff9999')};
    border-radius: 0.25rem;
    padding: 5px;
    transition: border 0.6s;
    width: 40%;
    color: ${(props) => (props.multiple ? '#6e7989' : '#fff')};
    background: ${(props) => (props.multiple ? '#fff' : '#edafa4')};

    &:focus {
      border: 1px solid ${(props) => (props.multiple ? '#727c8e' : '#f00')};
    }
  }

  @media (max-width: 1150px) {
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }
`;

export const ButtonMinus = styled.button`
  background: #dbdde0;
  height: 18px;
  width: 18px;
  border-radius: 5px;
  border: 0;
  color: #6e7989;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonPlus = styled.button`
  background: #dbdde0;
  height: 18px;
  width: 18px;
  border-radius: 5px;
  border: 0;
  color: ${(props) => props.theme.colors.grayDark};
  font-size: 15px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Buy = styled.div`
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  border-top: ${(props) => props.deliveryTax && '1px solid #ddd'};
`;

export const Subtotal = styled.div`
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 5px 15px;
  width: 100%;
`;
export const TextSubTotal = styled.div``;
export const ValueSubtotal = styled.div``;

export const DeliveryTax = styled(Subtotal)``;
export const TextDeliveryTax = styled.div``;
export const ValueDeliveryTax = styled.div``;

export const Total = styled(Subtotal)`
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 20px;
`;

export const LabelTotal = styled.div``;

export const ValueTotal = styled.div``;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 20px 0;
`;

export const FinishButton = styled.button`
  background: ${(props) => props.color};
  width: 98%;
  height: 46px;
  border: 0;
  border-radius: 23px;
  box-shadow: 0px 3px 6px #00000050;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  transition: background 0.6s, box-shadow 0.6s;

  &:hover {
    background: ${(props) => darken(0.1, props.color)};
    box-shadow: 0px 3px 6px #00000050;
  }

  @media (max-width: 1150px) {
    height: 36px;
  }
`;
// #endregion
