import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import * as DeliveryActions from '../../../../../../store/modules/delivery/actions';
import * as MainActions from '../../../../../../store/modules/main/actions';
import * as StoreActions from '../../../../../../store/modules/store/actions';

import Loading from '../Loading';
import Input from '../../../../../components/Input';

import {
  Container,
  Form,
  Header,
  ButtonBackArea,
  ButtonArea,
  Address,
  CurrentAddress,
  SubTitle,
  IconArea,
  InputArea,
  ZipCodeArea,
  ButtonConfirm,
  Footer,
} from './styles';

function SubmitAddress({
  loadingAddress,
  address,
  getGeolocation,
  setDeliveryAddress,
  setZipCode,
  setErrorMessage,
  handleMenuAddress,
  color,
  setMinTime,
  setMaxTime,
}) {
  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [neighbourhood, setNeighbourhood] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    await getGeolocation(name, street, neighbourhood, number, complement);
  }

  function goBack() {
    setZipCode('');
    setDeliveryAddress(null);
    setErrorMessage('');
    setMinTime(null);
    setMaxTime(null);
  }

  return (
    <Container>
      {loadingAddress ? (
        <Loading />
      ) : (
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Header color={color}>
            <ButtonBackArea onClick={() => goBack()}>
              <IoIosArrowBack />
            </ButtonBackArea>
            <SubTitle>
              <span>Endereço</span>
            </SubTitle>
            <IconArea onClick={() => handleMenuAddress(false)}>
              <AiOutlineCloseCircle size={25} />
            </IconArea>
          </Header>
          <Address>
            <CurrentAddress>
              <ZipCodeArea>
                <strong>
                  {address &&
                    address.zipCode &&
                    address.zipCode
                      .replace(/\D/g, '')
                      .replace(/^(\d{5})(\d{3})/g, '$1-$2')}
                </strong>
              </ZipCodeArea>
              <InputArea>
                <span>Nome </span>
                <Input
                  ref={inputRef}
                  maxLength={20}
                  placeholder="Ex.(Casa, trabalho, empresa)"
                  setState={setName}
                />
              </InputArea>

              <p />
              {address.street ? (
                <InputArea>
                  <span>Rua </span>
                  <span>{address.street}</span>
                </InputArea>
              ) : (
                <InputArea>
                  <span>Rua</span>
                  <Input
                    required
                    placeholder="Ex.(Avenida Brasil)"
                    setState={setStreet}
                  />
                </InputArea>
              )}
              {address.neighbourhood ? (
                <InputArea>
                  <span>Bairro </span>
                  <span>{address.neighbourhood}</span>
                </InputArea>
              ) : (
                <InputArea>
                  <span>Bairro: </span>
                  <Input
                    required
                    placeholder="Ex.(Centro)"
                    setState={setNeighbourhood}
                  />
                </InputArea>
              )}
              <InputArea>
                <span>Cidade </span>
                <span>
                  {address.city}, {address.state}
                </span>
              </InputArea>
              <InputArea>
                <span>Número </span>
                <Input
                  min={1}
                  type="number"
                  required
                  placeholder="Ex.(2000)"
                  setState={setNumber}
                />
              </InputArea>
              <InputArea>
                <span>Complemento </span>
                <Input
                  maxLength={20}
                  type="text"
                  value={complement}
                  placeholder="Ex.(Apto 10 bloco C)"
                  setState={setComplement}
                />
              </InputArea>
            </CurrentAddress>
          </Address>
          <ButtonArea>
            <ButtonConfirm color={color}>
              <span>Confirmar endereço</span>
              <IoIosArrowForward size={25} />
            </ButtonConfirm>
          </ButtonArea>
          <Footer onClick={() => goBack()}>
            <span>Este não é meu endereço</span>
          </Footer>
        </Form>
      )}
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...DeliveryActions, ...MainActions, ...StoreActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
  address: state.delivery.address,
  loadingAddress: state.delivery.loadingAddress,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitAddress);
