import produce from 'immer';

const INITIAL_STATE = {
  profile: {
    id: '',
  },
  recoveryPassword: false,
  loadingSendEmail: false,
  customer: { customer_address: [{ street: '' }] },
  loadingCustomer: true,
  name: '',
  document: '',
  phone: '',
  cellphone: '',
  email: '',
  newsletterAccept: false,
  acceptContract: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SIGN_IN_SUCCESS':
        draft.profile = action.payload.user;
        break;
      case 'SIGN_OUT': {
        draft.profile = { id: '' };
        draft.customer = { customer_address: [{ street: '' }] };
        break;
      }

      case 'HANDLE_FORCE_CHANGE_PASSWORD': {
        draft.profile.force_change_password = action.payload.state;
        break;
      }
      case 'HANDLE_RECOVERY_PASSWORD': {
        draft.recoveryPassword = action.payload.state;
        break;
      }
      case 'HANDLE_LOADING_SEND_EMAIL': {
        draft.loadingSendEmail = action.payload.state;
        break;
      }
      case 'SET_CUSTOMER': {
        draft.customer = action.payload.customer;
        break;
      }

      case 'UPDATE_CUSTOMER': {
        draft.customer.customer_address.push(action.address);
        break;
      }

      case 'SET_LOADING_CUSTOMER': {
        draft.loadingCustomer = action.payload.state;
        break;
      }
      case 'SET_NAME': {
        draft.name = action.name;
        break;
      }
      case 'SET_DOCUMENT': {
        draft.document = action.document;
        break;
      }
      case 'SET_PHONE': {
        draft.phone = action.phone;
        break;
      }
      case 'SET_CELLPHONE': {
        draft.cellphone = action.cellphone;
        break;
      }
      case 'SET_EMAIL': {
        draft.email = action.email;
        break;
      }
      case 'SET_NEWSLATTER': {
        draft.newsletterAccept = action.newsletterAccept;
        break;
      }
      case 'SET_CONTRACT': {
        draft.acceptContract = action.acceptContract;
        break;
      }
      case 'SET_BIRTH_DATE': {
        draft.profile.birth_date = action.birthDate;
        break;
      }

      case 'CLEAN_DATA': {
        draft.name = '';
        draft.document = '';
        draft.phone = '';
        draft.cellphone = '';
        draft.email = '';
        draft.newsletterAccept = false;
        draft.acceptContract = false;

        break;
      }
      default:
    }
  });
}
