import React from 'react';

import { BsChatDots } from 'react-icons/bs';

import { Container, NoteArea } from './styles';
import { SubTitle } from '../../styles';

function Note({ setNote }) {
  return (
    <Container>
      <SubTitle>
        <BsChatDots size={16} />
        <span>Observações</span>
      </SubTitle>

      <NoteArea
        onChange={(text) => {
          setNote(text.target.value);
        }}
        maxLength={255}
        placeholder="Digite aqui uma observação para o vendedor."
      />
    </Container>
  );
}

export default Note;
