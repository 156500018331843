import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FiUser, FiArrowLeft } from 'react-icons/fi';

import history from '../../../services/history';
import { getStoreSlug } from '../../../util/StoreUtil';

import * as MainActions from '../../../store/modules/main/actions';
import * as GroupsActions from '../../../store/modules/groups/actions';

import { Container, ProfileButton, ImageHeader, BackButtom } from './styles';

function Header({ company, image, handleMenuProfile, handleBackArrow }) {
  useEffect(() => {
    if (!company) history.push(`/${getStoreSlug()}`);
  }, []);
  return (
    <Container style={{ justifyContent: 'space-between' }}>
      {handleBackArrow && (
        <BackButtom>
          <FiArrowLeft
            size={30}
            color="#727C8E"
            onClick={() => {
              history.push(`/${getStoreSlug()}`);
            }}
          />
        </BackButtom>
      )}
      {image ? (
        <ImageHeader
          onClick={() => {
            history.push(`/${getStoreSlug()}`);
          }}
          image={image}
        />
      ) : (
        <div style={{ display: 'flex', width: '10%' }} />
      )}
      <ProfileButton>
        <FiUser size={30} color="#727C8E" onClick={() => handleMenuProfile()} />
        <label onClick={() => handleMenuProfile()}>Perfil</label>
      </ProfileButton>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...MainActions, ...GroupsActions }, dispatch);

const mapStateToProps = (state) => ({
  image: state.store.storeInfo.settings.logo_url,
  company: state.store.storeInfo.name,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
