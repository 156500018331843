import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IoIosArrowDown } from 'react-icons/io';
import { ReactComponent as Facebook } from '../../../../assets/icons/socialMedia/facebook.svg';
import { ReactComponent as Instagram } from '../../../../assets/icons/socialMedia/instagram.svg';
import { ReactComponent as Website } from '../../../../assets/icons/socialMedia/website.svg';
import { ReactComponent as Twitter } from '../../../../assets/icons/socialMedia/twitter.svg';
import { ReactComponent as Youtube } from '../../../../assets/icons/socialMedia/youtube.svg';

import DefaultCard from '../../../../assets/icons/cards/DefaultCard.png';
import Money from '../../../../assets/icons/cards/Money.png';
import Visa from '../../../../assets/icons/cards/Visa.png';
import Mastercard from '../../../../assets/icons/cards/Mastercard.png';
import Maestro from '../../../../assets/icons/cards/Maestro.png';
import Elo from '../../../../assets/icons/cards/Elo.png';
import Amex from '../../../../assets/icons/cards/Amex.png';
import DinersClub from '../../../../assets/icons/cards/DinersClub.png';
import Discover from '../../../../assets/icons/cards/Discover.png';
import Hipercard from '../../../../assets/icons/cards/Hipercard.png';
import Alelo from '../../../../assets/icons/cards/Alelo.png';
import Sodexo from '../../../../assets/icons/cards/Sodexo.png';
import Sorocred from '../../../../assets/icons/cards/Sorocred.png';
import Ticket from '../../../../assets/icons/cards/Ticket.png';
import Vr from '../../../../assets/icons/cards/Vr.png';

import * as StoreActions from '../../../../store/modules/store/actions';
import * as MainActions from '../../../../store/modules/main/actions';

import {
  Container,
  ModalStyled,
  Header,
  Body,
  Box,
  BoxWorkPeriod,
  WorkPeriodArea,
  WeekTitle,
  Row,
  Title,
  Subtitle,
  IconArea,
  DeliveryPaymentMethods,
  CardsArea,
  Name,
  Cards,
  Card,
  SocialMedia,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function About({
  storeInfo,
  modalAbout,
  handleModalAbout,
  deliveryPaymentsMethods,
  deliveryPaymentsCards,
  color,
}) {
  const [creditCards, setCreditCards] = useState([]);
  const [debitCards, setDebitCards] = useState([]);
  const [voucherCards, setVoucherCards] = useState([]);
  const [sunday, setSunday] = useState([]);
  const [monday, setMonday] = useState([]);
  const [tuesday, setTuesday] = useState([]);
  const [wednesday, setWednesday] = useState([]);
  const [thursday, setThursday] = useState([]);
  const [friday, setFriday] = useState([]);
  const [saturday, setSaturday] = useState([]);

  // Cards
  useEffect(() => {
    if (deliveryPaymentsCards?.length > 0) {
      const credits = deliveryPaymentsCards.filter(
        (deliveryPaymentsCard) => deliveryPaymentsCard.type === 'Credit Card'
      );
      if (credits?.length > 0) {
        setCreditCards(credits);
      }
      const debit = deliveryPaymentsCards.filter(
        (deliveryPaymentsCard) => deliveryPaymentsCard.type === 'Debit Card'
      );
      if (debit?.length > 0) {
        setDebitCards(debit);
      }
      const voucher = deliveryPaymentsCards.filter(
        (deliveryPaymentsCard) => deliveryPaymentsCard.type === 'Voucher Card'
      );
      if (voucher?.length > 0) {
        setVoucherCards(voucher);
      }
    }
  }, []);

  // Work period
  useEffect(() => {
    if (storeInfo.work_period.length > 0) {
      storeInfo.work_period.map((workPeriod) => {
        if (workPeriod.week_day === 1) {
          // const sorted = customers.slice().sort((a, b) => (a.id > b.id ? 1 : -1));
          setSunday((state) => [...state, workPeriod]);
        }
        if (workPeriod.week_day === 2) {
          setMonday((state) => [...state, workPeriod]);
        }
        if (workPeriod.week_day === 3) {
          setTuesday((state) => [...state, workPeriod]);
        }
        if (workPeriod.week_day === 4) {
          setWednesday((state) => [...state, workPeriod]);
        }
        if (workPeriod.week_day === 5) {
          setThursday((state) => [...state, workPeriod]);
        }
        if (workPeriod.week_day === 6) {
          setFriday((state) => [...state, workPeriod]);
        }
        if (workPeriod.week_day === 7) {
          setSaturday((state) => [...state, workPeriod]);
        }

        return true;
      });
    }
  }, []);

  function handleCloseModal(event) {
    if (event.currentTarget === event.target) {
      handleModalAbout(false);
    }
  }

  function handleIcon(icon) {
    if (icon === 'American Express') {
      return (
        <Card>
          {' '}
          <img src={Amex} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Diners Club') {
      return (
        <Card>
          {' '}
          <img src={DinersClub} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Visa') {
      return (
        <Card>
          {' '}
          <img src={Visa} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Mastercard') {
      return (
        <Card>
          {' '}
          <img src={Mastercard} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Elo') {
      return (
        <Card>
          {' '}
          <img src={Elo} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Discover Network') {
      return (
        <Card>
          {' '}
          <img src={Discover} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Maestro') {
      return (
        <Card>
          {' '}
          <img src={Maestro} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Hipercard') {
      return (
        <Card>
          {' '}
          <img src={Hipercard} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Alelo') {
      return (
        <Card>
          {' '}
          <img src={Alelo} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Sodexo') {
      return (
        <Card>
          {' '}
          <img src={Sodexo} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Sorocred') {
      return (
        <Card>
          {' '}
          <img src={Sorocred} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Ticket') {
      return (
        <Card>
          {' '}
          <img src={Ticket} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }
    if (icon === 'Vr') {
      return (
        <Card>
          {' '}
          <img src={Vr} alt={icon} /> <span>{icon}</span>
        </Card>
      );
    }

    return (
      <Card>
        {' '}
        <img src={DefaultCard} alt={icon} /> <span>{icon}</span>
      </Card>
    );
  }

  return modalAbout ? (
    <Container>
      <ModalStyled
        isOpen={modalAbout}
        onRequestClose={() => handleCloseModal(false)}
        style={customStyles}
      >
        <Header color={color}>
          <IconArea>
            <IoIosArrowDown onClick={() => handleModalAbout(false)} />
          </IconArea>
          <Title>
            <span>Sobre a loja</span>
          </Title>
          <IconArea />
        </Header>
        <Body>
          {(storeInfo.razao_social || storeInfo.cnpj) && (
            <Box>
              <Subtitle>Informações</Subtitle>
              <Box>
                <label>{storeInfo.razao_social}</label>
                <label>CNPJ: {storeInfo.cnpj}</label>
              </Box>
            </Box>
          )}
          {storeInfo?.description && (
            <Box>
              <Subtitle>Descrição da loja</Subtitle>
              <Box>
                <span>{storeInfo?.description}</span>
              </Box>
            </Box>
          )}
          {(!sunday[0] || !sunday[0].start_hour) &&
          (!monday[0] || !monday[0].start_hour) &&
          (!tuesday[0] || !tuesday[0].start_hour) &&
          (!wednesday[0] || !wednesday[0].start_hour) &&
          (!thursday[0] || !thursday[0].start_hour) &&
          (!friday[0] || !friday[0].start_hour) &&
          (!saturday[0] || !saturday[0].start_hour) ? (
            <></>
          ) : (
            <Box>
              <Subtitle>Horários de funcionamento</Subtitle>
              <BoxWorkPeriod>
                <WorkPeriodArea>
                  <WeekTitle> Domingo </WeekTitle>
                  {sunday[0] && sunday[0].start_hour ? (
                    <Row>
                      {sunday[0].start_hour} - {sunday[0].end_hour}
                    </Row>
                  ) : (
                    <Row>Fechado</Row>
                  )}
                  {sunday[1] && sunday[1].start_hour && (
                    <Row>
                      {sunday[1].start_hour} - {sunday[1].end_hour}
                    </Row>
                  )}
                </WorkPeriodArea>
                <WorkPeriodArea>
                  <WeekTitle> Segunda </WeekTitle>
                  {monday[1] && monday[1].start_hour ? (
                    <Row>
                      {monday[1].start_hour} - {monday[1].end_hour}
                    </Row>
                  ) : (
                    <Row>Fechado</Row>
                  )}
                  {monday[0] && monday[0].start_hour && (
                    <Row>
                      {monday[0].start_hour} - {monday[0].end_hour}
                    </Row>
                  )}
                </WorkPeriodArea>
                <WorkPeriodArea>
                  <WeekTitle> Terça </WeekTitle>
                  {tuesday[1] && tuesday[1].start_hour ? (
                    <Row>
                      {' '}
                      {tuesday[1].start_hour} - {tuesday[1].end_hour}
                    </Row>
                  ) : (
                    <Row>Fechado</Row>
                  )}
                  {tuesday[0] && tuesday[0].start_hour && (
                    <Row>
                      {tuesday[0].start_hour} - {tuesday[0].end_hour}
                    </Row>
                  )}
                </WorkPeriodArea>
                <WorkPeriodArea>
                  <WeekTitle> Quarta </WeekTitle>
                  {wednesday[1] && wednesday[1].start_hour ? (
                    <Row>
                      {wednesday[1].start_hour} - {wednesday[1].end_hour}
                    </Row>
                  ) : (
                    <Row>Fechado</Row>
                  )}
                  {wednesday[0] && wednesday[0].start_hour && (
                    <Row>
                      {wednesday[0].start_hour} - {wednesday[0].end_hour}
                    </Row>
                  )}
                </WorkPeriodArea>
                <WorkPeriodArea>
                  <WeekTitle> Quinta </WeekTitle>
                  {thursday[1] && thursday[1].start_hour ? (
                    <Row>
                      {thursday[1].start_hour} - {thursday[1].end_hour}
                    </Row>
                  ) : (
                    <Row>Fechado</Row>
                  )}
                  {thursday[0] && thursday[0].start_hour && (
                    <Row>
                      {thursday[0].start_hour} - {thursday[0].end_hour}
                    </Row>
                  )}
                </WorkPeriodArea>
                <WorkPeriodArea>
                  <WeekTitle> Sexta </WeekTitle>
                  {friday[1] && friday[1].start_hour ? (
                    <Row>
                      {friday[1].start_hour} - {friday[1].end_hour}
                    </Row>
                  ) : (
                    <Row>Fechado</Row>
                  )}
                  {friday[0] && friday[0].start_hour && (
                    <Row>
                      {friday[0].start_hour} - {friday[0].end_hour}
                    </Row>
                  )}
                </WorkPeriodArea>
                <WorkPeriodArea>
                  <WeekTitle> Sábado </WeekTitle>
                  {saturday[1] && saturday[1].start_hour ? (
                    <Row>
                      {saturday[1].start_hour} - {saturday[1].end_hour}
                    </Row>
                  ) : (
                    <Row>Fechado</Row>
                  )}
                  {saturday[0] && saturday[0].start_hour && (
                    <Row>
                      {saturday[0].start_hour} - {saturday[0].end_hour}
                    </Row>
                  )}
                </WorkPeriodArea>
              </BoxWorkPeriod>
            </Box>
          )}
          {storeInfo.show_address && (
            <Box>
              <Subtitle>Endereço</Subtitle>
              <Box>
                <span>
                  {storeInfo.street},<> Nº </>
                  {storeInfo.number},<> </>
                  {storeInfo.city}
                  <> - </>
                  {storeInfo.state}
                </span>
                <span>
                  <> CEP - </>
                  {storeInfo.zip_code}.
                </span>
              </Box>
            </Box>
          )}
          {deliveryPaymentsMethods && (
            <Box>
              <Subtitle>Formas de pagamento na entrega</Subtitle>
              <DeliveryPaymentMethods>
                {deliveryPaymentsMethods?.money && (
                  <CardsArea>
                    <Name>Dinheiro</Name>
                    <img src={Money} alt="money" />
                  </CardsArea>
                )}
                {deliveryPaymentsMethods?.credit && (
                  <CardsArea>
                    <Name>Cartão de crédito</Name>
                    <Cards>
                      {creditCards?.length > 0 &&
                        creditCards.map((creditCard) => (
                          <Card key={creditCard.name}>
                            {handleIcon(creditCard.name)}
                          </Card>
                        ))}
                    </Cards>
                  </CardsArea>
                )}
                {deliveryPaymentsMethods?.debit && (
                  <CardsArea>
                    <Name>Cartão de débito</Name>
                    <Cards>
                      {debitCards?.length > 0 &&
                        debitCards.map((debitCard) => (
                          <Card key={debitCard.name}>
                            {handleIcon(debitCard.name)}
                          </Card>
                        ))}
                    </Cards>
                  </CardsArea>
                )}
                {deliveryPaymentsMethods?.voucher && (
                  <CardsArea>
                    <Name>Voucher</Name>
                    <Cards>
                      {voucherCards?.length > 0 &&
                        voucherCards.map((voucherCard) => (
                          <Card key={voucherCard.name}>
                            {handleIcon(voucherCard.name)}
                          </Card>
                        ))}
                    </Cards>
                  </CardsArea>
                )}
              </DeliveryPaymentMethods>
            </Box>
          )}
          {(storeInfo?.settings?.facebook ||
            storeInfo?.settings?.instagram ||
            storeInfo?.settings?.youtube ||
            storeInfo?.settings?.twitter ||
            storeInfo?.settings?.website) && (
            <Box>
              <Subtitle>Redes sociais</Subtitle>
              <SocialMedia>
                {storeInfo?.settings?.facebook && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.facebook}
                  >
                    <Facebook />
                  </a>
                )}
                {storeInfo?.settings?.instagram && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.instagram}
                  >
                    <Instagram />
                  </a>
                )}
                {storeInfo?.settings?.youtube && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.youtube}
                  >
                    <Youtube />
                  </a>
                )}
                {storeInfo?.settings?.twitter && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.twitter}
                  >
                    <Twitter />
                  </a>
                )}
                {storeInfo?.settings?.website && (
                  <a
                    href={
                      storeInfo?.settings?.website.startsWith('http')
                        ? storeInfo?.settings?.website
                        : `//${storeInfo?.settings?.website}`
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Website />
                  </a>
                )}
              </SocialMedia>
            </Box>
          )}
        </Body>
      </ModalStyled>
    </Container>
  ) : (
    <></>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...StoreActions,
      ...MainActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  storeInfo: state.store.storeInfo,
  modalAbout: state.main.modalAbout,
  // eslint-disable-next-line
  deliveryPaymentsMethods: state.store?.storeInfo?.payment_methods_on_delivery,
  deliveryPaymentsCards:
    // eslint-disable-next-line
    state.store?.storeInfo?.payment_methods_on_delivery?.cards,
  color: state.store.storeInfo.settings.color_primary,
});
export default connect(mapStateToProps, mapDispatchToProps)(About);
