import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import swal from 'sweetalert';

import { IoIosAddCircleOutline, IoIosArrowDown } from 'react-icons/io';
import { BsTrash, BsThreeDotsVertical } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { FcCheckmark } from 'react-icons/fc';

import SubmitAddress from './SubmitAddress';
import EditAddress from './EditAddress';

import * as DeliveryActions from '../../../../../../store/modules/delivery/actions';
import * as MainActions from '../../../../../../store/modules/main/actions';

import {
  Header,
  Space,
  Title,
  CurrentAddressArea,
  IconArea,
  CurrentAddress,
  WithouAddress,
  Addresses,
  CardAddress,
  CardEdit,
  Edit,
  DeleteArea,
  EditArea,
  ButtonsArea,
  Button,
} from './styles';

function UserAddresses({
  currentAddress,
  addresses,
  handleCurrentAddress,
  setZipCode,
  submitAddress,
  setSubmitAddress,
  deleteCustomerAddress,
  handleEditAddress,
  editAddress,
  handleMenuAddress,
  color,
}) {
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setSubmitAddress(false);
    handleEditAddress(false);
  }, []);

  function handleSubmitAddress() {
    setSubmitAddress(true);
    setZipCode('');
  }

  function handleDelete(addressId) {
    swal({
      title: 'Deseja excluir esse endereço?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteCustomerAddress(addressId);
      }
    });
  }

  function handleEdit() {
    handleEditAddress(true);
  }

  return editAddress ? (
    <EditAddress />
  ) : (
    <>
      {submitAddress ? (
        <SubmitAddress />
      ) : (
        <>
          <Header color={color}>
            <IconArea onClick={() => handleMenuAddress(false)}>
              <IoIosArrowDown />
            </IconArea>
            <Title>
              <span>Endereços de entrega</span>
            </Title>

            <Space />
          </Header>
          <CurrentAddressArea>
            {
              // eslint-disable-next-line
            currentAddress?.zip_code ? (
                <CurrentAddress>
                  {currentAddress.name && (
                    <span>Nome: {currentAddress.name}</span>
                  )}
                  <span>CEP: {currentAddress.zip_code}</span>
                  <span>
                    Rua: {currentAddress.street}, {currentAddress.number}
                  </span>
                  <span>
                    Cidade: {currentAddress.city}, {currentAddress.state}
                  </span>
                </CurrentAddress>
              ) : (
                <>
                  <WithouAddress>
                    <span>Nenhum endereço disponível.</span>
                  </WithouAddress>
                </>
              )
            }
          </CurrentAddressArea>
          <ButtonsArea>
            <Button color={color} onClick={() => handleSubmitAddress()}>
              <span>
                <strong>Cadastrar endereço </strong>
              </span>
              <IoIosAddCircleOutline size={26} />
            </Button>
            <Button color={color} onClick={() => handleMenuAddress(false)}>
              <span>Confirmar endereço</span>
              <FcCheckmark size={26} />
            </Button>
          </ButtonsArea>
          <Addresses>
            {addresses &&
              addresses.length > 0 &&
              addresses.map((addre) => {
                return (
                  <CurrentAddressArea key={addre && addre.id}>
                    <CardAddress
                      edit={edit && addre && addre === currentAddress}
                      selected={addre === currentAddress}
                      onClick={() => handleCurrentAddress(addre)}
                    >
                      <span>
                        {addre.name ? `${addre.name} -` : ''}{' '}
                        {addre.neighborhood}, {addre.number}
                      </span>
                      <Edit
                        selected={addre === currentAddress}
                        onClick={() =>
                          setEdit(!edit && addre === currentAddress)
                        }
                        edit={edit && addre === currentAddress}
                      >
                        {edit && addre === currentAddress ? (
                          <AiOutlineClose size={20} />
                        ) : (
                          <BsThreeDotsVertical size={20} />
                        )}
                      </Edit>
                    </CardAddress>
                    <CardEdit edit={edit && addre === currentAddress}>
                      <DeleteArea onClick={() => handleDelete(addre.id)}>
                        <BsTrash size={20} />
                      </DeleteArea>
                      <EditArea onClick={() => handleEdit(addre)}>
                        <FaRegEdit size={20} />
                      </EditArea>
                    </CardEdit>
                  </CurrentAddressArea>
                );
              })}
          </Addresses>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...DeliveryActions, ...MainActions }, dispatch);

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
  currentAddress: state.delivery.currentAddress,
  addresses: state.delivery.addresses,
  submitAddress: state.delivery.submitAddress,
  editAddress: state.delivery.editAddress,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAddresses);
