import styled from 'styled-components';

export const BannerArea = styled.div`
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  transition: margin-top 0.6s, width 0.6s;
  margin-top: ${(props) => (props.navigation ? '55px' : '0')};

  @media (max-width: 1150px) {
    width: 100%;
    margin-top: ${(props) => {
      if (
        props.headerVisible &&
        props.navigation &&
        props.restrictDeliveryArea
      ) {
        return '135px';
      }
      if (props.headerVisible && props.navigation) {
        return '105px';
      }
      if (props.headerVisible && props.restrictDeliveryArea) {
        return '80px';
      }
      if (props.headerVisible) {
        return '50px';
      }
      if (props.navigation && props.restrictDeliveryArea) {
        return '85px';
      }
      if (props.navigation) {
        return '55px';
      }
      if (props.restrictDeliveryArea) {
        return '30px';
      }
      return '0';
    }};
  }
`;

export const BannerImage = styled.div`
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;
  background-position: center center;

  @media (min-width: 768px) {
    width: 100%;
    height: 380px;
  }

  @media (max-width: 767px) {
    height: 145px;
    width: 100%;
    background-image: ${(props) => `url(${props.mobile})`};
  }
`;

export const ImageDefault = styled.div`
  display: flex;
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  height: 350px;
  z-index: -1;
  background: ${(props) => props.color};

  @media (max-width: 500px) {
    height: 200px;
  }
`;
