import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';
import { darken } from 'polished';

const animation = keyframes`
 from {
    margin-top: 50%;
		margin-bottom: -300px;
		opacity: 0
	}
	to {
    margin-top: 0px;
		margin-bottom: 0px;
		opacity: 1
	}

`;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 600px;
  background: #fff;
  border-radius: 10px;
  transition: top 0.2s;

  @media (max-width: 1150px) {
    transform: translate(-50%, -40%) !important;
    height: 100%;
    width: 100%;
    border-radius: 0;

    animation-name: ${animation};
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-play-state: running;
  }
`;

export const Container = styled.div``;

export const Headers = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: none;
  width: 100%;
  padding: 10px;
  max-height: 50px;
  color: ${(props) => props.theme.colors.grayDark};

  svg {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  @media (max-width: 1150px) {
    display: flex;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;
export const Title = styled(Icon)`
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
`;
export const Space = styled(Title)``;

export const WindowLogin = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 600px;
  background: #fff;
  border-radius: 10px;

  @media (max-width: 1150px) {
    height: 100%;
    width: 100%;
    border-radius: 0;

    animation-name: ${animation};
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-play-state: running;
  }
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  border-radius: 10px 10px 0 0;

  div {
    border-top-left-radius: 10px;
    border-top-right-radius: 0;

    & + div {
      border-top-left-radius: 0;
      border-top-right-radius: 10px;
    }
  }

  @media (max-width: 1150px) {
    height: 50px;

    div {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      & + div {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
`;

export const Tab = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  text-align: center;
  background: ${(props) =>
    !props.registerVisible ? props.color : darken(0.1, props.color)};
  color: #fff;
  font-weight: bold;
  cursor: pointer;
`;

export const WindowRecoveryPassword = styled(WindowLogin)``;
