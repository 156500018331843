import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  AiOutlineExclamationCircle,
  AiOutlineCheckCircle,
  AiOutlineCloseSquare,
  AiOutlineCloseCircle,
} from 'react-icons/ai';

import { formatPrice } from '../../../../util/format';

import * as CartActions from '../../../../store/modules/cart/actions';

import {
  Container,
  Modal,
  TitleIcon,
  Space,
  Icon,
  Close,
  Title,
  Header,
  HeaderProduct,
  HeaderObservation,
  HeaderOptions,
  Products,
  Product,
  Name,
  Observation,
  Options,
  ButtonsArea,
  ButtonDelete,
  ButtonAccept,
} from './styles';

function Divergents({
  handleModalDivergents,
  divergentProducts,
  removeFromCart,
  removeDivergent,
  updateCartProductPrices,
  priceDecimals,
}) {
  useEffect(() => {
    if (divergentProducts?.length <= 0) {
      handleModalDivergents(false);
    }
  }, [divergentProducts]);

  function handleRemoveProduct(productId) {
    removeFromCart(productId);
    removeDivergent(productId);
  }

  function handleUpdateProductCart(product) {
    removeDivergent(product?.id);
    updateCartProductPrices(product?.id, product.prices);
  }

  function handleRemoveAll() {
    if (divergentProducts?.length > 0) {
      divergentProducts.map((product) => handleRemoveProduct(product?.old?.id));
    }
  }

  function handleAcceptAll() {
    if (divergentProducts?.length > 0) {
      divergentProducts.map((product) => {
        if (product.old.errorCode === 404) {
          return handleRemoveProduct(product.old.id);
        }
        return handleUpdateProductCart(product.new);
      });
    }
  }

  function handleCloseModal(event) {
    if (event.currentTarget === event.target) {
      handleModalDivergents(false);
    }
  }

  return (
    <Container onClick={(e) => handleCloseModal(e)}>
      <Modal>
        <TitleIcon>
          <Space />
          <Icon>
            <AiOutlineExclamationCircle />
          </Icon>
          <Close>
            <AiOutlineCloseCircle
              onClick={() => handleModalDivergents(false)}
            />
          </Close>
        </TitleIcon>
        <Title>
          <span>Alguns produtos foram encontrados com divergência.</span>
          <span className="second">
            É necessário atualizar ou remover os itens do carrinho.
          </span>
        </Title>
        <Products>
          <Header>
            <HeaderProduct>Produto</HeaderProduct>
            <HeaderObservation>Observação</HeaderObservation>
            <HeaderOptions>Opções</HeaderOptions>
          </Header>
          {divergentProducts &&
            divergentProducts.length > 0 &&
            divergentProducts.map((divergentProduct) => {
              return (
                <Product key={divergentProduct?.id}>
                  <Name>{divergentProduct.old.name}</Name>
                  {divergentProduct.old.errorCode === 404 && (
                    <Observation>
                      <span>
                        Produto <strong>{divergentProduct.old.name}</strong> não
                        está mais disponível.
                      </span>
                    </Observation>
                  )}

                  {divergentProduct.old.errorCode === 400 && (
                    <Observation>
                      <span>
                        Preço atualizado, o valor foi alterador de{' '}
                        <strong>
                          {formatPrice(
                            divergentProduct.old.value_unitary,
                            priceDecimals
                          )}{' '}
                        </strong>
                        para{' '}
                        <strong>
                          {formatPrice(
                            divergentProduct.new.prices[0].promotional_price ||
                              divergentProduct.new.prices[0].regular_price,
                            priceDecimals
                          )}
                        </strong>
                        .
                      </span>
                      {divergentProduct.old.error}
                    </Observation>
                  )}
                  {divergentProduct.old.errorCode === 409 && (
                    <Observation>
                      <span>
                        Houve uma divergência de valores, o item precisa ser
                        atualizado no carrinho.
                      </span>
                      {divergentProduct.old.error}
                    </Observation>
                  )}
                  <Options>
                    <AiOutlineCloseSquare
                      onClick={() =>
                        handleRemoveProduct(divergentProduct?.old?.id)
                      }
                    />
                    <AiOutlineCheckCircle
                      onClick={() =>
                        handleUpdateProductCart(divergentProduct.new)
                      }
                    />
                  </Options>
                </Product>
              );
            })}
        </Products>
        <ButtonsArea>
          <ButtonDelete onClick={() => handleRemoveAll()}>
            <span>Remover todos</span>
          </ButtonDelete>
          <ButtonAccept onClick={() => handleAcceptAll()}>
            <span>Atualizar todos</span>
          </ButtonAccept>
        </ButtonsArea>
      </Modal>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...CartActions }, dispatch);

const mapStateToProps = (state) => ({
  modalDivergents: state.cart.modalDivergents,
  divergentProducts: state.cart.divergentProducts,
  priceDecimals: state.store.storeInfo.settings.price_decimals,
});
export default connect(mapStateToProps, mapDispatchToProps)(Divergents);
