import styled, { keyframes } from 'styled-components';

import Modal from 'react-modal';

const animation = keyframes`
  from {
    top: -10px;
    opacity: 0;
  }

  to {
    top: 50%;
    opacity: 1;
  }
`;

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  height: auto;
  width: 600px;
  background: #fff;
  border-radius: 10px;
  transition: top 0.2s;

  @media (max-width: 576px) {
    height: 100%;
    width: 100%;
    min-height: 100%;
    border-radius: 0;
    overflow-y: auto;
    position: relative;

    animation: ${animation} 0.2s;
  }
`;

export const CardArea = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  animation: ${animation} 0.6s;
  position: relative;
`;

export const Card = styled.div`
  width: 100%;
  height: 50px;
  box-shadow: ${(props) =>
    props.selected ? ' 3px 6px 9px #00000029' : ' 0px 3px 6px #00000029'};
  transition: box-shadow 0.6s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${(props) => (props.selected ? '#eee' : '#fff')};
  color: #727c8e;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  transition: background 0.6s, box-shadow 0.1s;

  &:hover {
    box-shadow: 5px 6px 9px #00000029;
  }

  svg {
    margin-left: 20px;
  }

  @media (max-width: 576px) {
    border-radius: 0px;
  }
`;
