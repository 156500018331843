import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import { FaTruckLoading } from 'react-icons/fa';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import { toast } from 'react-toastify';

import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import correiosLogo from '../../../../assets/icons/correios-logo.svg';
import deliveryTruck from '../../../../assets/icons/delivery-truck.svg';

import { formatPrice } from '../../../../util/format';

import LoadingFreight from '../LoadingFreight';

import {
  Container,
  DeliveryTypes,
  Type,
  Correios,
  AddressesArea,
  Addresses,
  AddressArea,
  CheckBoxArea,
  AddressInfo,
  WithouAddress,
  ButtonNewAddressArea,
  ButtonNewAddress,
  CardArea,
  Method,
  Freights,
  HeaderFreight,
  Freight,
  Name,
  Address,
  Value,
  OnwDelivery,
} from './styles';
import { SubTitle, Sub } from '../../styles';

function Delivery({
  storePickUp,
  storeCorreios,
  storeOnwDelivery,
  setPickUp,
  addresses,
  handleModalAddress,
  setOnwDelivery,
  addressSelected,
  setAddressSelected,
  cart,
  getFreights,
  setFreights,
  pickUp,
  color,
  onwDelivery,
  loadingFreight,
  freights,
  setCurrentFreight,
  verifyService,
  currentFreight,
  setDeliveryTaxValue,
  correios,
  setCorreios,
  setCurrentAddress,
}) {
  const [deliveryType, setDeliveryType] = useState(
    !storeOnwDelivery && !storePickUp
  );

  function handleCurrentFreight(freigth) {
    setCurrentFreight(freigth);

    setDeliveryTaxValue(parseFloat(freigth.price));
  }

  function handlePickUp() {
    setPickUp(true);
    setDeliveryType(false);
  }
  function handleDeliveryType() {
    setPickUp(false);
    setDeliveryType(true);
  }

  function handleCorreios() {
    if (!addresses || addresses.length <= 0) {
      return handleModalAddress(true);
    }
    setCorreios(true);
    return setOnwDelivery(false);
  }
  function handleOnwDelivery() {
    setCorreios(false);
    setOnwDelivery(true);
  }

  function handleSelectAddress(address) {
    setCurrentAddress(address);
    if (address === addressSelected) {
      return setAddressSelected({});
    }

    const weight = cart.reduce((totalItem, item) => {
      totalItem += parseFloat(item.weight) * item.amount;
      return totalItem;
    }, 0);

    let height = cart.reduce((totalItem, item) => {
      totalItem += parseFloat(item.height) * item.amount;
      return totalItem;
    }, 0);
    let width = cart.reduce((totalItem, item) => {
      if (parseFloat(item.width) > totalItem) {
        totalItem = parseFloat(item.width);
      }
      return totalItem;
    }, 0);
    let length = cart.reduce((totalItem, item) => {
      if (parseFloat(item.length) > totalItem) {
        totalItem = parseFloat(item.length);
      }
      return totalItem;
    }, 0);

    if (weight > 50) {
      return toast.error(
        'O peso excedeu o quantidade permitida, por favor entre em contato com a loja.'
      );
    }

    if (width + height + length > 200) {
      return toast.error(
        'O tamanho excedeu o quantidade permitida, por favor entre em contato com a loja.'
      );
    }

    if (height < 1) {
      height = 1;
    }
    if (width < 10) {
      width = 10;
    }
    if (length < 15) {
      length = 15;
    }

    getFreights({
      destiny: address.zip_code,
      weight: JSON.stringify(weight),
      height,
      width,
      length,
    });
    return setAddressSelected(address);
  }
  function verifyDay(day) {
    const startDate = new Date();
    const date = moment(startDate).add(day, 'days');
    const after = moment(date).add(3, 'days');

    return `${moment(date).format('DD')} e ${moment(after).format('LL')}`;
  }

  function handleGoBackAddresses() {
    setFreights([]);
    setAddressSelected(null);
    setCurrentFreight(null);
  }
  return (
    <Container>
      <SubTitle>
        <Sub>
          <FaTruckLoading size={16} />
          <span>Formas de entrega</span>
        </Sub>
      </SubTitle>
      <CardArea>
        {storePickUp && (
          <Method current={pickUp} onClick={() => handlePickUp()} color={color}>
            <span>Retirar na loja</span>
          </Method>
        )}
        {storeCorreios && (
          <Method
            storePickUp={storePickUp}
            current={deliveryType}
            onClick={() => handleDeliveryType()}
            color={color}
          >
            <span>Entregas</span>
          </Method>
        )}
      </CardArea>
      {deliveryType && (
        <DeliveryTypes>
          {!correios && !onwDelivery && storeOnwDelivery && (
            <>
              <Type onClick={() => handleCorreios()} color={color}>
                <img src={correiosLogo} alt={correiosLogo} />
                <span>Correios</span>
              </Type>
              <Type onClick={() => handleOnwDelivery()} color={color}>
                <img src={deliveryTruck} alt={deliveryTruck} />
                <span>Entrega própria da loja</span>
              </Type>
            </>
          )}
          {correios && (
            <Correios>
              {loadingFreight ? (
                <LoadingFreight />
              ) : (
                <AddressesArea>
                  {freights?.length <= 0 ? (
                    <>
                      {storeOnwDelivery && (
                        <AiOutlineArrowLeft
                          onClick={() => setCorreios(false)}
                        />
                      )}
                      <Addresses>
                        {addresses?.length > 0 ? (
                          addresses.map((address) => (
                            <AddressArea
                              onClick={() => handleSelectAddress(address)}
                              key={address?.id}
                            >
                              <CheckBoxArea>
                                <input
                                  checked={address === addressSelected}
                                  type="checkbox"
                                  onChange={() => handleSelectAddress(address)}
                                />
                              </CheckBoxArea>
                              <AddressInfo>
                                <span>{address.name}</span>
                                <span>{address.zip_code}</span>
                                <span>
                                  {address.street}, {address.number}
                                </span>
                                <span>
                                  {address.city}, {address.state}
                                </span>
                                {address.complement && (
                                  <span>{address.complement}</span>
                                )}
                              </AddressInfo>
                            </AddressArea>
                          ))
                        ) : (
                          <WithouAddress>
                            <span>Nenhum endereço cadastrado</span>
                          </WithouAddress>
                        )}
                      </Addresses>
                    </>
                  ) : (
                    <Freights>
                      <HeaderFreight>
                        <div>
                          <AiOutlineArrowLeft
                            onClick={() => handleGoBackAddresses()}
                          />
                        </div>
                        <div>
                          {addressSelected.zip_code && (
                            <span>
                              {addressSelected.zip_code} -{' '}
                              {addressSelected.street}, {addressSelected.number}
                            </span>
                          )}
                        </div>
                        <div />
                      </HeaderFreight>
                      {freights.map((freight) => (
                        <Freight
                          key={freight.code}
                          onClick={() => handleCurrentFreight(freight)}
                        >
                          <Name>{verifyService(freight.code)}</Name>
                          <Address>
                            <span className="nameMobile">
                              {verifyService(freight.code)}
                            </span>
                            <div className="date">
                              receba entrega
                              <strong>{verifyDay(freight.deadline)}</strong>
                            </div>
                          </Address>
                          <Value>
                            {formatPrice(parseFloat(freight.price), 2)}
                            <input
                              checked={freight === currentFreight}
                              type="checkbox"
                            />
                          </Value>
                        </Freight>
                      ))}
                    </Freights>
                  )}
                  <WithouAddress>
                    <ButtonNewAddressArea>
                      <ButtonNewAddress
                        color={color}
                        onClick={() => handleModalAddress(true)}
                      >
                        Cadastrar endereço
                      </ButtonNewAddress>
                    </ButtonNewAddressArea>
                  </WithouAddress>
                </AddressesArea>
              )}
            </Correios>
          )}
          {onwDelivery && <OnwDelivery>Entrega própria da loja.</OnwDelivery>}
        </DeliveryTypes>
      )}
    </Container>
  );
}
function getValueUnitary(product) {
  if (product?.prices?.length > 0) {
    return product.prices[0].promotional_price > 0
      ? product.prices[0].promotional_price
      : product.prices[0].regular_price;
  }

  return 0;
}

function getValueTotal(product) {
  if (product?.prices?.length > 0) {
    return product.prices[0].promotional_price > 0
      ? product.prices[0].promotional_price * product.amount
      : product.prices[0].regular_price * product.amount;
  }
  return 0;
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...DeliveryActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  // eslint-disable-next-line
  storePickUp: state.store.storeInfo?.settings?.delivery_ways?.pick_up,
  // eslint-disable-next-line
  storeCorreios: state.store.storeInfo?.settings?.delivery_ways?.correios,
  // eslint-disable-next-line
  storeOnwDelivery: state.store.storeInfo?.settings?.delivery_ways?.onw_delivery,
  addresses: state.delivery.addresses,
  cart: state?.cart?.products.map((product) => ({
    ...product,
    value_unitary: getValueUnitary(product),
    value_total: getValueTotal(product),
  })),
  color:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.color_primary,
  loadingFreight: state.delivery.loadingFreight,
  freights: state.delivery?.freights?.filter(
    (freight) => freight.error === '0'
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
