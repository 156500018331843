import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const Loading = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  svg {
    animation: ${rotate} 1s infinite;
  }
`;

export const Title = styled.div`
  margin-top: 80px;
  font-weight: bold;
  font-size: 30px;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 0 0 10px 0;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
`;

export const Sub = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const HeaderFreight = styled.div`
  display: flex;
  padding: 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;

  div {
    flex: 0.5;

    &:nth-child(2) {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ZipCodeArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const ZipCode = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export const Body = styled.div`
  display: flex;
  width: 70%;
  padding: 20px 20px 0 0;
  flex-direction: column;
  overflow-y: hidden;

  @media (max-width: 992px) {
    padding: 20px 0 0 0;
    width: 100%;
  }
`;

export const Confirmation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 5px #00000050;
  cursor: pointer;
  border-radius: 5px;
  align-self: center;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding: 20px;
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 20px;
  margin-bottom: 50px;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.color};
  transition: all 0.6s;

  &:hover {
    box-shadow: 2px 5px 8px #00000050;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const ConfirmationBottom = styled(Confirmation)`
  display: none;
  background: ${(props) => props.color};

  @media (max-width: 992px) {
    display: flex;
  }
`;
