import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TiLocationOutline } from 'react-icons/ti';
import { IoIosArrowDown } from 'react-icons/io';

import * as GroupsActions from '../../../../store/modules/groups/actions';
import * as MainActions from '../../../../store/modules/main/actions';
import * as DeliveryActions from '../../../../store/modules/delivery/actions';

import { Container } from './styles';

function DeliveryHeader({
  headerVisible,
  navigation,
  signed,
  currentAddress,
  handleMenuAddress,
  restrictDeliveryArea,
}) {
  return restrictDeliveryArea ? (
    <Container
      onClick={() => handleMenuAddress(true)}
      headerVisible={headerVisible}
      navigation={navigation.length > 0}
    >
      {signed && currentAddress ? (
        // <AddressComplet>
        <>
          <TiLocationOutline />
          <span>
            {currentAddress.street}, {currentAddress.number}
          </span>
          <IoIosArrowDown />
        </>
      ) : (
        // </AddressComplet>
        <>
          <TiLocationOutline />
          <span>Insira a sua localização.</span>
        </>
      )}
    </Container>
  ) : (
    <></>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...MainActions, ...GroupsActions, ...DeliveryActions },
    dispatch
  );
const mapStateToProps = (state) => ({
  signed: state.auth.signed,
  headerVisible: state.main.headerVisible,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
  address: state.delivery.address,
  currentAddress: state.delivery.currentAddress,
  navigation: state.groups.navigation,
});
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryHeader);
