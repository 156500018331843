import React, { useState, useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FiLoader } from 'react-icons/fi';

import * as UserActions from '../../../../../../store/modules/user/actions';

import {
  ModalStyled,
  Header,
  GoBack,
  Title,
  Space,
  Body,
  InputArea,
  ButtonArea,
  Button,
  Loading,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function RecoveryPassword({
  handleRecoveryPassword,
  color,
  requestRecoveryPassword,
  loadingSendEmail,
  recoveryPassword,
}) {
  const [email, setEmail] = useState('');
  const inputRef = useRef(null);
  function handleSendEmail(e) {
    e.preventDefault();
    requestRecoveryPassword(email);
  }

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <ModalStyled
      isOpen={recoveryPassword}
      onRequestClose={handleRecoveryPassword}
      style={customStyles}
    >
      <Header color={color}>
        <GoBack onClick={() => handleRecoveryPassword(false)}>
          <AiOutlineArrowLeft />
        </GoBack>
        <Title>Recuperar senha</Title>
        <Space />
      </Header>
      <Body onSubmit={(e) => handleSendEmail(e)}>
        <span>Para que possamos recuperar a sua senha,</span>
        <span>digite seu e-mail no campo abaixo.</span>
        <InputArea>
          <input
            ref={inputRef}
            type="email"
            required
            onChange={(text) => setEmail(text.target.value)}
          />
        </InputArea>
        <ButtonArea>
          <Button
            disable={!!loadingSendEmail}
            disabled={!!loadingSendEmail}
            type="submit"
            color={color}
          >
            {loadingSendEmail ? (
              <Loading>
                <FiLoader size={24} />
              </Loading>
            ) : (
              'Recuperar'
            )}
          </Button>
        </ButtonArea>
      </Body>
    </ModalStyled>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...UserActions }, dispatch);

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
  recoveryPassword: state.user.recoveryPassword,
  loadingSendEmail: state.user.loadingSendEmail,
});
export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
