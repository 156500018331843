import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FiPlus, FiMinus, FiEdit } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';
import { BsTrash } from 'react-icons/bs';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

import history from '../../../../services/history';
import { formatPrice } from '../../../../util/format';
import { getStoreSlug } from '../../../../util/StoreUtil';

import * as MainActions from '../../../../store/modules/main/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import * as GroupsActions from '../../../../store/modules/groups/actions';
import * as AuthActions from '../../../../store/modules/auth/actions';

import * as DeliveryActions from '../../../../store/modules/delivery/actions';
import defaultImage from '../../../../assets/images/default.jpeg';

import {
  CartContainer,
  Header,
  Icon,
  TitleMobile,
  Space,
  CartDescription,
  CardTitle,
  EmptyCart,
  Items,
  Item,
  ItemArea,
  IconsArea,
  DeleteAra,
  EditArea,
  ItemImage,
  ItemBody,
  Name,
  ItemQuantity,
  ItemAmount,
  Qtd,
  ButtonMinus,
  ButtonPlus,
  Buy,
  DeliveryTax,
  TextDeliveryTax,
  ValueDeliveryTax,
  Subtotal,
  TextSubTotal,
  ValueSubtotal,
  Total,
  LabelTotal,
  ValueTotal,
  ButtonArea,
  FinishButton,
} from './styles';

function Cart({
  cartVisible,
  cart,
  color,
  removeFromCart,
  updateAmount,
  total,
  totalFloat,
  orderMinimunValue,
  signed,
  user,
  cleanCart,
  handleCart,
  deliveryTax,
  totalWithTax,
  restrictDeliveryArea,
  currentAddress,
  handleMenuAddress,
  handleProductDetail,
  setCurrentProduct,
  priceDecimals,
  productDetail,
  handleLoginModal,
}) {
  const [taxVisible, setTaxVisible] = useState(false);

  useEffect(() => {
    if (restrictDeliveryArea) {
      setTaxVisible(true);
    } else {
      setTaxVisible(false);
    }
  }, []);

  function increment(product) {
    if (product.amount % product.multiple === 0) {
      return updateAmount(
        product.id,
        product.amount + parseFloat(product.multiple)
      );
    }
    return updateAmount(
      product.id,
      Math.ceil(product.amount / product.multiple) * product.multiple
    );
  }
  function decrement(product) {
    if (product.amount % product.multiple === 0) {
      return updateAmount(
        product.id,
        product.amount - parseFloat(product.multiple)
      );
    }
    return updateAmount(
      product.id,
      Math.floor(product.amount / product.multiple) * product.multiple
    );
  }
  function handleQuantity(quantity, product) {
    if (quantity % parseInt(product.multiple, 10) !== 0) {
      toast.error(
        `Esse produto só é vendido em multiplo de ${product.multiple}.`
      );
    }

    updateAmount(product.id, (product.amount = quantity));
  }

  function clean() {
    swal({
      title: 'Deseja realmente limpar?',
      text: 'Ao limpar o carrinho todos os itens serão removidos.',
      icon: 'warning',
      buttons: ['Manter', 'Limpar'],
    }).then((confirmation) => {
      if (confirmation) {
        cleanCart();
        swal('Carrinho limpo com sucesso!', {
          icon: 'success',
        });
      }
    });
  }

  function handleFinishOrderConfirmation() {
    let hasError = false;
    cart.map((item) => {
      if (item.amount % parseInt(item.multiple, 10) !== 0) {
        toast.error(
          `O produto ${item.name} só é vendido em multiplo de ${item.multiple}.`
        );
        hasError = true;
        return false;
      }
      return true;
    });

    if (hasError) {
      return;
    }

    if (!signed || !user) {
      handleLoginModal();
      toast.error('Você precisa estar logado para finalizar o pedido.');
      return;
    }
    if (restrictDeliveryArea && !currentAddress) {
      toast.info('Selecione um endereço.');
      handleMenuAddress(true);
      return;
    }
    if (!cart || cart.length <= 0) {
      toast.error('Seu carrinho está vazio.');
      return;
    }

    if (totalFloat < parseFloat(orderMinimunValue)) {
      toast.error(
        `Seu pedido deve ter o valor mínimo de ${formatPrice(
          orderMinimunValue,
          2
        )}`
      );
      return;
    }

    history.push(`/${getStoreSlug()}/checkout`);
  }

  function handleEditProduct(product) {
    setCurrentProduct({ ...product, edit: true });
    if (productDetail) {
      handleProductDetail(false);
    }
    setTimeout(() => {
      handleProductDetail(true);
    }, 10);

    if (window.innerWidth <= 1150) handleCart(false);
  }

  function getPrice(item) {
    if (item.total > 0) {
      return item.total;
    }
    // eslint-disable-next-line
    if (item.prices[0]?.promotional_price > 0) {
      return item.prices[0].promotional_price * item.amount;
    }
    // eslint-disable-next-line
    return item.prices[0]?.regular_price * item.amount;
  }

  return (
    <CartContainer cartVisible={cartVisible}>
      <Header color={color}>
        <Icon>
          <IoIosArrowDown onClick={() => handleCart(false)} />
        </Icon>
        <TitleMobile>
          <span>Carrinho</span>
        </TitleMobile>
        <Space />
      </Header>

      {orderMinimunValue && orderMinimunValue > 0 && (
        <CartDescription cartVisible={cartVisible} color={color}>
          {` O pedido minimo é de ${formatPrice(
            orderMinimunValue,
            2
          )}, não incluindo a taxa de entrega.`}
        </CartDescription>
      )}
      <CardTitle>
        <div>
          <h4>Meu pedido</h4>
        </div>

        {cart.length > 0 && (
          <div>
            {' '}
            <h6
              onClick={() => {
                clean();
              }}
            >
              Limpar carrinho
            </h6>
          </div>
        )}
      </CardTitle>
      <Items
        minimumValue={orderMinimunValue && orderMinimunValue > 0}
        deliveryTax={deliveryTax}
      >
        {cart.map((item, index) => {
          return (
            // eslint-disable-next-line
            <Item key={index}>
              <ItemArea>
                <IconsArea>
                  <DeleteAra>
                    <BsTrash onClick={() => removeFromCart(item.id)} />
                  </DeleteAra>
                  <EditArea>
                    <FiEdit onClick={() => handleEditProduct(item)} />
                  </EditArea>
                </IconsArea>
                <ItemImage onClick={() => handleEditProduct(item)}>
                  <img
                    alt="Product"
                    src={
                      item.photos.length <= 0 || !item.photos[0].thumb_url
                        ? defaultImage
                        : item.photos[0].thumb_url
                    }
                    onError={(e) => {
                      e.target.src = `${defaultImage}`;
                    }}
                  />
                </ItemImage>
                <ItemBody>
                  <Name onClick={() => handleEditProduct(item)}>
                    {item.name}
                  </Name>
                </ItemBody>
              </ItemArea>
              <ItemArea>
                <ItemQuantity>
                  {
                    // eslint-disable-next-line
                  item?.option_group?.length > 0 ? (
                      <ItemAmount onClick={() => handleEditProduct(item)}>
                        <input disabled type="number" value={item.amount} />
                      </ItemAmount>
                    ) : (
                      <Qtd
                        multiple={
                          parseInt(item.amount, 10) %
                            parseInt(item.multiple, 10) ===
                          0
                        }
                      >
                        <ButtonMinus
                          onClick={() => {
                            decrement(item);
                          }}
                        >
                          <FiMinus />
                        </ButtonMinus>
                        <input
                          type="number"
                          onChange={(quantity) =>
                            handleQuantity(quantity.target.value, item)
                          }
                          value={item.amount}
                        />

                        <ButtonPlus onClick={() => increment(item)}>
                          <FiPlus />
                        </ButtonPlus>
                      </Qtd>
                    )
                  }
                  <span>{formatPrice(getPrice(item), priceDecimals)}</span>
                </ItemQuantity>
              </ItemArea>
            </Item>
          );
        })}
        {!cart ||
          (cart.length <= 0 && <EmptyCart>Seu pedido está vazio.</EmptyCart>)}
      </Items>
      <Buy deliveryTax={taxVisible && deliveryTax}>
        {taxVisible && deliveryTax && (
          <>
            <Subtotal>
              <TextSubTotal>
                <spa>Subtotal:</spa>
              </TextSubTotal>
              <ValueSubtotal>
                <spa>{total}</spa>
              </ValueSubtotal>
            </Subtotal>
            <DeliveryTax>
              <TextDeliveryTax>
                <spa>Taxa de entrega:</spa>
              </TextDeliveryTax>
              <ValueDeliveryTax>
                <spa>{formatPrice(deliveryTax, 2)}</spa>
              </ValueDeliveryTax>
            </DeliveryTax>
          </>
        )}
        <Total>
          <LabelTotal>
            <span>Total:</span>
          </LabelTotal>
          <ValueTotal>
            {taxVisible && deliveryTax ? (
              <span>{formatPrice(totalWithTax, 2)}</span>
            ) : (
              <span>{total}</span>
            )}
          </ValueTotal>
        </Total>
        <ButtonArea>
          <FinishButton
            color={color}
            onClick={() => {
              handleFinishOrderConfirmation();
            }}
          >
            FINALIZAR
          </FinishButton>
        </ButtonArea>
      </Buy>
    </CartContainer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...CartActions,
      ...MainActions,
      ...DeliveryActions,
      ...GroupsActions,
      ...AuthActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  cart: state.cart.products.map((product) => ({
    ...product,
  })),

  total: formatPrice(
    state.cart.products.reduce((total, product) => {
      if (product?.total > 0) {
        return total + product.total;
      }
      // eslint-disable-next-line
      if (product?.prices[0]?.promotional_price > 0) {
        return total + product.prices[0].promotional_price * product.amount;
      }
      // eslint-disable-next-line
      return total + product?.prices[0]?.regular_price * product?.amount;
    }, 0),
    2
  ),
  totalFloat: state.cart.products.reduce((total, product) => {
    if (product.total > 0) {
      return total + product.total;
    }
    // eslint-disable-next-line
    if (product?.prices[0]?.promotional_price > 0) {
      return total + product.prices[0].promotional_price * product.amount;
    }
    // eslint-disable-next-line
    return total + product?.prices[0]?.regular_price * product?.amount;
  }, 0),
  totalWithTax:
    parseFloat(state.store.delivery_tax || 0) +
    state.cart.products.reduce((total, product) => {
      if (product.total > 0) {
        return total + product.total;
      }
      // eslint-disable-next-line
      if (product.prices[0]?.promotional_price > 0) {
        return total + product.prices[0].promotional_price * product.amount;
      }
      // eslint-disable-next-line
      return total + product?.prices[0]?.regular_price * product.amount;
    }, 0),

  orderMinimunValue: state.store.storeInfo.settings.order_minimun_value,
  color: state.store.storeInfo.settings.color_primary,
  user: state.user.profile,
  signed: state.auth.signed,
  cartVisible: state.main.cartVisible,
  priceDecimals: state.store.storeInfo.settings.price_decimals,
  deliveryTax: state.store.delivery_tax,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
  currentAddress: state.delivery.currentAddress,
  productDetail: state.groups.productDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
