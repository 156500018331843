import React from 'react';
import image from '../../../assets/icons/error/not-found.svg';

import Layout from '../components/Layout';

function WithOutStore() {
  return (
    <Layout
      title="Loja não encontrada"
      text="Verfique sua URL, insira a loja que deseja acessar, ou verifique se
  está correta."
      image={image}
    />
  );
}

export default WithOutStore;
