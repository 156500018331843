import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import store from './store/sagas';
import groups from './groups/sagas';
import delivery from './delivery/sagas';
import order from './order/sagas';
import alers from './alerts/sagas';

export default function* rootSaga() {
  return yield all([auth, user, store, groups, delivery, order, alers]);
}
