import React, { useEffect, useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { IoIosArrowDown } from 'react-icons/io';

import OutLine from '../../../../../components/OutLine';

import * as DeliveryActions from '../../../../../../store/modules/delivery/actions';
import * as AuthActions from '../../../../../../store/modules/auth/actions';
import * as MainActions from '../../../../../../store/modules/main/actions';

import deliveryImage from '../../../../../../assets/images/delivery.jpg';

import Input from '../../../../../components/Input';

import {
  Container,
  Header,
  CloseArea,
  Space,
  Title,
  IconArea,
  ImageArea,
  TextInfo,
  ZipCodeArea,
  ErrorArea,
  WithouCep,
  ButtonArea,
  Button,
} from './styles';

function ZipCode({
  zipCode,
  setZipCode,
  findZipCode,
  handleLoginModal,
  handleMenuAddress,
  errorMessage,
  color,
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  async function handleZipCode(text) {
    text = text.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
    await setZipCode(text);

    if (text && text.length > 8) {
      findZipCode(text);
    }
  }

  function handleModalLogin() {
    handleLoginModal();
    handleMenuAddress(false);
  }

  return (
    <Container>
      <Header>
        <CloseArea color={color} onClick={() => handleMenuAddress(false)}>
          <IconArea>
            <IoIosArrowDown />
          </IconArea>
          <Title>
            <span>Endereço</span>
          </Title>
          <Space />
        </CloseArea>
        <ImageArea>
          <img alt="delivery" src={deliveryImage} />
        </ImageArea>
      </Header>
      <TextInfo>
        <span>Você está dentro da área de entrega?</span>
        {/* <Card onClick={() => handleLocation()}>
    <span>Usar minha localização.</span>
  </Card> */}
      </TextInfo>

      <ZipCodeArea>
        <Input
          ref={inputRef}
          setState={handleZipCode}
          maxLength={9}
          value={zipCode}
          placeholder="Informe seu CEP"
        />
        <ErrorArea>
          <span>{errorMessage}</span>
        </ErrorArea>
        <WithouCep
          onClick={() =>
            window.open(
              'http://www.buscacep.correios.com.br/sistemas/buscacep/'
            )
          }
        >
          Não sei meu CEP
        </WithouCep>
      </ZipCodeArea>

      <OutLine />
      <ButtonArea>
        <Button color={color} onClick={() => handleModalLogin()}>
          <span>Fazer login</span>
        </Button>
      </ButtonArea>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...DeliveryActions, ...AuthActions, ...MainActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
  errorMessage: state.delivery.errorMessage,
  zipCode: state.delivery.zipCode,
});

export default connect(mapStateToProps, mapDispatchToProps)(ZipCode);
