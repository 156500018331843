import React, { useState, useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { IoIosArrowDown } from 'react-icons/io';

import swal from 'sweetalert';
import { toast } from 'react-toastify';

import api from '../../../../services/api';

import * as MainActions from '../../../../store/modules/main/actions';
import * as UserActions from '../../../../store/modules/user/actions';

import {
  Container,
  ModalStyled,
  Header,
  IconArea,
  Title,
  Space,
  Form,
  InputArea,
  SubmitButton,
  Button,
} from './styles';

import Input from '../../../components/Input';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function AlterPassword({
  id,
  handleAlterPassword,
  alterPasswordVisible,
  color,
  signed,
  profile,
  handleForceChangePassword,
}) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (signed && profile && profile.force_change_password) {
      handleAlterPassword(true);
      swal({
        title: 'Atenção',
        text:
          'Notamos que você ainda está utilizando uma senha temporária, é preciso alterar a senha para prosseguir',
        icon: 'info',
      });
    }
  }, []);

  function handleCloseModal(event) {
    if (event.currentTarget === event.target) {
      if (profile && !profile.force_change_password) handleAlterPassword(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!id || !oldPassword || !newPassword || !confirmPassword) {
      return toast.error('Campos obrigatórios.');
    }

    if (newPassword !== confirmPassword) {
      setNewPassword('');
      setConfirmPassword('');
      return toast.error('Confirmação de senha inválida.');
    }

    if (oldPassword === newPassword) {
      setNewPassword('');
      setConfirmPassword('');
      return toast.error('A nova senha deve ser diferente da senha antiga.');
    }
    const response = await api.AlterPasswrod(
      id,
      oldPassword,
      newPassword,
      confirmPassword
    );

    if (response.status !== 200) {
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      return toast.error('Confirme sua nova senha');
    }
    if (response.status === 200) {
      toast.success('Senha alterada com sucesso!! : )');
      handleForceChangePassword(false);
      return handleAlterPassword(false);
    }
    return true;
  }

  return (
    alterPasswordVisible && (
      <Container>
        <ModalStyled
          isOpen={alterPasswordVisible}
          onRequestClose={handleCloseModal}
          style={customStyles}
        >
          <Header color={color}>
            <IconArea onClick={() => handleAlterPassword(false)}>
              <IoIosArrowDown />
            </IconArea>
            <Title>
              <span>Alterar senha</span>
            </Title>
            <Space />
          </Header>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <InputArea>
              <span>Senha atual</span>
              <Input
                ref={inputRef}
                value={oldPassword}
                setState={setOldPassword}
                type="password"
                placeholder="Digite sua senha atual."
              />
            </InputArea>
            <InputArea>
              <span>Nova senha</span>
              <Input
                value={newPassword}
                setState={setNewPassword}
                type="password"
                placeholder="Digite sua nova senha."
              />
            </InputArea>
            <InputArea>
              <span>Confirmar nova senha</span>
              <Input
                value={confirmPassword}
                setState={setConfirmPassword}
                type="password"
                placeholder="Digite novamente sua nova senha."
              />
            </InputArea>

            <SubmitButton>
              <Button color={color}>Alterar</Button>
            </SubmitButton>
          </Form>
        </ModalStyled>
      </Container>
    )
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...MainActions, ...UserActions }, dispatch);

const mapStateToProps = (state) => ({
  alterPasswordVisible: state.main.alterPasswordVisible,
  color: state.store.storeInfo.settings.color_primary,
  id: state.user.profile.id,
  signed: state.auth.signed,
  profile: state.user.profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(AlterPassword);
