import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  background: #00000050;
  z-index: 5;
  justify-content: center;
  padding-top: 150px;

  @media (max-width: 1150px) {
    padding-top: 0px;
  }
`;

export const Modal = styled.div`
  height: 550px;
  width: 50%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;

  @media (max-width: 1150px) {
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }
  @media (max-width: 380px) {
    padding: 10px;
  }
`;
export const TitleIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Space = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Icon = styled(Space)`
  svg {
    height: 50px;
    width: 50px;
    color: #f8c48f;
  }
`;

export const Close = styled(Space)`
  justify-content: flex-end;

  svg {
    cursor: pointer;
    height: 25px;
    width: 25px;
    transition: color 0.2s;

    &:hover {
      color: #bbb;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  .second {
    font-weight: normal;
    font-size: 18px;
    margin-top: 2px;
  }

  @media (max-width: 576px) {
    font-size: 18px;

    .second {
      font-size: 16px;
    }
  }
`;

export const Products = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-top: 1px solid #eee;
  max-height: 330px;
  height: 330px;
`;

export const Header = styled.div`
  display: flex;
  padding: 10px 0;
`;

export const HeaderProduct = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
`;

export const HeaderObservation = styled(HeaderProduct)``;

export const HeaderOptions = styled(HeaderObservation)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Product = styled.div`
  display: flex;
  padding: 20px 0;
  border-top: 1px solid #eee;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  color: #6e7989;

  svg {
    height: 20px;
    width: 20px;
    margin: 0 10px;
  }
`;

export const Name = styled.div`
  flex: 2;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Observation = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 10px;
  border-left: 1px solid #eee;
`;

export const Options = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding: 10px;
  border-left: 1px solid #eee;

  svg {
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #e57373;
    transition: height 0.1s, width 0.1s, color 0.6s;

    & + svg {
      color: #81c784;

      &:hover {
        color: #4caf50;
      }
    }

    &:hover {
      color: #f44336;
      width: 25px;
      height: 25px;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    justify-content: center;

    svg {
      & + svg {
        margin-top: 10px;
      }
    }
  }
`;

export const ButtonsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonDelete = styled.div`
  height: auto;
  padding: 10px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e57373;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.6s, box-shadow 0.6s;
  box-shadow: 0px 3px 4px #00000050;

  &:hover {
    background: #f44336;
    box-shadow: 1px 5px 7px #00000050;
  }
`;
export const ButtonAccept = styled(ButtonDelete)`
  border: 1px solid #81c784;
  background: #81c784;

  &:hover {
    background: #4caf50;
  }
`;
