import React, { useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MdKeyboardArrowRight } from 'react-icons/md';

import { toast } from 'react-toastify';

import * as CustomerActions from '../../../../../../store/modules/customer/actions';

import { signUpRequest } from '../../../../../../store/modules/auth/actions';
import {
  validationDocument,
  nameIsvValid,
} from '../../../../../../util/validation';

import Input from '../../../../../components/Input';

import {
  ResgisterContainer,
  Form,
  InputArea,
  InputLine,
  NewsLetter,
  Contract,
  ButtonArea,
  IconArea,
  Dot,
  Text,
  RegisterButton,
} from './styles';

function Register({
  registerVisible,
  color,
  handleLoginModal,
  setName,
  name,
  setDocument,
  document,
  setPhone,
  phone,
  setCellphone,
  cellphone,
  setEmail,
  email,
  setNewsletterAccept,
  newsletterAccept,
  setAcceptContract,
  acceptContract,
}) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const DocumentMask = (text) => {
    if (text.length <= 14) {
      text = text.replace(/\D/g, '');
      text = text.replace(/(\d{3})(\d)/, '$1.$2');
      text = text.replace(/(\d{3})(\d)/, '$1.$2');
      text = text.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      text = text.replace(/\D/g, '');
      text = text.replace(/^(\d{2})(\d{3})(\d)/g, '$1.$2.$3');
      text = text.replace(/(\d{3})(\d{4})(\d{2})$/, '$1/$2-$3');
    }
    return text;
  };
  const PhoneMask = (text) => {
    return text
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d)(\d{4})$/, '$1$2-$3');
  };

  async function handleDocument(text) {
    await setDocument(DocumentMask(text));
  }

  function handlePhone(text) {
    setPhone(PhoneMask(text));
  }

  function handleCellphone(text) {
    setCellphone(PhoneMask(text));
  }

  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();

    if (!nameIsvValid(name)) {
      return toast.error('Nome inválido');
    }

    if (!acceptContract) {
      return toast.error(
        'Para realizar o cadastro você deve aceitar com os termos.'
      );
    }

    if (password !== confirmPassword) {
      setPassword('');
      setConfirmPassword('');
      return toast.error('Confirme sua senha.');
    }

    if (
      document.length < 14 ||
      (document.length > 14 && document.length < 18)
    ) {
      return toast.error('CPF inválido');
    }

    if (!validationDocument(document)) return toast.error('CPF/CNPJ inválido');

    dispatch(
      signUpRequest(
        name,
        document,
        phone,
        cellphone,
        email,
        password,
        confirmPassword,
        newsletterAccept,
        acceptContract
      )
    );

    dispatch(handleLoginModal());

    return true;
  }

  return (
    <ResgisterContainer registerVisible={registerVisible}>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <InputArea>
          <span>Nome</span>
          <Input
            value={name}
            setState={setName}
            placeholder="Digite seu nome"
            required
            autoComplete="off"
          />
        </InputArea>
        <InputArea>
          <span>Documento</span>
          <Input
            minLength={14}
            maxLength={18}
            value={document}
            setState={handleDocument}
            placeholder="Digite seu CPF/CNPJ"
            required
            autoComplete="off"
          />
        </InputArea>
        <InputLine>
          <InputArea>
            <span>Telefone</span>
            <Input
              required={!cellphone}
              maxLength={15}
              value={phone}
              setState={handlePhone}
              placeholder="Digite seu telefone"
              autoComplete="off"
            />
          </InputArea>
          <InputArea>
            <span>Celular</span>
            <Input
              maxLength={15}
              value={cellphone}
              setState={handleCellphone}
              placeholder="Digite seu celular"
              autoComplete="off"
            />
          </InputArea>
        </InputLine>

        <InputArea>
          <span>E-mail</span>
          <Input
            value={email}
            setState={setEmail}
            type="email"
            placeholder="Digite seu e-mail"
            required
            autoComplete="off"
          />
        </InputArea>

        <InputLine>
          <InputArea>
            <span>Senha</span>
            <Input
              value={password}
              setState={setPassword}
              type="password"
              placeholder="Digite sua senha "
              required
              autoComplete="off"
            />
          </InputArea>
          <InputArea>
            <span>Confirme a senha</span>
            <Input
              value={confirmPassword}
              setState={setConfirmPassword}
              type="password"
              placeholder="Digite sua senha "
              required
              autoComplete="off"
            />
          </InputArea>
        </InputLine>
        <NewsLetter>
          <div>
            <input
              checked={newsletterAccept}
              defaultChecked={false}
              type="checkbox"
              onChange={(text) => setNewsletterAccept(text.target.checked)}
            />
          </div>
          <div>
            <span>
              Aceito receber e-mail comunicando novidades e promoções.
            </span>
          </div>
        </NewsLetter>
        <Contract>
          <input
            checked={acceptContract}
            defaultChecked={false}
            type="checkbox"
            onChange={(text) => setAcceptContract(text.target.checked)}
          />
          <span>
            Aceito os <span>termos de uso</span>
          </span>
        </Contract>
        <ButtonArea>
          <RegisterButton color={color}>
            <Text>Cadastrar</Text>
            <IconArea>
              <Dot>
                <MdKeyboardArrowRight size={20} />
              </Dot>
            </IconArea>
          </RegisterButton>
        </ButtonArea>
      </Form>
    </ResgisterContainer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...CustomerActions }, dispatch);

const mapStateToProps = (state) => ({
  name: state.customer.name,
  document: state.customer.document,
  phone: state.customer.phone,
  cellphone: state.customer.cellphone,
  email: state.customer.email,
  newsletterAccept: state.customer.newsletterAccept,
  acceptContract: state.customer.acceptContract,
});
export default connect(mapStateToProps, mapDispatchToProps)(Register);
