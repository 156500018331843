import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  from {
    right: -10px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  color: #fff;
  animation: ${animation} 0.6s;
  position: relative;
  background: ${(props) => props.color};
  width: 100%;
  padding: 10px;
`;

export const Title = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;

  @media (max-height: 660px) {
    padding: 10px;
  }
`;

export const IconArea = styled.div`
  display: flex;
  flex: 1;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: ${animation} 0.6s;
  position: relative;

  @media (max-width: 576px) {
    font-size: 14px;
  }
  @media (max-height: 768px) {
    padding: 0px;
    font-size: 14px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 20px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
  @media (max-height: 768px) {
    padding: 10px;
  }
`;

export const InputArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 5px 0;

  span + span {
    font-size: 20px;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 10px;

  @media (max-width: 576px) {
    font-size: 14px;
    padding: 0 0 50px 10px;
  }
`;

export const ButtonConfirm = styled.button.attrs({ type: 'submit' })`
  width: 80%;
  height: 40px;
  border: 0;
  box-shadow: 0px 2px 4px #00000050;
  font-size: 16px;
  transition: box-shadow 0.6s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.color};

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }
  @media (max-height: 768px) {
    height: 30px;
    margin-top: 0px;
  }
`;
