import React from 'react';

import { connect } from 'react-redux';

import { AiOutlineHome } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';

import {
  Container,
  SubTitle,
  SubAddress,
  EditAddress,
  CustomerInfo,
  Person,
  Name,
  Document,
  Phone,
  Email,
  Address,
} from './styles';
import { Sub } from '../../styles';

function Customer({
  user,
  currentAddress,
  restrictDeliveryArea,
  customer,
  handleMenuAddress,
}) {
  return (
    <Container>
      <SubTitle>
        <Sub>
          <AiOutlineHome size={16} />
          <span>Informações pessoais</span>
        </Sub>
        {
          // eslint-disable-next-line
        customer?.customer_address?.length > 0 && currentAddress?.id && (
            <SubAddress
              restrictDeliveryArea={restrictDeliveryArea}
              onClick={() => restrictDeliveryArea && handleMenuAddress(true)}
            >
              Endereço
              {restrictDeliveryArea && (
                <EditAddress>
                  <FiEdit2 size={16} />
                </EditAddress>
              )}
            </SubAddress>
          )
        }
      </SubTitle>
      <CustomerInfo>
        <Person>
          <Name>{user?.name || ''}</Name>
          <Document>{customer?.document || ''}</Document>
          <Phone>{customer?.phone || ''}</Phone>
          <Email>{customer?.email || ''}</Email>
        </Person>
        {
          // eslint-disable-next-line
        currentAddress?.id && (
            <Address
              restrictDeliveryArea={restrictDeliveryArea}
              onClick={() => restrictDeliveryArea && handleMenuAddress(true)}
            >
              <p />
              {currentAddress.name}
              <p />
              {currentAddress.street && `${currentAddress.street}, `}
              {currentAddress.number && currentAddress.number}
              <p />
              {currentAddress.neighbourhood}
              {currentAddress.city} {currentAddress.state}
              <p />
              {currentAddress.zip_code}
              <p />
              {currentAddress.complement}
            </Address>
          )
        }
      </CustomerInfo>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  currentAddress: state?.delivery?.currentAddress,
  restrictDeliveryArea:
    state?.store?.storeInfo?.settings &&
    state.store.storeInfo.settings.restrict_delivery_area,
  customer: state?.user?.customer,
  user: state?.user?.profile,
});

export default connect(mapStateToProps)(Customer);
