import { takeLatest, all, put, select } from 'redux-saga/effects';

import api from '../../../services/api';

import { setCartVisible } from '../main/actions';
import { setStoreInfo } from './actions';
import { renewToken } from '../auth/actions';

export function* getStoreInfo() {
  const response = yield api.getStore();

  const signed = yield select((state) => state.auth.signed);

  if (signed) yield put(renewToken(response.headers.token));

  if (
    response?.data &&
    window.innerWidth > 1150 &&
    !response.data.settings.showcase &&
    !response.data.settings.discount_club
  ) {
    yield put(setCartVisible(response.data.settings.cart_visible));
  }

  if (response.data.settings.showcase || response.data.settings.discount_club) {
    yield put(setCartVisible(false));
  }

  return yield response && put(setStoreInfo(response.data));
}

export default all([takeLatest('GET_STORE_INFO', getStoreInfo)]);
