import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  width: 30%;
  background: #fff;
  height: 200px;
  margin-top: 155px;
  display: flex;
  justify-self: center;
  color: ${(props) => props.theme.colors.grayDark};
  flex-direction: column;
  padding: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 2;

  @media (max-width: 992px) {
    margin-top: 20px;
    width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const Values = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const Tax = styled(Values)``;

export const TotalFooter = styled.div`
  height: 50px;
  display: flex;
  justify-self: flex-end;
  border-top: 1px solid #eeeeee;
  color: #000;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
`;
