import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 20px 0;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  margin: 0 10px 0 10px;

  div {
    height: 50%;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.color || '#ddd'};
  }

  @media (max-height: 600px) {
    height: 20px;
  }

  @media (max-height: 900px) and (max-width: 576px) {
    height: 200px;
  }
  @media (max-height: 768px) and (max-width: 576px) {
    height: 150px;
  }
  @media (max-height: 726px) and (max-width: 576px) {
    height: 50px;
  }
  @media (max-height: 600px) and (max-width: 576px) {
    height: 5px;
  }
`;
