import React from 'react';

import {
  Container,
  CheckBoxArea,
  CheckBoxWrapper,
  Check,
  CheckBoxLabel,
} from './styles';

function CheckBox({ status, id, disable, handleStatus }) {
  return (
    <Container>
      <CheckBoxArea status={status}>
        <CheckBoxWrapper>
          <Check
            disabled={disable}
            id={id}
            type="checkbox"
            checked={status}
            onChange={(e) => handleStatus(e)}
          />
          <CheckBoxLabel htmlFor={id} />
        </CheckBoxWrapper>
      </CheckBoxArea>
    </Container>
  );
}

export default CheckBox;
