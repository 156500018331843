export function validationResponse(response) {
  if (
    response &&
    response.response &&
    response.response.status &&
    response.response.status !== 200
  ) {
    return false;
  }
  return true;
}

export function validationCard(number) {
  const message = [];
  let type = null;
  let flag = '';
  let validate = false;

  if (number.length > 16 || number[0] === 0) {
    message.push('Número de cartão inválido');
  } else {
    const toDigits = (numString) =>
      numString
        .replace(/[^0-9]/g, '')
        .split('')
        .map(Number);

    const condTransform = (predicate, value, fn) => {
      if (predicate) {
        return fn(value);
      }
      return value;
    };

    const doubleEveryOther = (current, idx) =>
      condTransform(idx % 2 === 0, current, (x) => x * 2);

    const reduceMultiDigitVals = (current) =>
      condTransform(current > 9, current, (x) => x - 9);

    const luhn = {};

    luhn.validate = (numString) => {
      const digits = toDigits(numString);
      const len = digits.length;
      const luhnDigit = digits[len - 1];

      const total = digits
        .slice(0, -1)
        .reverse()
        .map(doubleEveryOther)
        .map(reduceMultiDigitVals)
        .reduce((current, accumulator) => current + accumulator, luhnDigit);

      return total % 10 === 0;
    };
    validate = luhn.validate(number);

    switch (parseInt(number[0], 10)) {
      case 0:
        message.push('Número incorreto');
        break;
      case 1:
        type = 'Empresas Aéreas';
        break;
      case 2:
        type = 'Empresas Aéreas';
        break;
      case 3:
        type = 'Viagens e Entretenimento';
        if (
          parseInt(number[0] + number[1], 10) === 34 ||
          parseInt(number[0] + number[1], 10) === 37
        ) {
          flag = 'Amex';
        }
        if (
          parseInt(number[0] + number[1], 10) === 36 ||
          parseInt(number[0] + number[1], 10) === 30 ||
          parseInt(number[0] + number[1], 10) === 38
        ) {
          flag = 'Diners';
        }
        break;
      case 4:
        type = 'Bancos e Instituições Financeiras';
        flag = 'Visa';
        break;
      case 5:
        if (
          parseInt(number[0] + number[1], 10) >= 51 &&
          parseInt(number[0] + number[1], 10) <= 55
        ) {
          flag = 'Mastercard';
        }
        type = 'Bancos e Instituições Financeiras';
        flag = 'Mastercard';
        break;
      case 6:
        type = 'Bancos e Comerciais';
        if (parseInt(number[0] + number[1] + number[2], 10) === 601) {
          flag = 'Discover';
        }
        if (parseInt(number[0] + number[1] + number[2], 10) === 606) {
          flag = 'Hipercard';
        }
        if (parseInt(number[0] + number[1], 10) === 63) {
          flag = 'Elo';
        }
        break;
      case 7:
        type = 'Companhias de petróleo';
        flag = '';
        break;
      case 8:
        type = 'Companhia de telecomunicações';
        flag = '';
        break;
      case 9:
        type = 'Nacionais';
        flag = '';
        break;
      default:
        message.push('Número incorreto');
        break;
    }
  }

  if (message.length > 0) {
    return message;
  }
  if (validate) {
    return { tipo: type, operadora: flag };
  }
  return `Cartão inválido.`;
}

export function validationDocument(document) {
  const doc = document.replace(/[^\d]+/g, '');
  if (doc === '') return false;
  if (doc.length === 11) {
    if (
      doc === '00000000000' ||
      doc === '11111111111' ||
      doc === '22222222222' ||
      doc === '33333333333' ||
      doc === '44444444444' ||
      doc === '55555555555' ||
      doc === '66666666666' ||
      doc === '77777777777' ||
      doc === '88888888888' ||
      doc === '99999999999'
    )
      return false;
    let sum;
    let rest;
    sum = 0;

    for (let i = 1; i <= 9; i += 1)
      sum += parseInt(doc.substring(i - 1, i), 10) * (11 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(doc.substring(9, 10), 10)) return false;

    sum = 0;
    for (let i = 1; i <= 10; i += 1)
      sum += parseInt(doc.substring(i - 1, i), 10) * (12 - i);
    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) rest = 0;
    if (rest !== parseInt(doc.substring(10, 11), 10)) return false;
    return true;
  }
  if (doc.length === 14) {
    if (
      doc === '00000000000000' ||
      doc === '11111111111111' ||
      doc === '22222222222222' ||
      doc === '33333333333333' ||
      doc === '44444444444444' ||
      doc === '55555555555555' ||
      doc === '66666666666666' ||
      doc === '77777777777777' ||
      doc === '88888888888888' ||
      doc === '99999999999999'
    ) {
      return false;
    }
    let size = doc.length - 2;
    let numbers = doc.substring(0, size);
    const digits = doc.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i -= 1) {
      // eslint-disable-next-line
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    // eslint-disable-next-line
    if (result != digits.charAt(0)) return false;

    size += 1;
    numbers = doc.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i -= 1) {
      // eslint-disable-next-line
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    // eslint-disable-next-line
    if (result != digits.charAt(1)) return false;

    return true;
  }

  return false;
}

export function nameIsvValid(name) {
  return /^([A-Za-z&áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{2,}\s){1}([A-Za-z&áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{1,}\s?){1,}$/.test(
    name
  );
}
