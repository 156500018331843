import React from 'react';

import { connect } from 'react-redux';

import { ReactComponent as Facebook } from '../../../../../../assets/icons/socialMedia/facebook.svg';
import { ReactComponent as Instagram } from '../../../../../../assets/icons/socialMedia/instagram.svg';
import { ReactComponent as Website } from '../../../../../../assets/icons/socialMedia/website.svg';
import { ReactComponent as Twitter } from '../../../../../../assets/icons/socialMedia/twitter.svg';
import { ReactComponent as Youtube } from '../../../../../../assets/icons/socialMedia/youtube.svg';

import {
  Container,
  Logo,
  Store,
  Title,
  NameArea,
  NameStore,
  Address,
  DescriptionArea,
  Description,
  SocialMediaArea,
  SocialMedia,
} from './styles';

function FooterStore({ cartVisible, storeInfo, imageHeader, color }) {
  const { name, street, number, city, description } = storeInfo;
  return (
    <Container cartVisible={cartVisible}>
      {imageHeader ? (
        <Logo src={imageHeader} />
      ) : (
        <div style={{ margin: '20px' }} />
      )}
      <Store>
        <NameArea>
          {name && (
            <NameStore>
              <Title>Empresa</Title>
              <span>{name} </span>
            </NameStore>
          )}
          {storeInfo && storeInfo.show_address && (street || number || city) && (
            <Address>
              <Title>Endereço</Title>
              <span>
                {street}, {number || 'S/N'}, {city}.{' '}
              </span>
            </Address>
          )}
        </NameArea>
        <DescriptionArea>
          {description && (
            <Description>
              <Title>Informações</Title>
              <Description>{description}</Description>
            </Description>
          )}
          {(storeInfo?.settings?.facebook ||
            storeInfo?.settings?.instagram ||
            storeInfo?.settings?.youtube ||
            storeInfo?.settings?.twitter ||
            storeInfo?.settings?.website) && (
            <SocialMediaArea>
              <Title>Redes sociais</Title>
              <SocialMedia color={color}>
                {storeInfo?.settings?.facebook && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.facebook}
                  >
                    <Facebook />
                  </a>
                )}
                {storeInfo?.settings?.instagram && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.instagram}
                  >
                    <Instagram />
                  </a>
                )}
                {storeInfo?.settings?.youtube && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.youtube}
                  >
                    <Youtube />
                  </a>
                )}
                {storeInfo?.settings?.twitter && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={storeInfo?.settings?.twitter}
                  >
                    <Twitter />
                  </a>
                )}
                {storeInfo?.settings?.website && (
                  <a
                    href={
                      storeInfo?.settings?.website.startsWith('http')
                        ? storeInfo?.settings?.website
                        : `//${storeInfo?.settings?.website}`
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Website />
                  </a>
                )}
              </SocialMedia>
            </SocialMediaArea>
          )}
        </DescriptionArea>
      </Store>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  cartVisible: state.main.cartVisible,
  storeInfo: state.store.storeInfo,
  imageHeader: state.store.storeInfo.settings.logo_url,
  color: state.store.storeInfo.settings.color_primary,
});
export default connect(mapStateToProps)(FooterStore);
