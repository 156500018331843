import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import { toast } from 'react-toastify';
import api from '../../../../../../services/api';

import * as UserActions from '../../../../../../store/modules/user/actions';

import {
  Container,
  ModalStyled,
  Header,
  Title,
  Form,
  InputArea,
  SubmitButton,
  Button,
} from './styles';

import Input from '../../../../../components/Input';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function RegistryComplementary({
  id,
  color,
  signed,
  profile,
  discountClub,
  setProfileBirthDate,
}) {
  const [
    complementaryRegistryVisible,
    setComplementaryRegistryVisible,
  ] = useState(false);
  const [birthDate, setBirthDate] = useState();
  const [zipCode, setZipCode] = useState();
  const [street, setStreet] = useState();
  const [neighbourhood, setNeighbourhood] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [number, setNumber] = useState();
  const [complement, setComplement] = useState();

  async function handleZipCode(text) {
    text = text.replace(/\D/g, '').replace(/^(\d{5})(\d)/g, '$1-$2');
    await setZipCode(text);

    if (text && text.length > 8) {
      const response = await api.getZipCode(text);
      if (response?.status !== 200) {
        toast.error('CEP não encontrado.');
        return;
      }
      setStreet(response.data.street);
      setNeighbourhood(response.data.neighborhood);
      setCity(response.data.city);
      setState(response.data.state);
      setZipCode(
        response.data.cep
          .replace(/\D/g, '')
          .replace(/^(\d{5})(\d{3})/g, '$1-$2')
      );
    }
  }

  async function handleBirthDate(text) {
    text = text
      .replace(/\D/g, '')
      .replace(
        /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])((19|20)\d\d)/g,
        '$1/$2/$3'
      )
      .replace(/^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])(\d)/g, '$1/$2/$3')
      .replace(/^(0[1-9]|[12][0-9]|3[01])(\d)/g, '$1/$2');
    await setBirthDate(text);
  }

  useEffect(() => {
    if (discountClub && signed && profile && !profile.birth_date)
      setComplementaryRegistryVisible(true);
  }, [signed]);

  useEffect(() => {
    if (complementaryRegistryVisible) {
      setTimeout(() => {
        const root = document.getElementsByTagName('body')[0];
        root.classList.add('no-scroll');
      }, 300);
    } else {
      setTimeout(() => {
        const root = document.getElementsByTagName('body')[0];
        root.classList.remove('no-scroll');
      }, 300);
    }
  }, [complementaryRegistryVisible]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      !birthDate.match(
        /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]((19|20)\d\d)/g
      )
    ) {
      return toast.error('Data de nascimento inválida');
    }

    if (!zipCode.match(/^(\d{5})[-](\d{3})/g)) {
      return toast.error('CEP inválida');
    }

    if (
      !id ||
      !birthDate ||
      !zipCode ||
      !street ||
      !neighbourhood ||
      !city ||
      !state
    ) {
      return toast.error('Campos obrigatórios.');
    }

    const addressToSearch = `${street} ${number} ${neighbourhood} ${city}`;

    const geolocation = await api.getGeolocationByAddress(addressToSearch);

    if (geolocation && geolocation.data.status !== 'OK') {
      return toast.error(
        'Não foi possivel determinar a geolocalização, tente novamente mais tarde'
      );
    }

    const latitude = geolocation.data.results[0].geometry.location.lat;
    const longitude = geolocation.data.results[0].geometry.location.lng;

    const response = await api.createComplementaryRegistry(
      id,
      moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      zipCode,
      street,
      complement,
      neighbourhood,
      city,
      state,
      number,
      latitude,
      longitude
    );

    if (response.status !== 200) {
      return toast.error(
        'Falha ao atualizar cadastro, tente novamente mais tarde.'
      );
    }
    if (response.status === 200) {
      setProfileBirthDate(response.data.birth_date);
      setComplementaryRegistryVisible(false);
      toast.success('Cadastro finalizado : )');
    }

    return true;
  }

  return (
    complementaryRegistryVisible && (
      <Container>
        <ModalStyled
          isOpen
          // onRequestClose={handleCloseModal}
          style={customStyles}
        >
          <Header color={color}>
            <Title>
              <span>Dados adicionais</span>
            </Title>
          </Header>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <InputArea>
              <span>
                <strong>Data de nascimento: </strong>
              </span>
              <Input
                required
                maxLength={10}
                setState={handleBirthDate}
                value={birthDate}
                placeholder="DD/MM/AAAA"
              />
            </InputArea>

            <InputArea>
              <span>
                <strong>CEP: </strong>
              </span>
              <Input
                required
                setState={handleZipCode}
                maxLength={9}
                value={zipCode}
                placeholder="Informe seu CEP"
              />
            </InputArea>

            <InputArea>
              <strong>Rua: </strong>
              <Input
                value={street}
                required
                placeholder="Ex.(Avenida Brasil)"
                setState={setStreet}
              />
            </InputArea>

            <InputArea>
              <strong>Bairro: </strong>
              <Input
                value={neighbourhood}
                required
                placeholder="Ex.(Jardim Brasil)"
                setState={setNeighbourhood}
              />
            </InputArea>

            <InputArea>
              <strong>Cidade: </strong>
              <Input
                value={city}
                required
                placeholder="Ex.(Sorocaba)"
                setState={setCity}
              />
            </InputArea>

            <InputArea>
              <strong>Estado: </strong>
              <Input
                maxLength={2}
                value={state}
                required
                placeholder="Ex.(São Paulo)"
                setState={setState}
              />
            </InputArea>

            <InputArea>
              <strong>Número: </strong>
              <Input
                value={number}
                type="number"
                required
                placeholder="Ex.(2000)"
                setState={setNumber}
              />
            </InputArea>

            <InputArea>
              <strong>Complemento: </strong>
              <Input
                type="text"
                value={complement}
                placeholder="Ex.(Apto 10 bloco C)"
                setState={setComplement}
              />
            </InputArea>

            <SubmitButton>
              <Button color={color}>Salvar</Button>
            </SubmitButton>
          </Form>
        </ModalStyled>
      </Container>
    )
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...UserActions }, dispatch);

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
  id: state.user.profile.id,
  signed: state.auth.signed,
  profile: state.user.profile,
  discountClub: state.store.storeInfo.settings.discount_club,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistryComplementary);
