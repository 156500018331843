import styled from 'styled-components';

export const Container = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${(props) => props.theme.colors.linearGradientGreen};

  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

export const Side = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;

  img {
    width: 70%;
  }

  label {
    font-size: 5rem;
    margin-bottom: 10px;
  }
  span {
    font-size: 2rem;
  }
  input {
    margin: 10px 0;
  }

  @media (max-width: 992px) {
    justify-content: flex-start;
    & + div {
      justify-content: flex-end;
    }
    label {
      font-size: 3.5rem;
    }
    span {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 576px) {
    label {
      font-size: 3rem;
    }
    span {
      font-size: 1rem;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const ReloadArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: #fff;
    border-radius: 10px;
    font-size: 3rem;
    color: ${(props) => props.theme.colors.greenDark};
    font-weight: bold;
    box-shadow: 0px 3px 5px #00000050;
    transition: all 0.6s;
    cursor: pointer;
    width: 60%;
    text-decoration: none;

    &:hover {
      box-shadow: 2px 5px 7px #00000050;
    }

    @media (max-width: 992px) {
      font-size: 2rem;
    }
    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
`;
