import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { BannerArea, BannerImage, ImageDefault } from './styles';

function Banner({
  store,
  cartVisible,
  headerVisible,
  navigation,
  restrictDeliveryArea,
}) {
  const [navi, setNavi] = useState(false);

  useEffect(() => {
    if (navigation && navigation.length > 0) {
      return setNavi(true);
    }
    return setNavi(false);
  }, [navigation]);

  return (
    <>
      {store.settings.banner_url ? (
        <BannerArea
          navigation={navi}
          headerVisible={headerVisible}
          cartVisible={cartVisible}
          restrictDeliveryArea={restrictDeliveryArea}
        >
          <BannerImage
            image={store.settings.banner_url}
            mobile={
              store.settings.banner_mobile_url || store.settings.banner_url
            }
          >
            <div style={{ backgroundPosition: 'center center' }} />
          </BannerImage>
        </BannerArea>
      ) : (
        <ImageDefault
          headerVisible={headerVisible}
          cartVisible={cartVisible}
          color={store.settings.color_primary}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  store: state.store.storeInfo,
  cartVisible: state.main.cartVisible,
  headerVisible: state.main.headerVisible,
  navigation: state.groups.navigation,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
});
export default connect(mapStateToProps)(Banner);
