import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000050;
  z-index: 6;
`;

export const Modal = styled.div`
  display: flex;
  height: auto;
  max-width: 50%;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  line-height: 25px;

  @media (max-width: 576px) {
    max-width: 70%;
  }
  @media (max-width: 420px) {
    max-width: 80%;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 24px;
  text-align: center;
`;

export const Message = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const Button = styled.div`
  display: flex;
  background: ${(props) => props.color};
  border-radius: 10px;
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 4px #00000050;
  transition: all 0.6s;

  &:hover {
    box-shadow: 2px 4px 8px #00000050;
  }
`;
