import styled, { keyframes } from 'styled-components';

import Modal from 'react-modal';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  @media print {
    position: relative;
  }

  @media (max-width: 1150px) {
    z-index: 6;
  }
`;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  min-height: 80%;
  max-height: 80%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;

  @media print {
    height: 100%;
    overflow-y: visible;
  }

  &:hover {
    box-shadow: 10px 10px 10px #80828b30;
  }

  @media (max-width: 1150px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    margin-top: 0;
    border-radius: 0;
  }
`;

export const IconClose = styled.div`
  display: none;
  justify-content: space-between;

  @media (max-width: 768px) {
    cursor: pointer;
    display: flex;
  }

  @media print {
    display: none;
  }
`;

export const IconsMobile = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
`;
export const PrintResponsive = styled.div`
  display: none;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    display: flex;
  }
`;

export const HeaderComplete = styled.div``;

export const Loading = styled.div`
  position: fixed;
  height: 70%;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: flex;
    animation: ${rotate} 1s infinite;
  }
`;

export const HeaderPrint = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  align-items: center;

  @media print {
    border: 1px solid #eeeeee;
  }

  @media (max-width: 576px) {
    font-size: 12px;
  }

  @media (max-width: 500px) {
    height: auto;

    padding: 10px 0;
  }
`;

export const Logo = styled.div`
  display: none;
  margin: 5px 0 0 0px;
  background-image: ${(props) => ` url(${props.image})`};
  width: 10%;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;

  @media print {
    display: flex;
  }
`;

export const Info = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;

  @media (max-width: 576px) {
    margin-bottom: 0px;
    padding: 0;
  }
`;

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: column;

  span {
    & + span {
      margin-top: 5px;
    }
  }
`;

export const OnlinePaymentInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    div {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const TrackingCodeArea = styled.div`
  display: flex;
  padding: 5px 0;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TrackingCode = styled.div`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
  color: blue;

  @media (max-width: 576px) {
    margin-left: 0px;
  }
`;

export const Customer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  margin-right: 50px;

  span {
    & + span {
      margin-top: 5px;
    }
  }

  @media print {
    margin-right: 0px;
  }
  @media (max-width: 500px) {
    margin-right: 0px;
  }
`;

export const IconsArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 15%;

  @media print {
    display: none;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const ButtonArea = styled.a`
  display: flex;
  width: 80px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  transition: all 0.6s;
  cursor: pointer;
  text-decoration: none;
  color: #000;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }
`;

export const Print = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.5s;
  cursor: pointer;
`;

export const BankBillArea = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.5s;
  cursor: pointer;
  background: transparent;
`;

export const Body = styled.div`
  padding: 10px 0;
  width: 100%;
  height: 78%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 576px) {
    font-size: 10px;
  }

  @media (max-width: 500px) {
    height: 70%;
  }

  @media print {
    height: 100%;
    padding: 0;
    overflow-y: visible;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    border-top: 2px solid #eee;
  }
`;

export const Values = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  padding: 10px;

  @media print {
    margin: 0;
    display: flex;
    min-height: 30px;
    height: auto;
    padding: 10px !important;
  }

  @media print {
    padding: 0;
    margin: 0;
  }

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Option = styled.div`
  display: flex;
  padding: 5px 10px 5px 20px;
  font-size: 16px;
  color: #9e9e9e;
  justify-content: space-between;
`;

export const SubtotalItem = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  font-size: 18px;

  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

export const Tax = styled.div`
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  font-size: 20px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`;

export const Space = styled.div`
  display: flex;
  flex: 1;
`;
export const TaxText = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 10px;
`;
export const TaxValue = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0 10px;
`;

export const Footer = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 10%;
`;

export const Total = styled.h3`
  & + h3 {
    margin-left: 10px;
  }
`;
