import React from 'react';

import { connect } from 'react-redux';

import FooterStore from './components/FooterStore';
import FooterCompany from './components/FooterCompany';

import { Container } from './styles';

function Footer({ cartVisible }) {
  return (
    <Container cartVisible={cartVisible}>
      <FooterStore />
      <FooterCompany />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  cartVisible: state.main.cartVisible,
});
export default connect(mapStateToProps)(Footer);
