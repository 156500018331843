import produce from 'immer';

const INITIAL_STATE = {
  name: '',
  document: '',
  phone: '',
  cellphone: '',
  email: '',
  newsletterAccept: false,
  acceptContract: false,
};

export default function customer(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_NAME': {
        draft.name = action.name;
        break;
      }
      case 'SET_DOCUMENT': {
        draft.document = action.document;
        break;
      }
      case 'SET_PHONE': {
        draft.phone = action.phone;
        break;
      }
      case 'SET_CELLPHONE': {
        draft.cellphone = action.cellphone;
        break;
      }
      case 'SET_EMAIL': {
        draft.email = action.email;
        break;
      }
      case 'SET_NEWSLATTER': {
        draft.newsletterAccept = action.newsletterAccept;
        break;
      }
      case 'SET_CONTRACT': {
        draft.acceptContract = action.acceptContract;
        break;
      }

      case 'CLEAN_DATA': {
        draft.name = '';
        draft.document = '';
        draft.phone = '';
        draft.cellphone = '';
        draft.email = '';
        draft.newsletterAccept = false;
        draft.acceptContract = false;

        break;
      }
      default:
    }
  });
}
