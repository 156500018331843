import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  transition: width 1s;
  border-bottom: 1px solid #ddd;
  margin-left: 20px;
  margin-right: 20px !important;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 1150px) {
    flex-direction: column;
    padding: 0px 0px 10px 0;
    margin-left: 0px;
  }
`;

export const Logo = styled.img`
  height: 100px;
  margin: 5px 20px 0px 0px;

  @media (max-width: 576px) {
    height: 80px;
  }
`;

export const Store = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;

  @media (max-width: 1150px) {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 1150px) {
    text-align: center;
    width: 100%;
    font-size: 20px;
  }
`;

export const NameArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;

  @media (max-width: 1150px) {
    flex-direction: column;
    border-bottom: 0;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
`;

export const NameStore = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 1150px) {
    width: 100%;
    align-items: center;
    font-size: 20px;
  }
`;
export const Address = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: 1150px) {
    width: 100%;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
  }
`;

export const DescriptionArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  @media (max-width: 1150px) {
    flex-direction: column;
    font-size: 14px;
  }
`;

export const Description = styled.div`
  flex: 1;
  padding: 0 10px 0 0;
  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const SocialMediaArea = styled.div`
  flex: 1;

  @media (max-width: 1150px) {
    margin-top: 20px;
  }
`;
export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 0;

  a {
    text-decoration: none;

    & + a {
      margin-left: 20px;
    }
  }

  svg {
    height: 30px;
    width: 30px;
  }

  @media (max-width: 1150px) {
    justify-content: center;
  }
`;
