import styled from 'styled-components';

export const Input = styled.input`
  display: none;

  &:checked + .cbx {
    border-color: ${(props) => props.color || '#0b76ef'};
    .flip {
      transform: rotateY(180deg);
    }
  }
`;
export const Cbx = styled.label`
  perspective: 20;
  perspective: 20;
  top: 50%;
  left: 50%;
  margin: -12px;
  border: 2px solid #e8e8eb;
  background: #e8e8eb;
  border-radius: 4px;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border-color: ${(props) => props.color || '#0b76ef'};
  }
`;

export const Flip = styled.div`
  display: block;
  transition: all 0.4s ease;
  transform-style: preserve-3d;
  position: relative;
  width: 20px;
  height: 20px;
`;

export const Front = styled.div`
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background: white;
  z-index: 1;
`;

export const Back = styled.div`
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  transform: rotateY(180deg);
  background: ${(props) => props.color || '#0b76ef'};
  text-align: center;
  color: white;
  line-height: 20px;
  box-shadow: 0 0 0 1px ${(props) => props.color || '#0b76ef'};
  svg {
    margin-top: 3px;
    fill: none;
    path {
      stroke: white;
      stroke-width: 2.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
`;
