import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';

import { darken } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 600px;
  background: #fff;
  border-radius: 10px;

  @media (max-width: 1150px) {
    display: flex;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 2;
    border-radius: 0;
    padding: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  background: ${(props) => props.color};
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;

  @media (max-width: 1150px) {
    border-radius: 0;
  }
`;

export const GoBack = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;

  svg {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
`;

export const Space = styled(GoBack)``;

export const Body = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  color: #727c8e;
  overflow-y: auto;
`;

export const InputArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  input {
    margin-top: 20px;
    font-size: 18px;
    color: #727c8e;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 5px;
    transition: border 0.6s;
    width: 70%;

    &:focus {
      border: 1px solid #727c8e;
    }
  }
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 50%;
  background: ${(props) => props.color};
  box-shadow: 0px 2px 4px #00000050;
  border: 0;
  color: #fff;
  border-radius: 15px;
  font-weight: bold;
  transition: background 0.6s, box-shadow 0.6s;
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
  font-size: 16px;

  &:hover {
    background: ${(props) =>
      props.disable ? props.color : darken(0.18, props.color)};
    box-shadow: 0px 5px 7px #00000050;
  }
`;

export const Loading = styled.div`
  svg {
    animation: ${rotate} 2s infinite;
  }
`;
