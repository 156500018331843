export function handleCart(state) {
  return {
    type: 'HANDLE_CART',
    state,
  };
}

export function setCartVisible(state) {
  return {
    type: 'SET_CART_VISIBLE',
    payload: { state },
  };
}

export function handleLoading(state) {
  return {
    type: 'HANDLE_LOADING',
    state,
  };
}

export function handleMenuProfile() {
  return {
    type: 'HANDLE_PROFILE',
  };
}

export function handleAlterPassword(state) {
  return {
    type: 'HANDLE_ALTER_PASSWORD',
    state,
  };
}

export function handleHeader(state) {
  return {
    type: 'HANDLE_HEADER',
    state,
  };
}

export function handleFocusSearch(state) {
  return {
    type: 'HANDLE_FOCUS_SEARCH',
    state,
  };
}

export function handleMenuAddress(state) {
  return {
    type: 'HANDLE_MENU_ADDRESS',
    state,
  };
}

export function handleContact(state) {
  return {
    type: 'HANDLE_CONTACT',
    state,
  };
}

export function handleModalAbout(state) {
  return {
    type: 'HANDLE_MODAL_ABOUT',
    payload: { state },
  };
}
