export function addOptionToProduct(option, product) {
  return {
    type: 'ADD_OPTION_TO_PRODUCT',
    option,
    product,
  };
}

export function removeOption(option, product) {
  return {
    type: 'REMOVE_OPTION',
    option,
    product,
  };
}

export function changeOption(optionToRemove, item, product) {
  return {
    type: 'CHANGE_OPTION',
    optionToRemove,
    item,
    product,
  };
}

export function updateTemporaryAmount(uuid, amount) {
  return {
    type: 'UPDATE_TEMPORARY_AMOUNT_CART',
    uuid,
    amount,
  };
}

export function updateOptionAmount(uuid, itemId, amount, product) {
  return {
    type: 'UPDATE_OPTION_AMOUNT_PRODUCT',
    uuid,
    itemId,
    amount,
    product,
  };
}

export function sendToCart(product) {
  return {
    type: 'SEND_TO_CART',
    product,
  };
}
export function sendProductEditedToCart(product) {
  return {
    type: 'SEND_PRODUCT_EDITED_TO_CART',
    product,
  };
}

export function setTemporaryProduct(product) {
  return {
    type: 'SET_TEMPORARY_PRODUCT',
    product,
  };
}
