export function getAddressByGeolocation(latitude, longitude) {
  return {
    type: 'GET_ADDRESS_BY_GEOLOCATION',
    latitude,
    longitude,
  };
}

export function setDeliveryAddress(address) {
  return {
    type: 'SET_DELIVERY_ADDRESS',
    address,
  };
}

export function handleLoadingAddress(state) {
  return {
    type: 'HANDLE_LOADING_ADDRESS',
    state,
  };
}

export function handleLocationSelected(state) {
  return {
    type: 'HANDLE_LOCATION_SELECTED',
    state,
  };
}

export function handleSubmitAddressSelected(state) {
  return {
    type: 'HANDLE_SUBMIT_ADDRESS_SELECTED',
    state,
  };
}

export function findZipCode(zipCode) {
  return {
    type: 'FIND_ZIP_CODE',
    zipCode,
  };
}

export function setZipCode(zipCode) {
  return {
    type: 'SET_ZIP_CODE',
    zipCode,
  };
}

export function getGeolocation(
  name,
  street,
  neighbourhood,
  number,
  complement
) {
  return {
    type: 'GET_GEOLOCATION',
    name,
    currentStreet: street,
    currentNeighbourhood: neighbourhood,
    number,
    complement,
  };
}

export function setErrorMessage(error) {
  return {
    type: 'SET_ERROR_MESSAGE',
    error,
  };
}

export function getAddresses() {
  return {
    type: 'GET_ADDRESSES',
  };
}

export function setAddresses(addresses) {
  return {
    type: 'SET_ADDRESSES',
    addresses,
  };
}

export function handleCurrentAddress(address) {
  return {
    type: 'HANDLE_CURRENT_ADDRESS',
    payload: { address },
  };
}

export function setCurrentAddress(address) {
  return {
    type: 'SET_CURRENT_ADDRESS',
    address,
  };
}

export function setCustomerAddress(
  name,
  zipCode,
  street,
  complement,
  neighbourhood,
  city,
  state,
  number,
  latitude,
  longitude
) {
  return {
    type: 'SET_CUSTOMER_ADDRESS',
    name,
    zipCode,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
    latitude,
    longitude,
  };
}

export function handleCustomerAddress(
  name,
  zipCode,
  street,
  complement,
  neighbourhood,
  city,
  state,
  number
) {
  return {
    type: 'HANDLE_CUSTOMER_ADDRESS',
    name,
    zipCode,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
  };
}

export function setSubmitAddress(state) {
  return { type: 'SET_SUBMIT_ADDRESS', state };
}

export function deleteCustomerAddress(addressId) {
  return {
    type: 'DELETE_CUSTOMER_ADDRESS',
    addressId,
  };
}

export function handleEditAddress(state) {
  return { type: 'HANDLE_EDIT_ADDRESS', state };
}

export function handleUpdateAddress(
  zipCode,
  name,
  street,
  neighbourhood,
  city,
  state,
  number,
  complement
) {
  return {
    type: 'HANDLE_UPDATE_ADDRESS',
    zipCode,
    name,
    street,
    neighbourhood,
    city,
    state,
    number,
    complement,
  };
}

export function checkLocationArea(latitude, longitude) {
  return {
    type: 'CHECK_LOCATION_AREA',
    latitude,
    longitude,
  };
}

export function clearDelivery() {
  return { type: 'CLEAN_DELIVERY' };
}

export function getFreights(data) {
  return {
    type: 'GET_FREIGHTS',
    payload: { data },
  };
}

export function setFreights(freights) {
  return {
    type: 'SET_FREIGHTS',
    payload: { freights },
  };
}

export function handleModalFreight(state) {
  return {
    type: 'HANDLE_MODAL_FREIGHT',
    state,
  };
}

export function handleModalAddress(state) {
  return {
    type: 'HANDLE_MODAL_ADDRESS',
    state,
  };
}

export function createAddress(address) {
  return {
    type: 'CREATE_ADDRESS',
    payload: { address },
  };
}

export function handleLoadingFreight(state) {
  return {
    type: 'HANDLE_LOADING_FREIGHT',
    state,
  };
}
