import produce from 'immer';

const INITIAL_STATE = {
  freights: [],
  modalFreight: false,
  modalAddress: false,
  address: null,
  addresses: [],
  currentAddress: {},
  loadingAddress: true,
  locationSelected: false,
  submitAddressSelected: false,
  errorMessage: null,
  zipCode: '',
  submitAddress: false,
  editAddress: false,
  loadingFreight: false,
};

export default function delivery(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_DELIVERY_ADDRESS': {
        draft.address = action.address;
        break;
      }
      case 'HANDLE_LOADING_ADDRESS': {
        draft.loadingAddress = action.state;
        break;
      }
      case 'HANDLE_LOCATION_SELECTED': {
        draft.locationSelected = action.state;
        break;
      }
      case 'HANDLE_SUBMIT_ADDRESS_SELECTED': {
        draft.locationSelected = action.state;
        break;
      }
      case 'SET_ERROR_MESSAGE': {
        draft.errorMessage = action.error;
        break;
      }
      case 'SET_ADDRESSES': {
        draft.addresses = action.addresses;
        break;
      }
      case 'SET_CURRENT_ADDRESS': {
        draft.currentAddress = action.address;
        break;
      }
      case 'SET_ZIP_CODE': {
        draft.zipCode = action.zipCode;
        break;
      }
      case 'SET_SUBMIT_ADDRESS': {
        draft.submitAddress = action.state;
        break;
      }
      case 'HANDLE_EDIT_ADDRESS': {
        draft.editAddress = action.state;
        break;
      }
      case 'SIGN_OUT': {
        draft.address = null;
        draft.addresses = [];
        draft.zipCode = '';
        draft.errorMessage = null;
        break;
      }

      case 'SET_FREIGHTS': {
        draft.freights = action.payload.freights;
        break;
      }

      case 'HANDLE_MODAL_FREIGHT': {
        draft.modalFreight = action.state;
        break;
      }

      case 'HANDLE_MODAL_ADDRESS': {
        draft.modalAddress = action.state;
        break;
      }

      case 'HANDLE_LOADING_FREIGHT': {
        draft.loadingFreight = action.state;
        break;
      }

      default:
    }
  });
}
