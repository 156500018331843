import React from 'react';

import { connect } from 'react-redux';

import { AiOutlineLoading } from 'react-icons/ai';
import { TiLocationOutline } from 'react-icons/ti';
import { Container, FirstLoad, SecondLoad } from './styles';

function Loading({ color }) {
  return (
    <Container>
      <FirstLoad color={color}>
        <AiOutlineLoading />
      </FirstLoad>
      <SecondLoad>
        <TiLocationOutline />
      </SecondLoad>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  color: state.store.storeInfo.settings.color_primary,
});

export default connect(mapStateToProps)(Loading);
