import styled from 'styled-components';
import { darken } from 'polished';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 10px 10px #00000090;
  padding: 50px;

  @media (max-width: 1150px) {
    display: flex;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 2;
    overflow-y: auto;
    padding: 20px;
  }
`;

export const Form = styled.form`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const Email = styled.div`
  width: 100%;
  font-size: 16px;

  svg {
    margin-right: 5px;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

export const Password = styled.div`
  width: 100%;
  padding-top: 20px;

  svg {
    margin-right: 5px;
  }
`;

export const ForgetPassword = styled.span`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  cursor: pointer;
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

export const IconArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

export const Dot = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  background: #fff;
  align-items: center;

  @media (max-width: 550px) {
    margin-left: 10px;
  }
  @media (max-width: 400px) {
    margin-left: 5px;
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  flex: 1;
`;

export const ButtonEnter = styled.button.attrs({ type: 'submit' })`
  width: 100%;
  height: 40px;
  background: ${(props) => props.color};
  border-radius: 15px;
  color: #fff;
  box-shadow: 0px 2px 3px #00000050;
  transition: background 0.6s, box-shadow 0.6s;
  border: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.color && darken(0.07, props.color)};
    box-shadow: 1px 4px 5px #00000050;
  }

  svg {
    color: ${(props) => props.color};
  }

  @media (max-width: 420px) {
    padding: 0 10%;
    font-size: 20px;
  }
`;

export const RegisterFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;

    & + span {
      margin-left: 5px;
      font-weight: bold;
    }
  }
`;
