import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  height: auto;
  padding: 20px;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Continue = styled.div`
  flex: 1;
  font-style: italic;
  font-size: 14px;
  text-decoration-line: underline;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;

export const Product = styled.div`
  margin-top: 10px;
  width: 100%;
  padding: 20px 20px 0px 0px;
  display: flex;
  align-items: center;
  flex: 1;
  & + div {
    border-top: 1px solid #eeeeee;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const Image = styled.img.attrs({
  style: { height: 100, width: 100 },
})``;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  color: #000;
  flex: 2;
  padding: 0 20px;
  span {
    & + span {
      color: ${(props) => props.theme.colors.grayDark};
      font-weight: normal;
    }
  }
`;

export const ProductName = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const Options = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Option = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 5px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayDark};

  & + div {
    border-top: 1px solid #eee;
  }
`;

export const Price = styled.div`
  flex: 1;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #000;
  font-weight: bold;
`;
