import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  background: #fff;
  color: ${(props) => props.theme.colors.grayDark};
  transition: width 1s;

  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export const StoreInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;

  @media (max-width: 820px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const DeliveryTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  height: 100%;
  padding: 5px 10px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  @media (max-width: 820px) {
    margin-bottom: 5px;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const DeliveryTax = styled(DeliveryTime)``;

export const Status = styled(DeliveryTime)``;

export const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${(props) => (props.online ? '#2E7D32' : '#E53935')};
  margin-right: 10px;
`;

export const IconArea = styled.div`
  .opened {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
  .closed {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
`;

export const Online = styled.div`
  display: flex;
  align-items: center;
`;

export const Offline = styled(Online)``;

export const About = styled(DeliveryTime)`
  cursor: pointer;
  transition: border 0.6s;

  &:hover {
    border: 1px solid ${(props) => props.color};
  }
`;
