import React from 'react';

import { Input, Cbx, Flip, Front, Back } from './styles';

export default function FlipCheckBox({ id, checked, onChange, color }) {
  return (
    <>
      <Input
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        id={id}
        type="checkbox"
        color={color}
      />
      <Cbx color={color} className="cbx" htmlFor={id}>
        <Flip className="flip">
          <Front className="front" />
          <Back color={color} className="back">
            <svg width="16" height="14" viewBox="0 0 16 14">
              <path d="M2 8.5L6 12.5L14 1.5" />
            </svg>
          </Back>
        </Flip>
      </Cbx>
    </>
  );
}
