import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GoClippy } from 'react-icons/go';
import { RiLockPasswordLine, RiRoadMapLine } from 'react-icons/ri';
import { IoIosArrowDown } from 'react-icons/io';
import { FiLogOut } from 'react-icons/fi';

import swal from 'sweetalert';

import history from '../../../../services/history';

import { getStoreSlug } from '../../../../util/StoreUtil';
import * as MainActions from '../../../../store/modules/main/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import * as AuthActions from '../../../../store/modules/auth/actions';

import {
  Container,
  MenuProfileContainer,
  HeaderProfile,
  IconArea,
  Title,
  Header,
  Body,
  Menu,
} from './styles';

function MenuProfile({
  menuProfileVisible,
  profile,
  signed,
  color,
  handleMenuProfile,
  handleAlterPassword,
  align,
  handleMenuAddress,
  oldCurrentParentId,
  oldCurrentCategoryId,
  restrictDeliveryArea,
  cart,
  signOutRequest,
}) {
  async function handleSignOut() {
    await signOutRequest();
    // history.push(`/${getStoreSlug()}`);
    // if (window.location.pathname.split('/')[1] === `/${getStoreSlug()}`) {
    //   window.location.reload();
    // }
  }

  function handleConfirmation() {
    if (cart.length > 0) {
      swal({
        title: 'Deseja realmente sair?',
        text:
          'Notamos que você ainda possui itens no carrinho, ao sair seu carrinho será limpo. ',
        icon: 'warning',
        buttons: ['Ficar', 'Sair'],
      }).then((confirmation) => {
        if (confirmation) {
          handleSignOut();
        }
      });
    } else {
      handleSignOut();
    }
    return true;
  }

  function navigation() {
    handleMenuProfile();
    history.push(`/${getStoreSlug()}/pedidos`);
  }

  function handlePassword() {
    handleMenuProfile();
    handleAlterPassword(true);
  }

  function handleAddress() {
    handleMenuAddress(true);
    handleMenuProfile();
  }

  function handleCloseModal(event) {
    if (event.currentTarget === event.target) {
      handleMenuProfile();
    }
  }

  const name = profile.name && profile.name.split(' ');

  return !signed || !menuProfileVisible ? null : (
    <Container
      oldCurrentParentId={oldCurrentParentId}
      oldCurrentCategoryId={oldCurrentCategoryId}
      onClick={(event) => handleCloseModal(event)}
    >
      <MenuProfileContainer align={align}>
        <HeaderProfile color={color}>
          <IconArea>
            <IoIosArrowDown onClick={() => handleMenuProfile()} />
          </IconArea>
          <Title>Perfil</Title>
          <IconArea />
        </HeaderProfile>
        <Header>
          {!profile.name ? <h3>Olá</h3> : <h3>Olá, {name[0]}</h3>}
        </Header>
        <Body>
          {restrictDeliveryArea && (
            <Menu color={color} onClick={() => handleAddress()}>
              <RiRoadMapLine />
              Endereços
            </Menu>
          )}
          <Menu color={color} onClick={() => navigation()}>
            <GoClippy />
            Pedidos
          </Menu>
          {/* <AlterData>
          <GoGear />
          Alterar dados
        </AlterData> */}
          <Menu color={color} onClick={() => handlePassword()}>
            <RiLockPasswordLine />
            Alterar senha
          </Menu>
          <Menu color={color} onClick={() => handleConfirmation()}>
            <FiLogOut />
            Sair
          </Menu>
        </Body>
      </MenuProfileContainer>
    </Container>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...MainActions, ...CartActions, ...AuthActions },
    dispatch
  );

const mapStateToProps = (state) => ({
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
  menuProfileVisible: state.main.menuProfileVisible,
  profile: state.user.profile,
  signed: state.auth.signed,
  color: state.store.storeInfo.settings.color_primary,
  oldCurrentParentId: state.groups.oldCurrentParentId,
  oldCurrentCategoryId: state.groups.oldCurrentCategoryId,
  cart: state.cart.products.map((product) => product),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuProfile);
