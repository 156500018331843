import styled from 'styled-components';

export const CityArea = styled.div`
  display: flex;
  padding-top: 10px;
`;

export const City = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;

  input {
    font-size: 16px;
    color: #727c8e;
    text-align: center;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 5px;
    transition: border 0.6s;

    &:focus {
      border: 1px solid #727c8e;
    }
  }
`;

export const State = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-left: 5%;

  input {
    font-size: 16px;
    color: #727c8e;
    text-align: center;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 5px;
    transition: border 0.6s;

    &:focus {
      border: 1px solid #727c8e;
    }
  }
`;
