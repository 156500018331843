import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: rotate(360deg);
    opacity: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100px;

  svg {
    width: 25px;
    height: 25px;
  }

  .loading {
    animation: ${rotate} 1s infinite;
    height: 50px;
    width: 50px;
    position: absolute;
    color: ${(props) => props.color};
  }
`;
