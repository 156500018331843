import React from 'react';

import { IoMdPricetags } from 'react-icons/io';

import { formatPrice } from '../../../../util/format';

import { Container, Values, Tax, TotalFooter } from './styles';
import { SubTitle } from '../../styles';

function Total({
  total,
  currentFreight,
  verifyService,
  deliveryTax,
  totalOrder,
}) {
  return (
    <Container>
      <SubTitle>
        <IoMdPricetags size={16} />
        <span>Total</span>
      </SubTitle>
      <Values>
        <span>Produtos</span>
        {total}
      </Values>
      {currentFreight && (
        <Values>
          <span>Entrega ( {verifyService(currentFreight.code)} )</span>{' '}
          {formatPrice(parseFloat(currentFreight.price), 2)}
        </Values>
      )}
      {deliveryTax && (
        <Tax>
          <span>Taxa de entrega</span>
          {formatPrice(deliveryTax, 2)}
        </Tax>
      )}
      <TotalFooter>
        <span>Total</span> {formatPrice(totalOrder, 2)}
      </TotalFooter>
    </Container>
  );
}

export default Total;
