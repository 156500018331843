import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 90px;
  width: 100%;
  justify-content: center;
  color: ${(props) => props.theme.colors.grayDark};

  @media print {
    display: none;
    box-shadow: none;
  }
`;

export const Header = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  font-weight: bold;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 15px;
  }
`;

export const Number = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  border-right: 1px solid #eee;

  @media (max-width: 350px) {
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  svg {
    margin-right: 10px;
  }
`;

export const Date = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  border-right: 1px solid #eee;

  @media (max-width: 350px) {
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  svg {
    margin-right: 10px;
  }
`;

export const Status = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  border-right: 1px solid #eee;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TotalPrice = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 350px) {
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  svg {
    margin-right: 10px;
  }
`;

export const HeaderDetail = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ListITems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 100%;
  margin: 0 20px;
  list-style-type: none;
  border-radius: 15px;
  border: 0;
  box-shadow: 0px 15px 15px #80828b26;
  transition: box-shadow 0.6s;

  &:hover {
    box-shadow: 10px 10px 10px #80828b30;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const ItemArea = styled.div`
  width: 100%;
  flex: column;
`;

export const Item = styled.div`
  display: flex;
  height: 12px;
  width: 100%;
  padding: 30px;
  border-top: 1px solid #eee;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    padding: 15px;
    flex-direction: column;
  }
`;

export const AreaValues = styled.div`
  display: flex;
  flex: 4;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AreaMiddle = styled.div`
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    display: flex;
  }

  @media (max-width: 576px) {
    width: 100%;

    div {
      width: 100%;
    }
  }
`;

export const AreaDetail = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const OrderNumber = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const OrderDate = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const OrderDetails = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    flex-direction: column;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const AreaStatus = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Total = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const StatusPayment = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => {
    switch (props.status) {
      case 'Aprovada': {
        return '#b2dbb2';
      }
      case 'Aguardando Pagamento': {
        return '#fcf1de';
      }
      case 'Em Processamento': {
        return '#fcf1de';
      }
      case 'Em Contestação': {
        return '#fcf1de';
      }
      case 'Em Monitoramento': {
        return '#fcf1de';
      }
      case 'Cancelada': {
        return '#edafa4';
      }
      case 'Reprovada	': {
        return '#edafa4';
      }
      default:
        return '#edafa4';
    }
  }};
  color: ${(props) => {
    switch (props.status) {
      case 'Aprovada': {
        return '#609b40';
      }
      case 'Aguardando Pagamento': {
        return '#cc7912';
      }
      case 'Em Processamento': {
        return '#cc7912';
      }
      case 'Em Contestação': {
        return '#cc7912';
      }
      case 'Em Monitoramento': {
        return '#cc7912';
      }
      case 'Cancelada': {
        return '#c43232';
      }
      case 'Reprovada	': {
        return '#c43232';
      }
      default:
        return '#c43232';
    }
  }};
`;

export const OrderStatus = styled.div`
  display: flex;
  border: 0;
  border: 1px solid ${(props) => props.status === 'aprovado' && '#609b40'};
  border: 1px solid ${(props) => props.status === 'pendente' && '#cc7912'};
  border: 1px solid ${(props) => props.status === 'cancelado' && '#c43232'};
  background: ${(props) => props.status === 'aprovado' && '#b2dbb2'};
  background: ${(props) => props.status === 'pendente' && '#fcf1de'};
  background: ${(props) => props.status === 'cancelado' && '#edafa4'};
  color: ${(props) => props.status === 'aprovado' && '#609b40'};
  color: ${(props) => props.status === 'pendente' && '#cc7912'};
  color: ${(props) => props.status === 'cancelado' && '#c43232'};
  border-radius: 5px;
  padding: 3px 5px;
  font-weight: bold;
  font-size: 14px;
  justify-content: center;
  width: 100px;

  @media (max-width: 768px) {
    font-size: 14px;
    width: 90px;

    padding: 1px 0;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const WithOutOrders = styled.div`
  width: 100%;
  padding: 50px;
  text-align: center;
`;
