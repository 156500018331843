import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

import history from '../../../services/history';
import { getStoreSlug } from '../../../util/StoreUtil';

import api from '../../../services/api';

import {
  signInSuccess,
  handleLoginModal,
  signFailure,
  signOut,
} from './actions';
import { handleRecoveryPassword } from '../user/actions';
import { cleanCustomerData } from '../customer/actions';

export function* signIn({ payload }) {
  const { email, password } = payload;

  const response = yield call(api.Login, email, password);

  if (response.status === 400) {
    toast.error('Usuário ou senha incorretos');
    return yield put(signFailure());
  }
  if (response.status === 403) {
    swal(
      'Opss...!',
      'Seu cadastro ainda está em análise. \n Assim que aprovado, você será notificado.',
      'warning'
    );
    yield put(handleLoginModal());
    return yield put(signFailure());
  }
  if (response.status === 404) {
    toast.error('Usuário não encontrado');
    return yield put(signFailure());
  }

  const user = response.data.customer;

  const { token } = response.data;

  api.getApiInstance().defaults.headers.Authorization = `Bearer ${token}`;

  yield put(signInSuccess(token, user));

  const address = yield select((state) => state.delivery.address);

  if (!address) {
    return false;
  }
  const {
    name,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
    latitude,
    longitude,
  } = address;

  let { zipCode } = address;

  zipCode = zipCode.replace(/\D/g, '').replace(/^(\d{5})(\d{3})/g, '$1-$2');
  const customerId = response.data.customer.id;

  if (address && address.number) {
    return yield api.createCustomerAddress(
      name,
      zipCode,
      street,
      complement,
      neighbourhood,
      city,
      state,
      number,
      latitude,
      longitude,
      customerId
    );
  }
  return true;
}

export function* signUp({ payload }) {
  try {
    const {
      name,
      document,
      phone,
      cellphone,
      email,
      password,
      confirmPassword,
      newsLetter,
      acceptContract,
    } = payload;

    const response = yield call(
      api.CreateUser,
      name,
      document,
      phone,
      cellphone,
      email,
      password,
      confirmPassword,
      newsLetter,
      acceptContract
    );

    if (response && response.response && response.response.status === 400) {
      return toast.error(response.response.data.error);
    }

    if (response && response.status && response.status === 200) {
      yield put(cleanCustomerData());
      if (response.data.register_aproved) {
        return yield put({
          type: 'SIGN_IN_REQUEST',
          payload: { email, password },
        });
      }
      return swal(
        'Sucesso!',
        'Seu cadastro foi enviado para analise!\nEm breve entraremos em contato.',
        'success'
      );
    }
    return true;
  } catch (error) {
    toast.error('Falha no cadastro, verifique seus dados.');
    return yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.getApiInstance().defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* changePassword({ payload }) {
  const { password, token } = payload;
  const response = yield api.changePassword(password, token);
  if (response.response && response.response.status === 404) {
    return swal({
      text: 'Problema ao recuperar sua senha, tente novamente mais tarde.',
      icon: 'warning',
      button: 'Ok',
    }).then((confirmation) => {
      if (confirmation) {
        history.push(`/${getStoreSlug()}`);
      }
    });
  }
  if (response.response && response.response.status === 401) {
    return swal({
      text: response.response.data.message,
      icon: 'warning',
      button: 'Ok',
    }).then((confirmation) => {
      if (confirmation) {
        history.push(`/${getStoreSlug()}`);
      }
    });
  }

  return swal({
    text: response.data.message,
    icon: 'success',
    button: 'Ok',
  }).then((confirmation) => {
    if (confirmation) {
      put(handleRecoveryPassword(false));
      history.push(`/${getStoreSlug()}`);
    }
  });
}

export function* signOutRequest() {
  api.getApiInstance().defaults.headers.Authorization = '';
  yield put(signOut());
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('SIGN_IN_REQUEST', signIn),
  takeLatest('SIGN_UP_REQUEST', signUp),
  takeLatest('CHANGE_PASSWORD', changePassword),
  takeLatest('SIGN_OUT_REQUEST', signOutRequest),
]);
