import React from 'react';

import { connect } from 'react-redux';

import { AiOutlineLoading } from 'react-icons/ai';
import i9p from '../../../../../assets/images/I9P-01.svg';

import { Container, FirstLoad, SecondLoad, Image } from './styles';

function LoadingI9p({ loading }) {
  return (
    <Container loading={loading} height={window.innerHeight}>
      <FirstLoad>
        <AiOutlineLoading />
      </FirstLoad>
      <SecondLoad>
        <Image image={i9p} />
      </SecondLoad>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  loading: state.main.loading,
});
export default connect(mapStateToProps)(LoadingI9p);
