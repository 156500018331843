export function getAlerts() {
  return {
    type: 'GET_ALERTS',
  };
}

export function setAlerts(alerts) {
  return {
    type: 'SET_ALERTS',
    payload: { alerts },
  };
}
