import produce from 'immer';

const INITIAL_STATE = {
  alerts: [],
};

export default function alerts(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_ALERTS': {
        draft.alerts = action.payload.alerts;
        break;
      }

      default:
    }
  });
}
