import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 5px 3px 6px 0px #00000029;
  transition: width 0.6s;
  width: ${(props) => (props.cartVisible ? '80%' : '100%')};
  padding: 20px;

  @media (max-width: 1150px) {
    padding-bottom: 120px;
    width: 100%;
  }
`;
