import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  from {
    opacity: 0;
    right: -10px;
  }

  to {
    opacity: 1;
    right: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${animation} 0.6s;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 20px;
  align-items: center;
  background: ${(props) => props.color};
  color: #fff;
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonBackArea = styled.div`
  display: flex;
  flex: 1;

  svg {
    height: 30px;
    width: 30px;
  }
`;

export const Address = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.grayDark};
  @media (max-height: 768px) {
    font-size: 14px;
  }
`;

export const CurrentAddress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 10px 20px;
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

export const SubTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

export const IconArea = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  svg {
    display: none;
  }

  @media (max-width: 576px) {
    svg {
      display: flex;
    }
  }
`;

export const ButtonConfirm = styled.button.attrs({ type: 'submit' })`
  width: 80%;
  height: 40px;
  border: 0;
  background: ${(props) => props.color};
  box-shadow: 0px 2px 4px #00000050;
  font-size: 16px;
  transition: box-shadow 0.6s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
  }

  @media (max-height: 768px) {
    height: 30px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  font-weight: normal;

  span + span {
    font-size: 20px;
  }
`;

export const ZipCodeArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 22px;
  border-bottom: 1px solid #eee;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors.grayDark};
  font-style: italic;
  font-size: 14px;
  text-decoration-line: underline;
  padding: 20px 0;

  span {
    cursor: pointer;
  }
`;
