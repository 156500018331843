import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const animate = keyframes`
  from {
    top: -10px;
    opacity: 0;
  }

  to {

    top: 0px;
    opacity: 1;
  }
`;

const rigthToLeft = keyframes`
  from {
    right: -10px;
    opacity: 0;
  }

  to {

    right: 0px;
    opacity: 1;
  }
`;

export const Container = styled.div`
  border-radius: 5px;
  width: 100%;
  background: #fff;
  padding: 20px 40px 20px 20px;
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const DeliveryTypes = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  color: ${(props) => props.theme.colors.grayDark};
  animation: ${animate} 0.6s;
  flex-wrap: wrap;
  border: 1px solid #eee;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  flex-wrap: wrap;
`;

export const Type = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.grayDark};
  padding: 20px;

  img {
    width: 50px;
  }
`;

export const Correios = styled.div`
  display: flex;
  position: relative;
  animation: ${rigthToLeft} 0.6s;
  padding: 10px;
  width: 100%;

  svg {
    cursor: pointer;
  }
`;

export const AddressesArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Freights = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Freight = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;

  @media (max-width: 600px) {
    flex-direction: column;
    & + div {
      border-top: 1px solid #eee;
    }
  }

  @media (max-width: 576px) {
    align-items: center;
  }
`;

export const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.2;

  @media (max-width: 600px) {
    display: none;
  }
`;
export const Address = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 5px;
  text-align: center;

  .nameMobile {
    display: none;
  }

  strong {
    margin-left: 5px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    .nameMobile {
      display: flex;
    }

    .date {
      padding: 5px 0;
    }
  }
`;

export const Value = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.2;

  input {
    margin-left: 10px;
  }
`;

export const Addresses = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 420px) {
    padding: 0 10px 10px 0;
  }
`;

export const AddressArea = styled.div`
  display: flex;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin: 20px 0 0 20px;

  span {
    &:first-child {
      font-weight: bold;
      color: #000;
    }
  }

  @media (max-width: 420px) {
    margin: 10px 0 0 10px;
  }
`;

export const CheckBoxArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const AddressInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WithouAddress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  span {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const ButtonNewAddressArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonNewAddress = styled.div`
  background: ${(props) => props.color};
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 4px #00000050;
  cursor: pointer;
  transition: all 0.6s;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
    background: ${(props) => darken(0.1, props.color)};
  }
`;

export const CardArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 576px) {
    font-size: 14px;
    flex-direction: column;
  }
`;

export const Method = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000;
  padding: 10px;
  background: ${(props) => (props.current ? props.color : '#fff')};
  color: ${(props) => props.current && '#fff'};
  font-weight: bold;
  transition: background 0.6s, color 0.2s;
  margin-top: 10px;
  text-align: center;
  border: 1px solid #eee;
  width: 100%;
  border-radius: 10px 10px 0 0;
`;

export const HeaderFreight = styled.div`
  display: flex;
  padding: 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;

  div {
    flex: 0.2;

    &:nth-child(2) {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const OnwDelivery = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
`;
