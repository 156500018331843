import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { getStoreSlug } from '../../util/StoreUtil';

import api from '../../services/api';
import Header from '../components/Header';

// import Header from './components/Header';
import ListOrders from './components/ListOrders';
import OrderItems from './components/OrderItems';
import MenuProfile from '../Main/components/MenuProfile';
import AlterPassword from '../Main/components/AlterPassword';
import MenuAddress from '../Main/components/MenuAddress';

import ModalTracks from './components/ModalTracks';

import Metas from '../components/Metas';

import imageDefault from '../../assets/images/default.png';

import { Container, Title } from './styles';

function Orders({ signed, history }) {
  if (!signed) {
    history.push(`/${getStoreSlug()}`);
  }

  const [orders, setOrders] = useState([]);
  const [image, setImage] = useState(imageDefault);
  const [total, setTotal] = useState('');
  const [orderItems, setOrdersItems] = useState([]);
  const [loadingOrdersVisible, setLoadingOrdersVisible] = useState(false);
  const [loadingItemsVisible, setLoadingItemsVisible] = useState(false);
  const [itemsVisible, setitemsVisible] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});
  const [modalTrackVisible, setModalTrackVisible] = useState(false);

  async function loadOrders() {
    setLoadingOrdersVisible(true);
    const response = await api.getOrders();
    setOrders(response.data);
    const Store = await api.getStore();
    setImage(Store && Store.data && Store.data.settings.logo_url);

    setLoadingOrdersVisible(false);
  }
  useEffect(() => {
    document.title = `I9P - ${getStoreSlug()} - Pedidos`;

    loadOrders();
  }, []);

  useEffect(() => {
    const root = document.getElementsByTagName('body')[0];
    if (itemsVisible) {
      root.classList.add('no-scroll');
    } else {
      root.classList.remove('no-scroll');
    }
  }, [itemsVisible]);

  async function handleDetails(OrderId) {
    setLoadingItemsVisible(true);
    const details = await api.getOrders(OrderId);
    setOrdersItems(details.data);

    setLoadingItemsVisible(false);
  }

  return (
    <>
      <Metas />
      <Header image={image} history={history} handleBackArrow />
      <Container>
        <Title>Pedidos</Title>
        <AlterPassword />
        <MenuProfile />
        <MenuAddress />
        {modalTrackVisible && (
          <ModalTracks
            setModalTrackVisible={setModalTrackVisible}
            modalTrackVisible={modalTrackVisible}
          />
        )}
        <ListOrders
          setTotal={setTotal}
          setitemsVisible={setitemsVisible}
          loadingVisible={loadingOrdersVisible}
          orders={orders}
          setCurrentOrder={setCurrentOrder}
          handleDetails={handleDetails}
        />
        {itemsVisible && (
          <OrderItems
            total={total}
            setitemsVisible={setitemsVisible}
            itemsVisible={itemsVisible}
            orderItems={orderItems}
            setLoadingItemsVisible={setLoadingItemsVisible}
            loadingItemsVisible={loadingItemsVisible}
            currentOrder={currentOrder}
            setModalTrackVisible={setModalTrackVisible}
          />
        )}
      </Container>
    </>
  );
}
const mapStateToProps = (state) => ({
  signed: state.auth.signed,
});
export default connect(mapStateToProps)(Orders);
