import produce from 'immer';

const INITIAL_STATE = {
  storeInfo: {
    name: '',
    street: '',
    description: '',
    number: '',
    city: '',
    work_period: [],
    settings: {
      logo_url: null,
      banner_url: null,
      color_primary: '#43A047',
      order_minimun_value: null,
      price_public: false,
      onwDelivery: false,
    },
  },
  minTime: null,
  maxTime: null,
  delivery_tax: null,
  deliveryPaymentsMethods: [],
  deliveryPaymentsCards: [],
};

export default function store(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_STORE_INFO': {
        draft.storeInfo = action.payload;
        break;
      }
      case 'SET_MIN_TIME': {
        draft.minTime = action.payload.time;
        break;
      }
      case 'SET_MAX_TIME': {
        draft.maxTime = action.payload.time;
        break;
      }
      case 'SET_DELIVERY_TAX': {
        draft.delivery_tax = action.payload.deliveryTax;
        break;
      }

      case 'SIGN_OUT': {
        draft.minTime = null;
        draft.maxTime = null;
        draft.delivery_tax = null;
        break;
      }

      case 'SET_ONW_DELIVERY': {
        draft.storeInfo.settings.onwDelivery = action.payload.status;
        break;
      }

      default:
    }
  });
}
