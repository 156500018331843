import styled from 'styled-components';

import { darken } from 'polished';

import { Container, ImageHeader } from '../components/Header/styles';

export const ContainerRecovery = styled.div``;

export const Header = styled(Container)``;

export const Image = styled(ImageHeader)``;

export const Body = styled.div`
  padding: ${(props) => (props.headerVisible ? '120px' : '0px')} 20px 20px 20px;
  color: #727c8e;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 26px;
  font-weight: bold;
  padding: 20px;
`;

export const Password = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  span {
    padding: 5px;
  }
`;

export const NewPassword = styled(Password)``;

export const Input = styled.input.attrs({ type: 'password' })`
  width: 400px;
  font-size: 16px;
  color: #727c8e;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 5px;
  transition: border 0.6s;

  &:focus {
    border: 1px solid #727c8e;
  }

  @media (max-width: 576px) {
    width: 80%;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 150px;
  height: 30px;
  border: 0;
  background: ${(props) => props.color};
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.6s;

  &:hover {
    background: ${(props) => darken(0.18, props.color)};
  }
`;
