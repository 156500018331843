import styled from 'styled-components';
import Modal from 'react-modal';
import { darken } from 'polished';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  background: #00000050;
  z-index: 5;
  justify-content: center;
  align-items: center;
`;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 600px;
  background: #fff;
  border-radius: 10px;

  @media (max-width: 1150px) {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    top: 50%;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px 0;

  @media (max-width: 1150px) {
    border-radius: 0px;
    padding: 10px 0;
    max-height: 50px;
  }
`;

export const IconArea = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px;

  svg {
    display: none;
    height: 30px;
    width: 30px;

    @media (max-width: 1150px) {
      display: flex;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  flex: 3;
`;
export const Space = styled.div`
  display: flex;
  flex: 1;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  padding: 20px;
  color: ${(props) => props.theme.colors.grayDark};

  @media (max-width: 576px) {
    padding: 50px 20px;
  }
`;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const SubmitButton = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

export const Button = styled.button.attrs({ type: 'submit' })`
  height: 40px;
  border-radius: 15px;
  width: 100%;
  border: 0;
  align-self: center;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  background: ${(props) => props.color};
  transition: all 0.6s;
  box-shadow: 0px 2px 4px #00000050;

  &:hover {
    background: ${(props) => darken(0.1, props.color)};
    box-shadow: 2px 4px 6px #00000050;
  }
`;
