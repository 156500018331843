import { combineReducers } from 'redux';

import cart from './cart/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import main from './main/reducer';
import store from './store/reducer';
import groups from './groups/reducer';
import delivery from './delivery/reducer';
import alerts from './alerts/reducer';
import temporaryCart from './temporaryCart/reducer';
import customer from './customer/reducer';
import order from './order/reducer';

export default combineReducers({
  auth,
  user,
  cart,
  main,
  store,
  groups,
  delivery,
  alerts,
  temporaryCart,
  customer,
  order,
});
