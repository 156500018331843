import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Routes from './routes';
import history from './services/history';
import { theme } from './styles/theme';
import i9p from './assets/images/logo-symbol-01-1024-white.jpg';

import { store, persistor } from './store';

import GlobalStyle from './styles/global';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Helmet>
              <title>I9P | Seu pedido começa aqui!</title>

              <meta name="I9P" content="I9P | Seu pedido começa aqui!" />

              <link rel="canonical" href="https://www.i9p.app" />
              <meta
                id="meta-description"
                name="description"
                content="Soluções para pedidos B2B e Delivery com a marca do seu negócio.
                Agilize o processo e aumente suas vendas com as soluções da I9P."
              />
              <meta name="country" content="br" />
              <meta name="language" content="pt-BR" />

              <meta name="apple-mobile-web-app-capable" content="yes" />
              <meta
                name="apple-mobile-web-app-status-bar-style"
                content="white"
              />

              {/* <!-- Google / Search Engine Tags --> */}
              <meta itemProp="name" content="I9P | Seu pedido começa aqui!" />
              <meta
                itemProp="description"
                content="Soluções para pedidos B2B e Delivery com a marca do seu negócio.
                Agilize o processo e aumente suas vendas com as soluções da I9P."
              />
              <meta itemProp="image" content={i9p} />

              {/* <!-- Twitter Meta Tags --> */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:title"
                content="I9P | Seu pedido começa aqui!"
              />
              <meta
                name="twitter:description"
                content="Soluções para pedidos B2B e Delivery com a marca do seu negócio.
                Agilize o processo e aumente suas vendas com as soluções da I9P."
              />
              <meta name="twitter:image" content={i9p} />

              {/* <!-- Facebook Meta Tags --> */}
              <meta property="og:type" content="website" />
              <meta
                property="og:title"
                content="I9P | Seu pedido começa aqui!"
              />
              <meta property="og:image" content={i9p} />
              <meta property="og:image:alt" content="I9P" />
              <meta property="og:url" content="https://www.i9p.app" />
              <meta
                property="og:description"
                content="Soluções para pedidos B2B e Delivery com a marca do seu negócio.
                Agilize o processo e aumente suas vendas com as soluções da I9P."
              />

              <meta property="fb:app_id" content="251673356218544" />

              <meta
                name="keywords"
                content="i9p, I9P, i9p Tecnologia, I9P TECNOLOGIA,i9p seu pedido começa aqui , i9p pedidos, pedidos i9p, Tecnologia i9p, i9p delivery,delivery, DELIVERY, delivery i9p, plataforma i9p, i9p plataforma, i9p sistema de pedidos e delivery, pedidos, pedidos e delivery, i9p plataforma de pedidos, i9p tecnologia delivery, tecnologia i9p delivery, pedidos e delivery, PEDIDOS E DELIVERY, i9p APP, APP i9p, i9p app, app i9p, app para pedidos, app de pedidos, app de delivery, app para delivery, sistema i9p, sistema de delivery, sistema de pedidos, sistema de pedidos e delivery "
              />
            </Helmet>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
