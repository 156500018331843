import React from 'react';

import { AiOutlineLoading } from 'react-icons/ai';

import { ReactComponent as TruckIcon } from '../../../../../../assets/icons/delivery-truck-location.svg';

import { Container, FirstLoad, SecondLoad } from './styles';

function Loading() {
  return (
    <Container>
      <FirstLoad>
        <AiOutlineLoading />
      </FirstLoad>
      <SecondLoad>
        <TruckIcon />
      </SecondLoad>
    </Container>
  );
}

export default Loading;
