export function handleForceChangePassword(state) {
  return {
    type: 'HANDLE_FORCE_CHANGE_PASSWORD',
    payload: { state },
  };
}

export function handleRecoveryPassword(state) {
  return {
    type: 'HANDLE_RECOVERY_PASSWORD',
    payload: { state },
  };
}

export function requestRecoveryPassword(email) {
  return {
    type: 'REQUEST_RECOVERY_PASSWORD',
    email,
  };
}
export function handleLoadingSendEmail(state) {
  return {
    type: 'HANDLE_LOADING_SEND_EMAIL',
    payload: { state },
  };
}

export function getCustomer(id) {
  return {
    type: 'GET_CUSTOMER',
    payload: { id },
  };
}

export function setCustomer(customer) {
  return {
    type: 'SET_CUSTOMER',
    payload: { customer },
  };
}

export function updateCustomer(address) {
  return {
    type: 'UPDATE_CUSTOMER',
    address,
  };
}

export function setProfileBirthDate(birthDate) {
  return {
    type: 'SET_BIRTH_DATE',
    birthDate,
  };
}

export function setLoadingCustomer(state) {
  return { type: 'SET_LOADING_CUSTOMER', payload: { state } };
}
