import styled, { keyframes } from 'styled-components';

const animate = keyframes`
  from {
    top: -10px;
    opacity: 0;
  }

  to {

    top: 0px;
    opacity: 1;
  }
`;

export const Container = styled.div`
  border-radius: 5px;
  width: 100%;
  background: #fff;
  padding: 20px;
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  /* Block select text */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const TabsArea = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
`;

export const TabPayments = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const TabPayment = styled.div`
  display: flex;
  border-bottom: 2px solid ${(props) => (props.payment ? props.color : '#eee')};
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;

  & + div {
    margin-left: 20px;
  }
`;

export const CreditCardInfo = styled.form`
  animation: ${animate} 0.6s;
  padding: 20px;
  border: 1px solid ${(props) => props.color};
  border-top: 0;
  border-radius: 0 0 10px 10px;
`;

export const Cards = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 20px 0;

  @media (max-width: 576px) {
    display: none;
  }
`;

export const CreditCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    height: 50px;
    width: 50px;
  }
`;

export const Border = styled.div`
  width: ${(props) => (props.currentCreditCard ? `100%` : 0)};
  transition: all 0.6s;
  border-bottom: ${(props) =>
    props.currentCreditCard ? ` 3px solid ${props.color}` : 0};
`;

export const OnlinePayment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: ${animate} 0.6s;
`;

export const DataArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 0;
`;

export const IncorrectNumber = styled.span`
  font-size: 12px;
  color: #f00;
  margin-top: 5px;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 45%;
  }
`;

export const BankBillArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px;
  animation: ${animate} 0.6s;
  border: 1px solid ${(props) => props.color};
  border-top: 0;
  border-radius: 0 0 10px 10px;

  svg {
    margin-left: 20px;
    height: 50px;
    width: 50px;
    cursor: pointer;
  }
`;

export const PaymentBody = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${animate} 0.6s;

  @media (max-width: 576px) {
    flex-direction: row;
  }
`;

export const CardArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 576px) {
    font-size: 14px;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  height: 70px;
  transition: all 0.3s;

  input {
    margin-right: 10px;
  }
`;

export const Method = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000;
  padding: 10px;
  background: ${(props) => (props.current ? props.color : '#fff')};
  color: ${(props) => (props.current ? '#fff' : props.theme.colors.grayDark)};
  font-weight: bold;
  transition: background 0.6s, color 0.2s;
  margin-top: 10px;
  text-align: center;
  border: 1px solid ${(props) => props.color};
  width: 100%;
  border-radius: 10px 10px 0 0;
`;

export const Change = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.color};
  border-top: 0;
  padding: 20px 0;
  border-radius: 0 0 10px 10px;
  flex-direction: column;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const CheckBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const ResponseArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${animate} 0.6s;
  position: relative;
`;

export const CardOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.grayDark};
  animation: ${animate} 0.6s;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.color};
  border-top: 0;
  border-radius: 0 0 10px 10px;
  padding: 40px 0;

  img {
    width: 50px;
    margin-bottom: 5px;
  }

  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;

export const InputArea = styled.div`
  margin-left: 10px;
  width: 150px;
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
`;

export const OtherCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
