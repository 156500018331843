import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import unregisterServiceWork from '../../unregisterServiceWork';

import * as GroupActions from '../../store/modules/groups/actions';
import * as StoreActions from '../../store/modules/store/actions';
import * as MainActions from '../../store/modules/main/actions';
import * as CartActions from '../../store/modules/cart/actions';
import * as AlertsActions from '../../store/modules/alerts/actions';

import DeliveryHeader from './components/DeliveryHeader';
import Header from './components/Header';
import DeliveryInfo from './components/DeliveryInfo';
// import Information from './components/Information';
import Banner from './components/Banner';
import Category from './components/Category';
import Group from './components/Group';
import Cart from './components/Cart';
import TabCart from './components/TabCart';
import Tab from './components/Tab';
import Loading from './components/Loadings/I9p';
import LoginRegister from './components/LoginRegister';
import MenuProfile from './components/MenuProfile';
import AlterPassword from './components/AlterPassword';
import MenuAddress from './components/MenuAddress';
import ProductDetail from './components/ProductDetail';
import Footer from './components/Footer';
import Contact from './components/Contact';
import About from './components/About';
import Alert from './components/Alert';
import RegistryComplementary from './components/LoginRegister/components/RegistryComplementary';

import Metas from '../components/Metas';

import { Container, Body, Apresentation } from './styles';

function Main({
  getStoreInfo,
  getAlerts,
  alerts,
  getGroups,
  history,
  location,
  setStatePagination,
  oldCurrentCategoryId,
  oldNextPage,
  oldCurrentSearch,
  handleLoadingProducts,
  handleSearch,
  handleCart,
  signed,
  productDetail,
  menuAddress,
  modalAbout,
}) {
  unregisterServiceWork();

  useEffect(() => {
    async function getData() {
      await getStoreInfo(history);
      await getAlerts();
      await handleSearch('');

      if (window.innerWidth < 1150) {
        handleCart(false);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    handleLoadingProducts(true);
    async function getData() {
      const parentId = new URLSearchParams(location.search).get('categoryId');

      await setStatePagination(
        parentId || null,
        oldCurrentCategoryId || null,
        oldNextPage || 1
      );

      await getGroups(parentId, oldCurrentSearch, null, 1);
    }
    getData();
  }, [location.search, signed]);

  useEffect(() => {
    const root = document.getElementsByTagName('body')[0];
    if (productDetail || menuAddress || modalAbout) {
      root.classList.add('no-scroll');
    } else {
      root.classList.remove('no-scroll');
    }
  }, [productDetail, menuAddress, modalAbout]);

  function handleAlert(alert) {
    return <Alert alert={alert} />;
  }

  return (
    <Container>
      <Metas />
      {alerts?.map((alert) => {
        return handleAlert(alert);
      })}
      <Loading />
      <DeliveryHeader />
      <Header />
      <MenuProfile />
      <AlterPassword />
      <MenuAddress />
      <ProductDetail />
      <LoginRegister />
      <RegistryComplementary />
      <Contact />
      <About />
      <Body>
        <Apresentation>
          <Banner />
          <DeliveryInfo />
          {/* <Information /> */}
          <Category />
          <Group />
        </Apresentation>
        <Cart />
      </Body>
      <TabCart />
      <Tab />
      <Footer />
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...MainActions,
      ...CartActions,
      ...StoreActions,
      ...GroupActions,
      ...AlertsActions,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  signed: state.auth.signed,
  oldCurrentCategoryId: state.groups.oldCurrentCategoryId,
  oldNextPage: state.groups.oldNextPage,
  oldCurrentSearch: state.groups.oldCurrentSearch,
  store: state.store.storeInfo,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
  alerts: state.alerts.alerts,
  productDetail: state.groups.productDetail,
  showLoginModal: state.auth.showLoginModal,
  menuAddress: state.main.menuAddress,
  modalAbout: state.main.modalAbout,
});
export default connect(mapStateToProps, mapDispatchToProps)(Main);
