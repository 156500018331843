import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 30px;
  height: auto;
  position: fixed;
  background: #fff;
  top: 0;
  z-index: 4;
  box-shadow: 0px 3px 6px #00000029;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.grayDark};
  display: none;
  font-weight: bold;
  padding: 10px;

  margin-top: ${(props) => {
    if (props.headerVisible && props.navigation) {
      return '140px';
    }
    if (props.headerVisible) {
      return '90px';
    }
    if (props.navigation) {
      return '50px';
    }
    return 0;
  }};

  @media (max-width: 1150px) {
    display: flex;
  }
  @media (max-width: 555px) {
    margin-top: ${(props) => {
      if (props.headerVisible && props.navigation) {
        return '100px';
      }
      if (props.headerVisible) {
        return '50px';
      }
      if (props.navigation) {
        return '50px';
      }
      return 0;
    }};
  }
`;
