import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FiPlus, FiMinus } from 'react-icons/fi';
import { IoIosArrowDown } from 'react-icons/io';
import { MdCall } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import defaultImage from '../../../../assets/images/default.jpeg';

import { formatPrice } from '../../../../util/format';

import * as GroupActions from '../../../../store/modules/groups/actions';
import * as MainActions from '../../../../store/modules/main/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import * as AuthActions from '../../../../store/modules/auth/actions';
import * as TemporaryCartActions from '../../../../store/modules/temporaryCart/actions';

import FlipCheckBox from '../../../components/FlipCheckBox';

import {
  Container,
  ModalStyled,
  Header,
  Title,
  IconArea,
  Body,
  LeftButton,
  RightButton,
  SlideArea,
  ImageArea,
  Image,
  ProductArea,
  Product,
  Description,
  Name,
  Code,
  UnitDescription,
  ProductDescription,
  PriceArea,
  Price,
  ProductPrices,
  Prices,
  Old,
  NewPrice,
  Consult,
  ButtonAddArea,
  ButtonAdd,
  SignUpArea,
  SignUp,
  Options,
  Option,
  Item,
  ItemNamePrice,
  OptionPriceMobila,
  OptionPrice,
  ItemData,
  ItemName,
  ButtonAddItem,
  CompleteButtonItem,
  Box,
  Group,
  Obligatory,
  Choices,
  Sticky,
  Contact,
  Footer,
  TotalArea,
  Space,
  ButtonQtdArea,
  ButtonQtd,
  Total,
  ButtonArea,
  Call,
  CompleteButton,
  Minus,
  Quantity,
  Add,
} from './styles';

ModalStyled.setAppElement('#root');
ModalStyled.defaultStyles.overlay.backgroundColor = '#00000050';
const customStyles = {
  overlay: { zIndex: 5 },
};

function ProductDetail({
  productDetail,
  cartVisible,
  handleProductDetail,
  currentProduct,
  signed,
  pricePublic,
  color,
  handleLoginModal,
  priceDecimals,
  showcase,
  setProductToContact,
  handleContact,
  addOptionToProduct,
  removeOption,
  changeOption,
  updateOptionAmount,
  updateTemporaryAmount,
  cartTemporaryOptions,
  total,
  temporaryProduct,
  sendToCart,
  sendProductEditedToCart,
  setTemporaryProduct,
  cartItems,
  updateAmount,
  addToCart,
  discountClub,
}) {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line
    const sorted = currentProduct?.option_group
      ?.slice()
      .sort((a, b) => (a.variation < b.variation ? 1 : -1));
    setGroups(sorted);
  }, [currentProduct]);

  function handleCloseModal(e) {
    if (e.currentTarget === e.target) {
      handleProductDetail(false, currentProduct);

      if (currentProduct.edit) {
        setTemporaryProduct(currentProduct);
      }
    }
  }

  function increment() {
    if (temporaryProduct) {
      return updateTemporaryAmount(
        temporaryProduct.uuid,
        parseInt(temporaryProduct.amount, 10) +
          parseInt(temporaryProduct.multiple, 10)
      );
    }
    return updateAmount(currentProduct.id, currentProduct.amount);
  }

  function decrement() {
    if (temporaryProduct?.amount <= 1) {
      return false;
    }
    if (temporaryProduct) {
      return updateTemporaryAmount(
        temporaryProduct.uuid,
        parseInt(temporaryProduct.amount, 10) -
          parseInt(temporaryProduct.multiple, 10)
      );
    }
    return updateAmount(
      currentProduct.id,
      cartItems[currentProduct.id] - parseInt(currentProduct.multiple, 10)
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <RightButton
        className={className}
        style={{ ...style, background: '#ddd', borderRadius: '10px' }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <LeftButton
        className={className}
        style={{ ...style, background: '#ddd', borderRadius: '10px' }}
        onClick={onClick}
      />
    );
  }

  function handleModalContact() {
    setProductToContact(currentProduct);
    handleProductDetail(false);
    handleContact(true);
  }

  function handleShowModal() {
    handleLoginModal();
    handleProductDetail(false);
  }

  function decreOption(option, amount) {
    updateOptionAmount(
      currentProduct.uuid,
      option.id,
      amount - 1,
      currentProduct
    );
  }

  function getPrice() {
    if (total > 0) {
      return total;
    }
    // eslint-disable-next-line
    if (currentProduct?.prices[0]?.promotional_price) {
      return currentProduct.prices[0].promotional_price || 0;
    }

    // eslint-disable-next-line
    return currentProduct?.prices[0]?.regular_price || 0;
  }

  function sendProductToCart() {
    const obligatory = temporaryProduct.option_group.filter(
      (og) => og.min_choices >= 1
    );

    if (obligatory?.length > 0 && temporaryProduct?.options?.length <= 0) {
      return toast.error('Opções obrigatórias.');
    }

    const divergents = [];

    obligatory.map((ob) => {
      const option = temporaryProduct?.options?.filter(
        (opt) => opt.option_group_id === ob.id
      );
      if (option?.length <= 0) {
        divergents.push(ob);
      }

      return [];
    });

    if (divergents.length > 0) {
      const list = document.getElementById('list');
      const item = document.getElementById(`item-${divergents[0].id}`);
      if (list && item) {
        setTimeout(() => {
          list.scrollTo({
            behavior: 'smooth',
            top: item.offsetTop - 100,
          });
        }, 100);
      }
      return toast.error(
        divergents.length > 1 ? 'Opções obrigatórias.' : 'Opção obrigatórias.'
      );
    }

    return sendToCart({ ...temporaryProduct, total });
  }

  function sendProductEdited() {
    const obligatory = temporaryProduct.option_group.filter(
      (og) => og.min_choices >= 1
    );

    if (obligatory.length > 0 && temporaryProduct.options.length <= 0) {
      return toast.error('Opções obrigatórias.');
    }

    const divergents = [];

    obligatory.map((ob) => {
      const option = temporaryProduct.options.filter(
        (opt) => opt.option_group_id === ob.id
      );
      if (option.length <= 0) {
        divergents.push(ob);
      }

      return [];
    });

    if (divergents.length > 0) {
      const list = document.getElementById('list');
      const item = document.getElementById(`item-${divergents[0].id}`);
      if (list && item) {
        setTimeout(() => {
          list.scrollTo({
            behavior: 'smooth',
            top: item.offsetTop - 100,
          });
        }, 100);
      }
      return toast.error(
        divergents.length > 1 ? 'Opções obrigatórias.' : 'Opção obrigatórias.'
      );
    }
    return sendProductEditedToCart({ ...temporaryProduct, total });
  }

  function handleChange(e, group, item) {
    if (!e) {
      return removeOption(item, currentProduct);
    }

    const optionsFiltered = temporaryProduct?.options?.filter(
      (o) => o.option_group_id === group.id
    );

    if (
      optionsFiltered?.length > 0 &&
      optionsFiltered?.length >= group.max_choices
    ) {
      return changeOption(
        optionsFiltered[optionsFiltered.length - 1],
        item,
        currentProduct
      );
    }

    return addOptionToProduct(item, currentProduct);
  }

  function handleAddOption(group, item, product) {
    const groupsFiltered = temporaryProduct?.options?.filter(
      (o) => o.option_group_id === group.id
    );
    if (groupsFiltered.length >= 0) {
      let totalOptions = 1;
      // eslint-disable-next-line
      groupsFiltered.map((gr) => (totalOptions += gr.amount));

      if (totalOptions > group.max_choices) {
        return false;
      }
    }

    return addOptionToProduct(item, product);
  }

  function handleChoices(group) {
    const { min_choices: minChoices, max_choices: maxChoices } = group;

    if (minChoices === 0 && maxChoices === 0) {
      return <span />;
    }

    if (minChoices === 0 && maxChoices > 1) {
      return <span>Escolha até {maxChoices} opções.</span>;
    }

    if (minChoices > 0 && maxChoices > 1) {
      return (
        <span>
          Escolha de {minChoices} até {maxChoices} opções.
        </span>
      );
    }
    if (minChoices > 1 && maxChoices > 1) {
      return (
        <span>
          Escolha pelo menos {minChoices} até {maxChoices} opções.
        </span>
      );
    }

    return <span>Escolha pelo menos {minChoices} opções.</span>;
  }

  function handleButtonAddToCart() {
    if (
      discountClub || // eslint-disable-next-line
      (currentProduct?.option_group?.length === 0 &&
        currentProduct?.prices?.length === 0) || // eslint-disable-next-line
      (currentProduct?.prices[0]?.regular_price <= 0 && // eslint-disable-next-line
        currentProduct?.prices[0]?.promotional_price <= 0)
    ) {
      return <></>;
    }

    if (temporaryProduct) {
      return (
        <ButtonArea>
          {currentProduct.edit ? (
            <ButtonAdd
              visible={
                (pricePublic || (!pricePublic && signed)) &&
                currentProduct.prices.length > 0
              }
              onClick={() => sendProductEdited()}
              color={color}
            >
              Salvar edição
            </ButtonAdd>
          ) : (
            <ButtonAdd onClick={() => sendProductToCart()} color={color}>
              Adicionar ao carrinho
            </ButtonAdd>
          )}
        </ButtonArea>
      );
    }

    if (!cartItems[currentProduct.id]) {
      return (
        <ButtonAddArea>
          <ButtonAdd onClick={() => addToCart(currentProduct)} color={color}>
            Adicionar ao carrinho
          </ButtonAdd>
        </ButtonAddArea>
      );
    }

    if (cartItems[currentProduct.id]) {
      return (
        <CompleteButton color={color}>
          {cartItems[currentProduct.id] > 1 ? (
            <Minus onClick={() => decrement(currentProduct)}>
              <FiMinus size={20} color={color} />
            </Minus>
          ) : (
            <Minus onClick={() => decrement(currentProduct)}>
              <BsTrash size={20} color={color} />
            </Minus>
          )}
          <Quantity>{cartItems[currentProduct.id]}</Quantity>
          <Add onClick={() => addToCart(currentProduct)}>
            <FiPlus size={20} color={color} />
          </Add>
        </CompleteButton>
      );
    }

    return <></>;
  }

  return productDetail ? (
    <Container cartVisible={cartVisible} onClick={(e) => handleCloseModal(e)}>
      <ModalStyled
        isOpen={productDetail}
        onRequestClose={() => handleProductDetail(false)}
        style={customStyles}
      >
        <Header color={color}>
          <IconArea onClick={() => handleProductDetail(false)}>
            <IoIosArrowDown />
          </IconArea>
          <Title>
            {currentProduct.name.length > 20 ? (
              <span>Detalhes do produto</span>
            ) : (
              <span>{currentProduct.name}</span>
            )}
          </Title>

          <IconArea />
        </Header>
        <Body>
          <SlideArea>
            {currentProduct.photos.length > 1 ? (
              <Slider
                className="slide"
                dots
                infinite
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                centerMode
                swipeToSlide
                prevArrow={<SamplePrevArrow />}
                nextArrow={<SampleNextArrow />}
              >
                {currentProduct.photos.map((photo) => (
                  <ImageArea key={photo.id}>
                    <Image image={photo.url} />
                  </ImageArea>
                ))}
              </Slider>
            ) : (
              <ImageArea>
                <Image
                  caroucel={currentProduct.photos.length > 2}
                  image={
                    currentProduct &&
                    currentProduct.photos &&
                    currentProduct.photos.length > 0
                      ? currentProduct.photos[0].url
                      : defaultImage
                  }
                />
              </ImageArea>
            )}
          </SlideArea>
          <ProductArea>
            <Product id="list">
              <Description complete={groups?.length > 0}>
                {currentProduct?.name && (
                  <Sticky>
                    <Name color={color}>{currentProduct.name}</Name>
                  </Sticky>
                )}
                {currentProduct?.code && <Code>{currentProduct.code}</Code>}
                <PriceArea>
                  {
                    // Produto com promoção
                    (pricePublic || (!pricePublic && signed)) &&
                      currentProduct.prices.length > 0 &&
                      currentProduct.prices[0].promotional_price &&
                      currentProduct.prices[0].promotional_price > 0 && (
                        <ProductPrices>
                          <Prices>
                            <Old>
                              <span>De: </span>
                              <Price>
                                {formatPrice(
                                  currentProduct.prices[0].regular_price,
                                  priceDecimals
                                )}
                              </Price>
                            </Old>
                            <NewPrice>
                              <strong>Por:</strong>
                              {formatPrice(
                                currentProduct.prices[0].promotional_price,
                                priceDecimals
                              )}
                            </NewPrice>
                          </Prices>
                        </ProductPrices>
                      )
                  }

                  {
                    // Produto com preço normal
                    (pricePublic || (!pricePublic && signed)) &&
                      currentProduct.prices.length > 0 &&
                      (!currentProduct.prices[0].promotional_price ||
                        currentProduct.prices[0].promotional_price <= 0) && (
                        <ProductPrices>
                          <Prices>
                            <NewPrice>
                              {formatPrice(
                                currentProduct.prices[0].regular_price,
                                priceDecimals
                              )}
                            </NewPrice>
                          </Prices>
                        </ProductPrices>
                      )
                  }

                  {
                    // Produto sem preço
                    (pricePublic || (!pricePublic && signed)) &&
                      currentProduct.prices.length === 0 &&
                      currentProduct.option_group.length <= 0 && (
                        <Consult>Preço sob consulta</Consult>
                      )
                  }

                  {
                    // Preço bloqueado e usuario não logado ou sem preço
                    !pricePublic && !signed && (
                      <SignUpArea>
                        <strong>R$</strong>
                        <SignUp onClick={() => handleShowModal()} color={color}>
                          Cadastre-se
                        </SignUp>
                      </SignUpArea>
                    )
                  }
                </PriceArea>
                {currentProduct && currentProduct.unit_description ? (
                  <UnitDescription>
                    <span>Unidade: </span>
                    <span>{currentProduct.unit_description}</span>
                  </UnitDescription>
                ) : (
                  <></>
                )}
                {currentProduct.description ? (
                  <ProductDescription
                    price={
                      (pricePublic || (!pricePublic && signed)) &&
                      currentProduct.prices.length === 0
                    }
                  >
                    <span>Descrição:</span>

                    <div
                      // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                        __html: currentProduct.description,
                      }}
                    />
                  </ProductDescription>
                ) : (
                  <></>
                )}
              </Description>
              <Options>
                {groups?.length > 0 &&
                  groups?.map((group) => (
                    <Option key={group.id}>
                      {group.option_items.length > 0 && (
                        <Sticky id={`item-${group.id}`}>
                          <Group>
                            <Obligatory>
                              <span>{group.title}</span>
                              <Choices>{handleChoices(group)}</Choices>
                            </Obligatory>
                            {group.min_choices > 0 && (
                              <label>( Obrigatório ) </label>
                            )}
                          </Group>
                        </Sticky>
                      )}
                      {group.option_items.length > 0 &&
                        group.option_items.map((item) => (
                          <Item key={item.id} available={item.available}>
                            <ItemNamePrice>
                              <ItemName>{item.name}</ItemName>
                              {item.available && (
                                <OptionPriceMobila>
                                  <span>
                                    +{' '}
                                    {formatPrice(
                                      // eslint-disable-next-line
                                    item?.option_items_price[0]?.regular_price,
                                      priceDecimals
                                    )}
                                  </span>
                                </OptionPriceMobila>
                              )}
                            </ItemNamePrice>
                            {item.available ? (
                              <>
                                <OptionPrice>
                                  <span>
                                    +{' '}
                                    {formatPrice(
                                      // eslint-disable-next-line
                                    item?.option_items_price[0]?.regular_price,
                                      priceDecimals
                                    )}
                                  </span>
                                </OptionPrice>
                                {!group.repeat_option ? (
                                  <ItemData>
                                    <FlipCheckBox
                                      id={item.id}
                                      checked={
                                        !!(
                                          cartTemporaryOptions &&
                                          cartTemporaryOptions[item.id]
                                        )
                                      }
                                      onChange={(e) =>
                                        handleChange(e, group, item)
                                      }
                                      color={color}
                                    />
                                  </ItemData>
                                ) : (
                                  <ItemData>
                                    {!cartTemporaryOptions ||
                                    (cartTemporaryOptions &&
                                      !cartTemporaryOptions[item.id]) ? (
                                      <ButtonAddItem
                                        onClick={() =>
                                          handleAddOption(
                                            group,
                                            item,
                                            currentProduct
                                          )
                                        }
                                        color={color}
                                      >
                                        <FiPlus />
                                      </ButtonAddItem>
                                    ) : (
                                      <CompleteButtonItem color={color}>
                                        <Box
                                          className="minus"
                                          onClick={() =>
                                            decreOption(
                                              item,
                                              cartTemporaryOptions[item.id]
                                            )
                                          }
                                        >
                                          <FiMinus />
                                        </Box>
                                        <Box>
                                          <span>
                                            {cartTemporaryOptions[item.id]}
                                          </span>
                                        </Box>
                                        <Box
                                          className="plus"
                                          onClick={() =>
                                            handleAddOption(
                                              group,
                                              item,
                                              currentProduct
                                            )
                                          }
                                        >
                                          <FiPlus />
                                        </Box>
                                      </CompleteButtonItem>
                                    )}
                                  </ItemData>
                                )}
                              </>
                            ) : (
                              <>
                                <ItemData>
                                  <span>Indisponível</span>
                                </ItemData>
                                <ItemData>
                                  <span />
                                </ItemData>
                              </>
                            )}
                          </Item>
                        ))}
                    </Option>
                  ))}
              </Options>
            </Product>
            {showcase ? (
              <Contact>
                <Call onClick={() => handleModalContact()} color={color}>
                  <MdCall />
                </Call>
              </Contact>
            ) : (
              <Footer>
                <TotalArea>
                  <Space />
                  {temporaryProduct && (
                    <ButtonQtdArea>
                      <ButtonQtd color={color}>
                        <Box className="minus" onClick={() => decrement()}>
                          <FiMinus />
                        </Box>
                        <Box>
                          <span>{temporaryProduct.amount}</span>
                        </Box>
                        <Box className="plus" onClick={() => increment()}>
                          <FiPlus />
                        </Box>
                      </ButtonQtd>
                    </ButtonQtdArea>
                  )}
                  {
                    // eslint-disable-next-line
                  currentProduct?.option_group?.length > 0 &&(
                      <>
                        {(pricePublic || (!pricePublic && signed)) &&
                        (currentProduct.prices?.length === 0 || // eslint-disable-next-line
                  (currentProduct.prices[0]?.regular_price <= 0 && // eslint-disable-next-line
                    currentProduct.prices[0]?.promotional_price <= 0)) ? (
                          <></>
                        ) : (
                          <Total>
                            Total: {formatPrice(getPrice(), priceDecimals)}
                          </Total>
                        )}
                      </>
                    )
                  }
                </TotalArea>
                {handleButtonAddToCart()}
              </Footer>
            )}
          </ProductArea>
        </Body>
      </ModalStyled>
    </Container>
  ) : (
    <></>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...GroupActions,
      ...MainActions,
      ...CartActions,
      ...AuthActions,
      ...TemporaryCartActions,
    },
    dispatch
  );

function getTemporaryCartOptions(state) {
  const productIndex = state.temporaryCart?.temporaryProducts?.findIndex(
    (p) => p.uuid === state.groups.currentProduct.uuid
  );

  if (productIndex >= 0) {
    return state.temporaryCart?.temporaryProducts[
      productIndex
    ]?.options?.reduce((sumAmount, option) => {
      sumAmount[option.id] = option.amount;
      return sumAmount;
    }, {});
  }

  return [];
}

function getProductValue(state, productIndex) {
  let total = 0;
  const product = state.temporaryCart?.temporaryProducts[productIndex];
  if (
    // eslint-disable-next-line
      product?.prices[0]?.promotional_price > 0
  ) {
    // eslint-disable-next-line
      total = product?.prices[0]?.promotional_price *
        product?.amount
  }
  if (
    // eslint-disable-next-line
    product?.prices[0]?.regular_price) {
    // eslint-disable-next-line
      total = product?.prices[0]?.regular_price  *
        product?.amount

  }

  return total;
}

function getItemValue(state, productIndex) {
  const temporaryProduct = state.temporaryCart?.temporaryProducts[productIndex];

  let itemTotal = 0;

  if (temporaryProduct?.options?.length > 0) {
    itemTotal = state.temporaryCart?.temporaryProducts[
      productIndex
    ]?.options?.reduce((totalItems, option) => {
      return (
        totalItems +
        (option.option_items_price[0].promotional_price > 0
          ? option.option_items_price[0].promotional_price
          : option.option_items_price[0].regular_price) *
          option.amount *
          temporaryProduct?.amount
      );
    }, 0);
  }

  return itemTotal;
}

function getTemporaryValue(state) {
  if (state.groups.currentProduct.uuid) {
    const productIndex = state.temporaryCart?.temporaryProducts?.findIndex(
      (p) => p.uuid === state.groups.currentProduct.uuid
    );

    const totalProduct = getProductValue(state, productIndex);

    const ItemProduct = getItemValue(state, productIndex);

    const total = parseFloat(totalProduct) + parseFloat(ItemProduct);

    return total;
  }
  const productIndex = state.temporaryCart?.temporaryProducts?.findIndex(
    (p) => p.id === state.groups.currentProduct.id
  );

  const product = state.temporaryCart?.temporaryProducts[productIndex];
  if (
    // eslint-disable-next-line
      product?.prices[0]?.promotional_price > 0
  ) {
    // eslint-disable-next-line
     return  product?.prices[0]?.promotional_price *
        product?.amount
  }
  // eslint-disable-next-line
     return  product?.prices[0]?.regular_price *
        product?.amount


}

function getTemporaryProduct(state) {
  const productIndex = state.temporaryCart?.temporaryProducts?.findIndex(
    (p) => p.uuid === state.groups.currentProduct.uuid
  );

  return state.temporaryCart?.temporaryProducts[productIndex];
}

const mapStateToProps = (state) => ({
  discountClub: state.store.storeInfo.settings.discount_club,
  products: state.cart.products,
  showcase: state.store.storeInfo.settings.showcase,
  priceDecimals: state.store.storeInfo.settings.price_decimals,
  productDetail: state.groups.productDetail,
  cartVisible: state.main.cartVisible,
  currentProduct: state.groups.currentProduct,
  signed: state.auth.signed,
  pricePublic: state.store.storeInfo.settings.price_public,
  color: state.store.storeInfo.settings.color_primary,
  // cartTemporaryProducts: getCartTemporaryProduct(state),
  cartTemporaryProducts: state.temporaryCart?.temporaryProducts?.reduce(
    (sumAmount, product) => {
      sumAmount[product.uuid] = product.amount;
      return sumAmount;
    },
    {}
  ),
  cartItems: state.cart.products.reduce((sumAmount, product) => {
    sumAmount[product.id] = product.amount;
    return sumAmount;
  }, {}),
  cartTemporaryOptions: getTemporaryCartOptions(state),
  total: getTemporaryValue(state),
  temporaryProduct: getTemporaryProduct(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
