export function currencyMask(text, thousandthSeparator, decimalSeparator) {
  const textAux = text.replace(/[\D]+/g, '').replace(/^0+/, '');

  const { length } = textAux;

  if (length === 0) {
    text = '';
  }

  if (length === 1) {
    text = `0${decimalSeparator}0${textAux}`;
  }

  if (length === 2) {
    text = `0${decimalSeparator}${textAux}`;
  }

  if (length > 2) {
    let textAux2 = '';
    for (let j = 0, i = length - 3; i >= 0; i -= 1) {
      if (j === 3) {
        textAux2 += thousandthSeparator;
        j = 0;
      }
      textAux2 += textAux.charAt(i);
      j += 1;
    }
    text = '';
    const lengthAux = textAux2.length;
    for (let i = lengthAux - 1; i >= 0; i -= 1) text += textAux2.charAt(i);
    text += `${decimalSeparator}${textAux.substr(length - 2, length)}`;
  }

  return text;
}
