import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: rotate(360deg);
    opacity: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export const FirstLoad = styled.div`
  position: absolute;

  svg {
    height: 150px;
    width: 150px;
    animation: ${rotate} 2s infinite;
  }
`;
export const SecondLoad = styled.div`
  svg {
    height: 75px;
    width: 75px;
  }
`;
