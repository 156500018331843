import styled from 'styled-components';
import Modal from 'react-modal';
import { darken } from 'polished';

export const Container = styled.div``;

export const ModalStyled = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 40%;
  background: #fff;
  border-radius: 10px;

  @media (max-width: 1050px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  color: #fff;
  background: ${(props) => props.color};
  padding: 10px;
  border-radius: 10px 10px 0 0;

  @media (max-width: 1050px) {
    border-radius: 0;
  }
`;

export const IconArea = styled.div`
  flex: 1;
  svg {
    display: none;
    height: 30px;
    width: 30px;
  }

  @media (max-width: 1050px) {
    svg {
      display: flex;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  flex: 3;
  font-weight: bold;

  @media (max-width: 1050px) {
    font-size: 1.2rem;
  }
`;

export const Form = styled.form`
  padding: 20px;
  overflow-y: auto;
`;

export const Body = styled.div``;

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
  }
  .number {
    margin-left: 10px;
    flex: 0.3;
  }

  .uf {
    margin-left: 10px;
    flex: 0.3;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
export const Button = styled.button.attrs({ type: 'submit' })`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  width: 80%;
  height: 40px;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
  background: ${(props) => props.color};
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0px 2px 4px #00000050;
  transition: all 0.6s;

  &:hover {
    box-shadow: 2px 4px 6px #00000050;
    background: ${(props) => darken(0.2, props.color)};
  }
`;
