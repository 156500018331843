import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 200px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const FirstLoad = styled.div`
  position: absolute;
  color: ${(props) => props.color};

  svg {
    height: 80px;
    width: 80px;
    animation: ${rotate} 2s infinite;
  }
`;
export const SecondLoad = styled.div`
  color: ${(props) => props.theme.colors.redDark};

  svg {
    height: 40px;
    width: 40px;
  }
`;
