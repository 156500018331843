import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  width: 100%;
  background: #fff;
  height: 200px;
  padding: 20px 40px 20px 20px;
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 470px) {
    height: 220px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
`;

export const SubAddress = styled.div`
  flex: 1;
  font-size: 14px;
  justify-content: flex-end;
  display: flex;
  cursor: ${(props) => props.restrictDeliveryArea && 'pointer'};
  font-weight: bold;
  align-items: center;
  margin-right: ${(props) => !props.restrictDeliveryArea && '100px'};

  svg {
    color: ${(props) => props.theme.colors.grayDark};
  }

  @media (max-width: 470px) {
    display: none;
  }
`;

export const EditAddress = styled.div`
  border: 1px solid #6e7989;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 45px;
  transition: background 0.4s;

  &:hover {
    background: #eee;
  }
`;

export const CustomerInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 470px) {
    flex-direction: column;
  }
`;

export const Person = styled.div``;

export const Name = styled.div`
  align-items: center;
  color: #000;
  font-weight: bold;
  padding: 20px 0 0 0px;
`;

export const Document = styled.div`
  margin-top: 1px;
`;
export const Phone = styled.div`
  margin-top: 1px;
`;
export const Email = styled.div`
  margin-top: 1px;
`;

export const Address = styled.div`
  padding: 20px 0 0 0px;
  cursor: ${(props) => props.restrictDeliveryArea && 'pointer'};

  strong {
    color: #000;
  }
`;
