import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 5px;
  width: 100%;
  background: #fff;
  height: 200px;
  padding: 20px;
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 20px;

  svg {
    margin-right: 5px;
  }
`;

export const NoteArea = styled.textarea`
  margin-top: 20px;
  height: 80%;
  width: 100%;
  resize: none;
  border-radius: 5px;
  padding: 15px;
`;
