import axios from 'axios';
import history from './history';
import { getStoreSlug } from '../util/StoreUtil';

// LOCATION IQ
// const token = '5acd0f832debee';

// GOOGLE MAPS GEOCODING Acassio
// const token = 'AIzaSyDw4oX03fVUPsBPcjITo7elMBGHY5w5oRI';

// GOOGLE MAPS GEOCODING Alisson
const tokenMaps = 'AIzaSyDuj1XK71H-mR2APJmaHM0ni-MNGWb_v2Y';

const api = axios.create({
  baseURL: 'https://www.api.i9p.com.br',
  // baseURL: 'http://localhost:3333',
  // baseURL: 'http://privea.ddns.com.br:3333',
  // baseURL: 'http://10.0.0.113:3333',
});

const store = getStoreSlug();

export default {
  getApiInstance() {
    return api;
  },

  async getStore() {
    const response = await api
      .get('/api/v1/stores', {
        headers: { store },
      })
      .then(null, async (error) => {
        if (error.response) {
          const { status } = error.response;

          if (status === 400) {
            return history.push(`/${getStoreSlug()}/erro/400`);
          }

          if (status === 401) {
            return history.push(`/${getStoreSlug()}/erro/401`);
          }

          if (status === 403) {
            return history.push(`/${getStoreSlug()}/erro/403`);
          }

          if (status === 404) {
            return history.push(`/${getStoreSlug()}/erro/404`);
          }
        }

        return history.push(`/${getStoreSlug()}/erro/500`);
      });

    if (response && !response.settings) {
      response.settings = {
        logo_url: null,
        banner_url: null,
        color_primary: '#43A047',
        order_minimun_value: null,
        price_public: false,
      };
    }
    return response;
  },

  async getProducts(parentId, name, categoryId, nextPage) {
    const headers = { store };
    if (parentId) headers.parent_id = parentId;
    if (categoryId) headers.category_id = categoryId;
    if (name) headers.name = name;

    const products = await api
      .get('/api/v1/products/categories', {
        params: {
          page: nextPage,
          oldLimit: nextPage === 2 ? 8 : 52,
          limit: nextPage > 1 ? 52 : 8,
        },
        headers,
      })
      .then(null, (error) => {
        if (error.response) {
          const { status } = error.response;

          if (status === 400) {
            history.push(`/${getStoreSlug()}/erro/400`);
            return;
          }

          if (status === 401) {
            history.push(`/${getStoreSlug()}/erro/401`);
            return;
          }

          if (status === 403) {
            history.push(`/${getStoreSlug()}/erro/403`);
            return;
          }

          if (status === 404) {
            history.push(`/${getStoreSlug()}/erro/404`);
            return;
          }
        }

        history.push(`/${getStoreSlug()}/erro/500`);
      });
    return products;
  },

  async getAlerts() {
    const data = { date: new Date() };
    const response = await api
      .post('/api/v1/alerts', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async Login(email, password) {
    const data = { email, password };

    const response = await api
      .post('/api/v1/sessions', data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error.response;
      });

    return response;
  },

  async AlterPasswrod(id, oldPassword, password, confirmPassword) {
    const data = { oldPassword, password, confirmPassword };

    const response = await api
      .put(`/api/v1/customers/${id}/password`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error.response;
      });

    return response;
  },

  async CreateUser(
    name,
    document,
    phone,
    cellphone,
    email,
    password,
    confirmPassword,
    newsletterAccept,
    acceptContract
  ) {
    const data = {
      name,
      document,
      phone,
      cellphone,
      email,
      password,
      newsletter_accept: newsletterAccept,
      acceptContract,
    };
    const response = await api
      .post('api/v1/customers', data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getCustomer(UserId) {
    const response = await api
      .get(`api/v1/customers/${UserId}`, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async finishOrder(data) {
    const response = await api
      .post('api/v1/orders', data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getOrders(orderId) {
    const response = orderId
      ? await api
          .get(`api/v1/orders/${orderId}`, {
            headers: { store },
          })
          .then(null, (error) => {
            const { status } = error.response;
            if (status !== 200) {
              history.push(`/${getStoreSlug()}/erro/404`);
            }
            return error.response;
          })
      : await api
          .get('api/v1/orders', {
            headers: { store },
          })
          .then(null, (error) => {
            const status = error?.response?.status;
            if (status !== 200) {
              history.push(`/${getStoreSlug()}/erro/404`);
            }
            return error.response;
          });

    return response;
  },

  async getAddressByGeo(latitude, longitude) {
    const response = await api
      .get(
        `https://us1.locationiq.com/v1/reverse.php?key=${tokenMaps}&lat=${latitude}&lon=${longitude}&format=json`
      )
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getGeolocationByAddress(address) {
    const response = axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${tokenMaps}`
      )
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getZipCode(zipCode) {
    const response = await axios
      .get(`https://brasilapi.com.br/api/cep/v1/${zipCode}`)
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async checkLocation(latitude, longitude) {
    const data = { latitude, longitude };

    const response = await api
      .post('api/v1/delivery_area', data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async createCustomerAddress(
    name,
    zipCode,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
    latitude,
    longitude,
    customerId
  ) {
    const data = {
      name,
      zip_code: zipCode,
      street,
      complement,
      neighborhood: neighbourhood,
      city,
      state,
      number,
      latitude,
      longitude,
    };

    const response = await api
      .post(`api/v1/customers/${customerId}/addresses`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async getAddresses(customerId) {
    const response = await api
      .get(`api/v1/customers/${customerId}/addresses`, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
  async deleteCustomerAddress(customerId, addressId) {
    const response = await api
      .delete(`api/v1/customers/${customerId}/address/${addressId}`, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },
  async updateCustomerAddress(
    addressId,
    customerId,
    zipCode,
    name,
    street,
    neighbourhood,
    city,
    state,
    number,
    complement,
    latitude,
    longitude
  ) {
    const data = {
      zip_code: zipCode,
      name,
      street,
      neighborhood: neighbourhood,
      city,
      state,
      number,
      complement,
      latitude,
      longitude,
    };

    const response = await api
      .put(`api/v1/customers/${customerId}/addresses/${addressId}`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async requestRecoveryPassword(email) {
    const data = {
      email,
    };

    const response = await api
      .post(`api/v1/session/recovery-password`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async changePassword(password, token) {
    const data = {
      password,
      token,
    };

    const response = await api
      .post('/api/v1/session/change-password', data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async setContactRequest(contact) {
    const data = {
      name: contact.name,
      phone: contact.phone,
      product_id: contact.productId,
      product_name: contact.productName,
      product_price: contact.productPrice,
      status: 'Pendente',
    };

    const response = await api
      .post(`api/v1/contact-request`, data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getDeliveryPaymentMethods() {
    const response = await api
      .get('api/v1/payment-methods-cards', { headers: { store } })
      .then(null, (error) => {
        return error;
      });
    return response;
  },

  async getFreights(data) {
    const response = await api
      .post('api/v1/delivery/freight', data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async getTrackingOrder(data) {
    const response = await api
      .post('api/v1/delivery/track-order', data, { headers: { store } })
      .then(null, (error) => {
        return error;
      });

    return response;
  },

  async createComplementaryRegistry(
    customerId,
    birthDate,
    zipCode,
    street,
    complement,
    neighbourhood,
    city,
    state,
    number,
    latitude,
    longitude
  ) {
    const data = {
      birth_date: birthDate,
      zip_code: zipCode,
      street,
      complement,
      neighborhood: neighbourhood,
      city,
      state,
      number,
      latitude,
      longitude,
    };

    const response = await api
      .post(`api/v1/customers/${customerId}/complement`, data, {
        headers: { store },
      })
      .then(null, (error) => {
        return error;
      });
    return response;
  },
};
