import React from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

function Metas({ store, restrictDeliveryArea, discountClub }) {
  function handleTitle() {
    if (restrictDeliveryArea) {
      return <title>{store?.name} | Delivery</title>;
    }

    if (discountClub) {
      return <title>{store?.name} | Promoções</title>;
    }

    return <title>{store?.name} | Pedidos</title>;
  }

  function handleOgTitle() {
    if (restrictDeliveryArea) {
      return <meta property="og:title" content={`${store?.name} | Delivery`} />;
    }
    if (discountClub) {
      return (
        <meta property="og:title" content={`${store?.name} | Promoções`} />
      );
    }

    return <meta property="og:title" content={`${store?.name} | Pedidos`} />;
  }

  function handleGoogleSearch() {
    if (restrictDeliveryArea) {
      return <meta itemProp="name" content={`${store?.name} | Delivery`} />;
    }
    if (discountClub) {
      return <meta itemProp="name" content={`${store?.name} | Promoções`} />;
    }

    return <meta itemProp="name" content={`${store?.name} | Pedidos`} />;
  }

  function handleTwitterTags() {
    if (restrictDeliveryArea) {
      return (
        <meta name="twitter:title" content={`${store?.name} | Delivery`} />
      );
    }
    if (discountClub) {
      return (
        <meta name="twitter:title" content={`${store?.name} | Promoções`} />
      );
    }
    return <meta name="twitter:title" content={`${store?.name} | Pedidos`} />;
  }

  return (
    <Helmet>
      {handleTitle()}
      {store?.name &&
        store?.city &&
        store?.state &&
        (discountClub ? (
          <meta
            name="description"
            content={`Site de promoções exclusivas para os participantes do ${store.name}.`}
          />
        ) : (
          <meta
            name="description"
            content={`Site de pedidos oficial ${store.name} em ${store.city}, ${store.state}. Confira nossos produtos no catalogo virtual com preços e promoções exclusivas.`}
          />
        ))}

      {store.settings.logo_url && (
        <link rel="icon" href={store.settings.logo_url} />
      )}
      <meta name="smartbanner:title" content={store?.name} />
      {store.settings.logo_url && (
        <meta
          name="smartbanner:icon-google"
          content={store.settings.logo_url}
        />
      )}
      <meta
        name="smartbanner:button-url-google"
        content={`https://play.google.com/store/apps/details?id=br.com.i9p.${store?.slug}`}
      />
      <link rel="canonical" href={`https://www.i9p.app/${store?.slug}`} />
      <meta name="apple-mobile-web-app-title" content={store?.name} />
      {store.settings.logo_url && (
        <link rel="apple-touch-icon" href={store.settings.logo_url} />
      )}
      {handleOgTitle()}
      {store.settings.logo_url && (
        <meta property="og:image" content={store.settings.logo_url} />
      )}
      {store.settings.logo_url && (
        <meta property="og:image:alt" content={store?.name} />
      )}
      <meta property="og:url" content={`https://www.i9p.app/${store?.slug}`} />

      {store?.name &&
        store?.city &&
        store?.state &&
        (discountClub ? (
          <meta
            property="og:description"
            content={`Site de promoções exclusivas para os participantes do ${store.name}.`}
          />
        ) : (
          <meta
            property="og:description"
            content={`Site de pedidos oficial ${store.name} em ${store.city}, ${store.state}. Confira nossos produtos no catalogo virtual com preços e promoções exclusivas.`}
          />
        ))}
      <meta
        name="keywords"
        content={`${store?.name}, ${store?.name} pedidos, ${store?.name} pedidos ${store?.city},pedios ${store?.name},delivery ${store?.name}, ${store?.name} delivery, ${store?.name} entrega,  ${store?.name}  ${store?.city},${store?.name} DELIVERY  ${store?.city}, cardapio ${store?.name}, preços  ${store?.name}, produtos  ${store?.name},${store?.name} produtos, delivery  ${store?.name}  ${store?.city}, ${store?.name} oficial,  ${store?.name} site`}
      />

      {/* <!-- Google / Search Engine Tags --> */}
      {handleGoogleSearch()}
      {discountClub ? (
        <meta
          itemProp="description"
          content={`Site de promoções exclusivas para os participantes do ${store.name}.`}
        />
      ) : (
        <meta
          itemProp="description"
          content={`Site de pedidos oficial ${store.name} em ${store.city}, ${store.state}. Confira nossos produtos no catalogo virtual com preços e promoções exclusivas.`}
        />
      )}
      {store.settings.logo_url && (
        <meta itemProp="image" content={store.settings.logo_url} />
      )}

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      {handleTwitterTags()}
      {discountClub ? (
        <meta
          name="twitter:description"
          content={`Site de promoções exclusivas para os participantes do ${store.name}.`}
        />
      ) : (
        <meta
          name="twitter:description"
          content={`Site de pedidos oficial ${store.name} em ${store.city}, ${store.state}. Confira nossos produtos no catalogo virtual com preços e promoções exclusivas.`}
        />
      )}

      {store.settings.logo_url && (
        <meta name="twitter:image" content={store.settings.logo_url} />
      )}
    </Helmet>
  );
}

const mapStateToProps = (state) => ({
  store: state.store.storeInfo,
  restrictDeliveryArea: state.store.storeInfo.settings.restrict_delivery_area,
  discountClub: state.store.storeInfo.settings.discount_club,
});
export default connect(mapStateToProps)(Metas);
