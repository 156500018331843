export function setName(name) {
  return {
    type: 'SET_NAME',
    name,
  };
}
export function setDocument(document) {
  return {
    type: 'SET_DOCUMENT',
    document,
  };
}
export function setPhone(phone) {
  return {
    type: 'SET_PHONE',
    phone,
  };
}
export function setCellphone(cellphone) {
  return {
    type: 'SET_CELLPHONE',
    cellphone,
  };
}
export function setEmail(email) {
  return {
    type: 'SET_EMAIL',
    email,
  };
}
export function setNewsletterAccept(newsletterAccept) {
  return {
    type: 'SET_NEWSLATTER',
    newsletterAccept,
  };
}
export function setAcceptContract(acceptContract) {
  return {
    type: 'SET_CONTRACT',
    acceptContract,
  };
}

export function cleanCustomerData() {
  return {
    type: 'CLEAN_DATA',
  };
}
