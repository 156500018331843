import React from 'react';

import { AiOutlineLoading } from 'react-icons/ai';

import { Container } from './styles';

function Loading() {
  return (
    <Container>
      <AiOutlineLoading size={80} />
    </Container>
  );
}

export default Loading;
