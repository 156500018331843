import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    opacity: 1;
  }

  to {
    transform: rotate(360deg);
    opacity: 0;
  }
`;

export const Container = styled.div`
  display: ${(props) => (props.loading ? 'flex' : 'none')};
  width: 100%;
  height: ${(props) => props.height}px;
  justify-content: center;
  align-items: center;
  transition: display 2s;
  position: fixed;
  z-index: 10;
  background: #eee;
`;
export const FirstLoad = styled.div`
  position: absolute;

  svg {
    height: 100px;
    width: 100px;
    animation: ${rotate} 2s infinite;
  }
`;
export const SecondLoad = styled.div`
  svg {
    height: 50px;
    width: 50px;
  }
`;

export const Image = styled.div`
  background-image: ${(props) => ` url(${props.image})`};
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;
